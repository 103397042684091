import React from "react";

function ErrorPage() {
  return (
    <>
      <div className='maindiv row' style={{ justifyContent: "center" }}>
        <img
          src='/assets/background.png'
          className='mentor-card-screen-background'
          alt=''
        />
        <h1
          className=' focus-in-contract-bck text-white mb-5 login-title '
          style={{ position: "relative", marginTop: "12%" }}
        >
          <span>Apologies, you are not assigned to any of our events now </span>
        </h1>
        <img
          className='logoContainer mb-2'
          alt='login-background'
          src='/assets/ic-logo-navbar.png'
        ></img>{" "}
      </div>
    </>
  );
}

export default ErrorPage;
