import axios from "axios";
import React, { useEffect } from "react";
import { getCurrentUser } from "../../services/authService";

function ShowRoomBQAHall() {
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/BQAHall`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
  }, []);
  return (
    <a-scene
      cursor='rayOrigin: mouse'
      renderer='gammaOutput: true;antialias: true; colorManagement: true;
          sortObjects: true; '
    >
      <a-entity
        class='listenonkey'
        id='rig'
        position='-5 8 0'
        rotation='0 -90 0'
      >
        <a-camera
          id='camera'
          position='0 0 0'
          wasd-controls-enabled='false'
        ></a-camera>
      </a-entity>

      <a-light
        light='type: spot; angle: 180;'
        color='white'
        position='0 0 0'
        intensity='0.5'
      ></a-light>

      {/* <a-entity light='type: ambient;intensity:0.5'></a-entity> */}
      <a-entity
        light='type:directional; 
                   shadowCameraVisible: false; '
        position='1 35 1'
      ></a-entity>

      <a-entity
        id='model'
        position='0 0 0'
        scale='5 5 5'
        gltf-model='url(/assets/showroom/general/p-comingsoon.glb)'
        animation-mixer='clip:*;loop: infinity;'
        shadow='receive: true'
      ></a-entity>

      <a-entity
        id='texttest'
        geometry='primitive: box'
        material='transparency:true;opacity:0.0'
        //material='color:white'
        position='6 8 0'
        rotation='0 80 0'
        scale='6 4 0.12'
        class='intersectable'
        // light='type: point; intensity: 2.0'
        cursor-url='url:/showroom/bqacatalogue'
      ></a-entity>

      <a-entity
        id='texttest'
        geometry='primitive: box'
        material='transparency:true;opacity:0.0'
        //material='color:white'
        position='-16 6 0'
        rotation='0 80 0'
        scale='5 10 0.12'
        class='intersectable'
        // light='type: point; intensity: 2.0'
        cursor-url='url:/showroom'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='14.77 13.56 11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='11.15 13.56 14.75'
        rotation='-90 0 -90'
      ></a-entity>

      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='18 13.56 2'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='18 13.56 -2'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='14.77 13.56 -11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='11.15 13.56 -14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='2.7 13.56 -18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-2.2 13.56 -18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='2.7 13.56 18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-2.2 13.56 18'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-14.77 13.56 11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-11.15 13.56 14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-14.77 13.56 -11.216'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-11.15 13.56 -14.75'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-18 13.56 2.5'
        rotation='-90 0 -90'
      ></a-entity>
      <a-entity
        light='type: spot; angle: 45;intensity:0.5;decay:1;distance:12'
        position='-18 13.56 -2.5'
        rotation='-90 0 -90'
      ></a-entity>
    </a-scene>
  );
}

export default ShowRoomBQAHall;
