import React, { useState, useContext, useEffect } from "react";
import FlippingCard from "../../flippingCard/flippingCard";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../../../contextGenerator";
import axios from "axios";
import ReactPlayer from "react-player";
import "./projects.css";
const Projects = () => {
  // const [muted, setMuted] = useState(true);

  const context = useContext(authContext);
  const history = useNavigate();
  const [projects, setProjects] = useState([
    {
      name: "Third Ascension Day",
      path: "asc3",
      IsPublic: false,
      IsDisabled: false,
    },
    {
      name: "Show Room",
      path: "showroom",
      IsPublic: true,
      IsDisabled: false,
    },
    {
      name: "Web AR",
      path: "webar",
      IsPublic: false,
      IsDisabled: true,
    },
    {
      name: "Earth AR",
      path: "earth",
      IsPublic: false,
      IsDisabled: true,
    },
    {
      name: "Onboarding AR",
      path: "onboarding",
      IsPublic: false,
      IsDisabled: true,
    },
    {
      name: "Hackathon24",
      path: "hackathon",
      IsPublic: false,
      IsDisabled: true,
    },

    {
      name: "Women In _VOIS",
      path: "wiv",
      IsPublic: true,
      IsDisabled: true,
    },
  ]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}projects/checkprojects`,
        projects
      )
      .then((r) => {});
  }, []);

  return (
    <div className='container-flow p-0 mentor-card-screen-background'>
      <div className='maindiv row'>
        {context.role === "SuperAdmin" ? (
          <div className='col-md-12'>
            <Link
              type='button'
              className='btn btn-light mb-2 '
              style={{ position: "absolute", right: 15, top: 5 }}
              to='/addsuperadmin'
            >
              Add Super Admin
            </Link>
            <Link
              type='button'
              className='btn btn-light mb-2 '
              style={{ position: "absolute", right: 170, top: 5 }}
              to='/users'
            >
              Manage Users
            </Link>
          </div>
        ) : null}

        <div className='col-md-12 mt-5' style={{ position: "relative" }}>
          <div className='row' style={{ minHeight: "90vh" }}>
            {projects.map((el) => {
              return (
                <FlippingCard
                  cardData={el}
                  fromSuperAdmin={true}
                  // sendDataToParent={playVideos}
                ></FlippingCard>
              );
              console.log(el);
            })}
          </div>
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
};

export default Projects;
