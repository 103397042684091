import Axios from "axios";
import React, { useState } from "react";
import "./userCard.css";

function UserCard({ email, user }) {
  const [eventName, seteventName] = useState("");
  const [eventid, seteventid] = useState("");
  const [role, setrole] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const _getNameInitials = () => {
    console.log(user);
    if (email.includes("@vodafone.com"))
      return email.split("@")[0].split(".")[0][0].toUpperCase();
    else return email.split("@")[0].split(".")[0][0].toUpperCase();
  };
  const _getName = () => {
    if (email.includes("@vodafone.com"))
      return (
        email.split("@")[0].split(".")[0] +
        " " +
        email.split("@")[0].split(".")[1]
      );
    else return email.split("@")[0].split(".")[0];
  };
  const deleteuser = () => {
    Axios.post(`${process.env.REACT_APP_SERVER_URL}participant/deletePart`, {
      email: email,
      projectid: eventid,
      role: role,
    }).then((res) => {
      window.location.reload();
    });
  };
  const resetpassworduser = () => {
    Axios.post(`${process.env.REACT_APP_SERVER_URL}participant/resetPassword`, {
      email: email,
      password: newpassword,
    }).then((res) => {});
  };
  const _changepassword = (e) => {
    setnewpassword(e.target.value);
  };
  return (
    <>
      <div
        class='modal fade'
        id={"resetModal" + email}
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Reset Password
              </h5>
              <button
                type='button'
                class='close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div class='modal-body'>
              <h6 style={{ fontWeight: "bold" }}>
                Reset Password for user {_getName()}
              </h6>
              <p>
                NB : this action will send email to this user by his new
                credentials
              </p>
              <input type='text' onChange={_changepassword}></input>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                class='btn btn-danger'
                disabled={newpassword != "" ? false : true}
                data-bs-dismiss='modal'
                onClick={resetpassworduser}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class='card m-5' style={{ width: "18rem" }}>
        <div
          style={{ position: "absolute", right: "0", flexDirection: "column" }}
        >
          {!email.includes("@vodafone.com") ? (
            <div class='dropdown ' style={{ float: "right" }}>
              <button
                class='btn btn-default dropdown-toggle'
                type='button'
                id='dropdownMenu1'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='true'
              >
                <span class='caret'></span>
              </button>
              <ul class='dropdown-menu' aria-labelledby='dropdownMenu1'>
                <li>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                    data-bs-toggle='modal'
                    data-bs-target={"#resetModal" + email}
                  >
                    Reset Password
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        <div
          class='avatar-circle card-header'
          style={{ display: "flex", alignSelf: "center", margin: "2rem" }}
        >
          <span class='initials'>{_getNameInitials()}</span>
        </div>

        <div class='card-body'>
          <h5 class='card-title'>{_getName()}</h5>
          <p class='card-text'>{email}</p>
        </div>
        <ul class='list-group list-group-flush'>
          {user.map((r) => (
            <>
              <div
                class='modal fade'
                id={"deleteModal" + email + r?.project?._id + r?.role}
                tabindex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div class='modal-dialog' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='exampleModalLabel'>
                        Delete User
                      </h5>
                      <button
                        type='button'
                        class='close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div class='modal-body'>
                      <h6 style={{ fontWeight: "bold" }}>
                        Are you sure you Want to delete {_getName()} from event{" "}
                        {eventName} ?
                      </h6>
                      <p>
                        NB : by confirming this the user won't have access with
                        role {role} to view this event content
                      </p>
                    </div>
                    <div class='modal-footer'>
                      <button
                        type='button'
                        class='btn btn-secondary'
                        data-bs-dismiss='modal'
                      >
                        Close
                      </button>
                      <button
                        type='button'
                        class='btn btn-danger'
                        onClick={deleteuser}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <li
                class='list-group-item '
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {r.project?.name}
                  {r?.role == "Admin" ? (
                    <span
                      class='badge badge-pill badge-primary d-block'
                      style={{ width: "6rem", marginTop: "0.5rem" }}
                    >
                      {r.role}
                    </span>
                  ) : (
                    <div
                      class='badge badge-pill badge-secondary d-block'
                      style={{ width: "6rem", marginTop: "0.5rem" }}
                    >
                      {r.role}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    flexDirection: "column",
                  }}
                >
                  <div class='dropdown ' style={{ float: "right" }}>
                    <button
                      class='btn btn-default dropdown-toggle'
                      type='button'
                      id='dropdownMenu1'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='true'
                    >
                      <span class='caret'></span>
                    </button>
                    <ul class='dropdown-menu' aria-labelledby='dropdownMenu1'>
                      <li>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                          data-bs-toggle='modal'
                          data-bs-target={
                            "#deleteModal" + email + r.project?._id + r.role
                          }
                          onClick={() => {
                            seteventid(r.project?._id);
                            seteventName(r.project?.name);
                            setrole(r.role);
                          }}
                        >
                          Delete User
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
}

export default UserCard;
