import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ThirdAscensionChatRooms.module.css";
function ThirdAscensionChatRooms() {
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/chatroomEntrance2`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  const history = useNavigate();
  return (
    <div>
      <div
        className={
          styles["mentor-card-screen-background"] + " breakRoom-container-fluid"
        }
      >
        <div className={styles["mask"]}></div>
        <div
          className='row asc-cards-flip-container'
          style={{ margin: "0", padding: "0", overflowY: "auto" }}
        >
          <div
            className={styles["btn-back-envirtual-container"] + " col-md-12"}
            onClick={() => {
              history("/asc3/chatEntrance");
            }}
          >
            <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
            <label className={styles["back-btn-label"]}>Back</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className='col-md-12 col-xs-12  '
          >
            <div className={styles["main"] + " row"}>
              <div className={styles["main-div"]}>
                <img src='/assets/thirdAscension/ChatCircleDots.svg'></img>
                <span className={styles["header-text"]}>room 1</span>

                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className='mt-4'
                >
                  <button className={styles["btn-styling"]}>Join</button>
                </div>
              </div>
              <div className={styles["main-div"]}>
                <img src='/assets/thirdAscension/ChatCircleDots.svg'></img>
                <span className={styles["header-text"]}>room 1</span>

                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className='mt-4'
                >
                  <button className={styles["btn-styling"]}>Join</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdAscensionChatRooms;
