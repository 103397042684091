import React from "react";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
import styles from "./WIVASponcers.module.css";
import { useNavigate } from "react-router-dom";
function WIVASponcers() {
  const history = useNavigate();
  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          <WivaNavBar></WivaNavBar>

          <WIVASideNavBar></WIVASideNavBar>
          <div className={styles["row"] + " row"}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              className={styles["back-div"]}
              onClick={() => {
                history("/wiv/");
              }}
            >
              <path
                d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
                fill='#4A4D4E'
              />
              <path
                d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
                fill='#4A4D4E'
              />
            </svg>

            <div className='col-md-12  p-0'>
              <div className={styles["joinInnerContainer"]}>
                <div className={styles["first-div-row"] + " row p-0 m-0"}>
                  <div className={styles["wiva-header"] + " col-md-12"}>
                    {" "}
                    <img
                      src='/wiv/WIVAlogo.png'
                      className={styles["main-header-img"]}
                    ></img>
                  </div>
                  <div className={styles["left-side"] + " col-md-6 col-sm-6"}>
                    <div className={styles["left-side-header"]}>
                      <img
                        src='/wiv/itplatformslogo.png'
                        className={styles["it-logo"]}
                      ></img>
                      <div className={styles["brought-div"]}>
                        <span className={styles["brought-to-you-by"]}>
                          Brought To You By:
                        </span>
                        <span className={styles["department-title"]}>
                          Tech _VO<strong>IS</strong> <br /> IT Platforms &
                          Automation GSL
                        </span>
                      </div>
                    </div>
                    <div className={styles["names-div"]}>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>
                          Kareem AbdElSadek
                        </span>
                        <span className={styles["title"]}>
                          Digital Tooling Manager
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Sara ElKady</span>
                        <span className={styles["title"]}>Delivery Lead</span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Karim ElFakhrany</span>
                        <span className={styles["title"]}>
                          Senior Technical Artist
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Shehab Harhash</span>
                        <span className={styles["title"]}>
                          Senior Software Engineer
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Rana Shebl</span>
                        <span className={styles["title"]}>
                          Senior 3D Artist
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Amgad AbdelWahab</span>
                        <span className={styles["title"]}>
                          Senior Product Designer
                        </span>
                      </div>{" "}
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Mariam Kobesy</span>
                        <span className={styles["title"]}>UI/UX Designer</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles["right-side"] + " col-md-6 col-sm-6"}>
                    <div className={styles["left-side-header"]}>
                      <img
                        src='/wiv/connectLogo.png'
                        className={styles["connect-logo"] + " pr-3"}
                      ></img>
                      <div className={styles["brought-div"]}>
                        <span className={styles["brought-to-you-by"]}>
                          In Partnership With:
                        </span>
                        <span className={styles["department-title"]}>
                          Business _VO<strong>IS</strong>
                          <br /> Digital Change Connect Comms
                        </span>
                      </div>
                    </div>
                    <div className={styles["names2-div"]}>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Diana Ibrahim</span>
                        <span className={styles["title"]}>
                          Digital Change Lead
                        </span>
                      </div>
                    </div>
                    <div className={styles["brought2-div"]}>
                      <span className={styles["brought-to-you-by"]}>
                        With Special Thanks To:
                      </span>

                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Dina Salem</span>
                        <span className={styles["title"]}>
                          Women in _VO<strong>IS</strong> Chair Lead
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>Rajarajeswari A</span>
                        <span className={styles["title"]}>
                          Women in _VO<strong>IS</strong> Network SPOC
                        </span>
                      </div>
                      <div className={styles["name-record"]}>
                        <span className={styles["name"]}>
                          Chirawan Phongkasettrakam
                        </span>
                        <span className={styles["title"]}>
                          Women in _VO<strong>IS</strong> Squad Lead
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIVASponcers;
