import React, { useEffect, useState } from "react";
import styles from "./WivaMailTemplates.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function WivaMialTemplates() {
  const history = useNavigate();
  const [templates, setTemplates] = React.useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getMailTemplates();
  }, []);
  const getMailTemplates = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}wivatemplates`, {})
      .then((res) => {
        console.log(res.data);
        setTemplates(res.data);
        setLoading(false);
      });
  };
  return (
    <>
      <div className={styles["config-table-header"] + " col-md-12"}>
        Templates
      </div>

      {loading ? (
        <div class='spinner-border text-danger' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      ) : (
        <div className={"col-md-12"}>
          <div className='row'>
            {templates.map((r, i) => {
              return (
                <div className={" col-md-3"}>
                  <div className={styles["subscriber-card"]}>
                    <span className={styles["subscriber-card-title"]}>
                      {r.MailSubject}
                    </span>
                    <i
                      className={styles["edit"] + " fa fa-pencil"}
                      style={{
                        cursor: "pointer",
                        color: "#e60000",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        history("/viewmail", {
                          state: {
                            subject: r.MailSubject,
                            templateData: r.Template,
                            id: r._id,
                          },
                        });
                      }}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default WivaMialTemplates;
