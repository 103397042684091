import React, { useEffect } from "react";
import styles from "./WIVAVistorPass.module.css";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
import { getCurrentUser } from "../../services/authService";
import domtoimage from "dom-to-image";
import { useNavigate } from "react-router-dom";
function WivaVistorPass() {
  const [name, setName] = React.useState("");
  useEffect(() => {
    getCurrentUser().then((r) => {
      setName(r.preferred_username.split(".")[0]);
    });
  }, []);
  let width = 250,
    height = 200,
    filter = "none",
    streaming = false;
  let videoStream;
  const video = document.getElementById("video");
  const photoButton = document.getElementById("photo-button");
  navigator.mediaDevices
    .getUserMedia({
      video: true,
      audio: false,
    })
    .then((stream) => {
      // link to the video source
      if (video.paused == true) {
        videoStream = stream;
        video.srcObject = stream;

        height = video.videoHeight / (video.videoWidth / width);
        video.setAttribute("width", width);
        video.setAttribute("height", height);

        video.play();
        streaming = true;
      }
    })
    .catch((err) => {});
  const [tookPhoto, setTakePhoto] = React.useState(false);

  const canvas = document.getElementById("canvas");
  function takePicture() {
    // Create canvas
    const context = canvas.getContext("2d");

    // set canvas props
    canvas.width = width;
    canvas.height = height;

    // Draw an image of the video on the canvas
    context.drawImage(video, 0, 0, width, height);

    // Create image from the canvas
    const imgUrl = canvas.toDataURL("image/png");
    const frameImage = document.createElement("Div");
    let imgName;

    let im = imgUrl;
    document.getElementById("userimg").src = imgUrl;
    document.getElementById("userimg").style.width = "80%";

    let img = document.getElementById("visitor-pass");
    let d = document.getElementById("download");

    domtoimage
      .toBlob(img)
      .then(function (blob) {
        d.href = URL.createObjectURL(blob);

        d.download = `Wiva_visitorPass${Date.now()}.png`;
      })
      .catch((err) => console.log(err));
    // btnDiv.appendChild(ReactDOM.(getLink(), btnDiv));
  }

  const format = function date2str(x, y) {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };
  const history = useNavigate();
  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          <WivaNavBar></WivaNavBar>

          <WIVASideNavBar></WIVASideNavBar>

          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={styles["back-div"]}
            onClick={() => {
              history("/wiv/photoboothentrance");
            }}
          >
            <path
              d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
              fill='#4A4D4E'
            />
            <path
              d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
              fill='#4A4D4E'
            />
          </svg>

          <div className={styles["joinInnerContainer"]}>
            <div className={styles["first-div-row"] + " row  m-0"}>
              <div
                className={
                  styles["section-div"] +
                  " " +
                  styles["border-right"] +
                  " " +
                  styles["border-bottom"] +
                  " col-md-6 col-sm-6"
                }
              >
                <div className={styles["tip-div"]}>
                  <span className={styles["tip-number"]}>1</span>
                  <span className={styles["tip-text"]}>Take Your Photo</span>
                </div>
                <div className={styles["video-cnvas"]}>
                  <video id='video' className={styles["photo-video-frame"]}>
                    Stream not available..
                  </video>
                  <button
                    className={styles["btn"]}
                    id='takePhoto'
                    disabled={tookPhoto}
                    onClick={takePicture}
                  >
                    Take Photo
                  </button>
                </div>
              </div>
              <div
                className={styles["section-div"] + " " + " col-md-6 col-sm-6"}
              >
                <canvas id='canvas' style={{ display: "none" }}></canvas>

                <div className={styles["tip-div"]}>
                  <span className={styles["tip-number"]}>2</span>
                  <span className={styles["tip-text"]}>
                    Enjoy Your Visitor Pass
                  </span>
                </div>
                <div className={styles["video-cnvas"]}>
                  <div id='visitor-pass' className={styles["visitor-bg"]}>
                    <span className={styles["welcome-msg"]}>
                      Welcome to Wiva , {name}
                    </span>
                    <img
                      src='/wiv/model.png'
                      id='userimg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["welcome-msg"]}>
                      Visit Date : {format(new Date(), "dd/MM/yy")}
                    </span>
                  </div>
                  <a
                    className={styles["btn-2"]}
                    id='download'
                    disabled={tookPhoto}
                  >
                    Download Your Visitor Pass
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className='container-fluid p-0'>
    //   <WivaNavBar></WivaNavBar>
    //   <WIVASideNavBar></WIVASideNavBar>
    //   <svg
    //     xmlns='http://www.w3.org/2000/svg'
    //     width='20'
    //     height='20'
    //     viewBox='0 0 20 20'
    //     fill='none'
    //     style={{
    //       cursor: "pointer",
    //       marginLeft: "5rem",
    //       marginTop: "1rem",
    //       justifySelf: "flex-start !important",
    //       display: "flex",
    //       alignSelf: "flex-start",
    //     }}
    //     onClick={() => {
    //       history("/wiv/photoboothentrance");
    //     }}
    //   >
    //     <path
    //       d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
    //       fill='#4A4D4E'
    //     />
    //     <path
    //       d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
    //       fill='#4A4D4E'
    //     />
    //   </svg>
    //   <div className={styles["joinInnerContainer"] + " row pb-2"}>
    //     <div className={styles["chat-room-div"] + " col-md-6"}>
    //       <div className={styles["tip-div"]}>
    //         <span className={styles["tip-number"]}>1</span>
    //         <span className={styles["tip-text"]}>Take Your Photo</span>
    //       </div>
    //       <div className={styles["video-cnvas"]}>
    //         <video id='video' className={styles["photo-video-frame"]}>
    //           Stream not available..
    //         </video>
    //         <button
    //           className={styles["btn"]}
    //           id='takePhoto'
    //           disabled={tookPhoto}
    //           onClick={takePicture}
    //         >
    //           Take Photo
    //         </button>
    //       </div>
    //     </div>
    //     <div className={styles["chat-room-div"] + " col-md-6"}>
    //       <canvas id='canvas' style={{ display: "none" }}></canvas>

    //       <div className={styles["tip-div"]}>
    //         <span className={styles["tip-number"]}>2</span>
    //         <span className={styles["tip-text"]}>Enjoy Your Visitor Pass</span>
    //       </div>
    //       <div className={styles["video-cnvas"]}>
    //         <div id='visitor-pass' className={styles["visitor-bg"]}>
    //           <span className={styles["welcome-msg"]}>
    //             Welcome to Wiva , {name}
    //           </span>
    //           <div className={styles["img-div"]}>
    //             <img
    //               src='/wiv/model.png'
    //               id='userimg'
    //               className={styles["img"]}
    //             ></img>
    //           </div>
    //           <span className={styles["welcome-msg"]}>
    //             Visit Date : {format(new Date(), "dd/MM/yy")}
    //           </span>
    //         </div>
    //         <a className={styles["btn-2"]} id='download' disabled={tookPhoto}>
    //           Download Your Visitor Pass
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default WivaVistorPass;
