import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../services/authService";

function ShowRoomMainHall() {
  const [backDrop, setbackDrop] = useState(true);

  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/MainHall`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
  }, []);
  return (
    <>
      {backDrop ? (
        <div
          id='loader-wrapper'
          className='clickEffect'
          style={{ overflowY: "scroll" }}
        >
          <div id='loader'></div>
          <div className='row'>
            <div
              className='loader-section section-left col-md-12'
              style={{ overflowY: "auto" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2%",
                }}
              >
                <h1 className='section-text' style={{ color: "white" }}>
                  {" "}
                  Welcome to _VO
                  <span className='summit-intro-bold pr-3'>IS</span>
                  ShowRoom
                </h1>
                <h4 className='section-text'>
                  {" "}
                  Click and drag your mouse right, left, up, and down to look
                  around the ShowRoom.​
                </h4>

                <img
                  src='/assets/clicks.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <h4 className='section-text mt-2'>
                  {" "}
                  Navigate around the ShowRoom using the keyboard arrows or WASD
                  keys.
                </h4>
                <div className='row '>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/wasd.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                  <div
                    className='col-md-6 '
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/arrows.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                </div>
                <h4 className='section-text mt-2'>
                  {" "}
                  Click on the red dot on the ShowRoom area to enter it.
                </h4>
                <img
                  src='/assets/redDot.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <button
                  className='btn btn-outline-light'
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // var audio = document.getElementById("audio");
                    // audio.muted = false;
                    setbackDrop(false);
                    localStorage.setItem("firstLogin", "true");
                    localStorage.setItem("firstTimeWithLoading", "false");
                    localStorage.setItem("secondTimeWithLoading", "true");

                    // var player = window.videojs("#example-video");
                    // player.play();
                  }}
                >
                  {" "}
                  OK​
                </button>
              </div>{" "}
              {/* <img
                src='/assets/n1560902.gif'
                style={{
                  zIndex: 9999,
                  position: "absolute",
                  right: "5rem",
                  height: "5rem",
                }}
              ></img> */}
            </div>
          </div>{" "}
        </div>
      ) : null}
      <a-scene
        deallocate
        renderer='gammaOutput: true;antialias: true; colorManagement: true;
          sortObjects: true; '
        cursor='rayOrigin: mouse'
        loading-screen='dotsColor: white; backgroundColor: darkred'
        showroomhall
      >
        <a-simple-sun-sky
          id='background'
          sun-position='0.7 0.4 5'
        ></a-simple-sun-sky>
        <a-entity
          environment='preset: default;active:false;fog:0'
          id='env'
        ></a-entity>
        <a-entity
          class='listenonkey'
          id='rig'
          movement-controls='controls: touch;speed:0.5'
          position='0 8 47'
        >
          <a-camera
            id='camera'
            wasd-controls='acceleration: 150'
            position='0 0 0'
            twoway-motion='speed: 35;nonMobileLoad:false'
          ></a-camera>
        </a-entity>
        <a-entity
          light='type: spot; angle: 65;penumbra:1'
          position='32 11 40'
          rotation='90 90 90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 65;penumbra:1'
          position='32 11 3'
          rotation='90 90 90'
        ></a-entity>
        <a-light
          light='type: spot; angle: 180;'
          color='white'
          position='0 0 0'
          intensity='0.2'
        ></a-light>
        <a-light
          light='type: spot; angle: 180;'
          color='white'
          position='0 15 -40'
          intensity='0.2'
        ></a-light>
        {/* <a-entity light='type: ambient;intensity:0.5'></a-entity> */}
        <a-entity
          light='type:directional; 
                   shadowCameraVisible: false; '
          position='1 35 1'
        ></a-entity>
        <a-entity
          id='model'
          position='0 0 0'
          gltf-model='url(/assets/showroom/mainhall/r1-chairs-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model1'
          scale='5 5 5'
          position='0.071 0 0'
          gltf-model='url(/assets/showroom/mainhall/r1-desk-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model2'
          position='0 0 0'
          gltf-model='url(/assets/showroom/mainhall/r1-door-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model3'
          position='0 0 0'
          gltf-model='url(/assets/showroom/mainhall/r1-plants-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model4'
          position='0 0 0'
          gltf-model='url(/assets/showroom/mainhall/r1-walls-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model5'
          className='directionaltarget'
          position='0 0 -53.5'
          gltf-model='url(/assets/showroom/mainhall/r2-walls.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model6'
          position='0 0 -53.5'
          gltf-model='url(/assets/showroom/mainhall/r2-doors.glb)'
          animation-mixer='clip:*;loop: infinity;'
          className='directionaltarget'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model7'
          position='0 0 -53.5'
          gltf-model='url(/assets/showroom/mainhall/r2-names.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          className='directionaltarget'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model8'
          className='directionaltarget'
          position='0 0 0'
          gltf-model='url(/assets/showroom/mainhall/Underscore.glb)'
          animation-mixer='clip:*;loop: infinity;'
          scale='5 5 5'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-11.3 5 -80 '
          rotation='0 15 0'
          scale='2.5 5 3'
          cursor-url='url:/showroom/seahall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-25 5 -62.5 '
          rotation='0 65 0'
          scale='2 4.5 3'
          cursor-url='url:/showroom/sehall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-26.5 5 -42.5'
          rotation='0 -70 0'
          scale='2 4.5 3'
          cursor-url='url:/showroom/oithall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='11 5 -27.5'
          rotation='0 25 0'
          scale='2 4.5 3'
          cursor-url='url:/showroom/opchall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='26 5 -42.5'
          rotation='0 65 0'
          scale='2 4.5 3'
          cursor-url='url:/showroom/teshall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='26 5 -63.5'
          rotation='0 100 0'
          scale='2.5 4.5 3'
          cursor-url='url:/showroom/bqahall'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='11.3 5 -80 '
          rotation='0 -15 0'
          scale='2.5 5 3'
          cursor-url='url:/showroom/hrcomingsoon'
        ></a-entity>
        <a-entity
          geometry='primitive: box; width:3; height: 3; depth: 0.3'
          //material='color:white'
          material='transparency:true;opacity:0.0'
          class='heatpoint'
          src='#heatPoint'
          position='-11 5 -27.5'
          scale='2 4.5 3'
          rotation='0 -25 0'
          cursor-url='url:/showroom/aohall'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 35;decay:0.8;distance:15'
          position='-38 18.96 0.429'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='-38 18.96 17.8'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 35;decay:0.8;distance:15'
          position='-38 18.96 35.7'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 35;decay:0.5;distance:10'
          position='38 18.96 -3'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='38 18.96 14.3'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='38 18.96 32.2'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='24 18.96 -9'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='-20 18.96 -9'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-10.3 14.52 -80'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:15.5'
          position='-0.351 16.52 -80'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='11 14.52 -80'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='19 15.52 -73.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-19 15.52 -73.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-25 16.52 -62.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='25 16.52 -64.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-25 16.52 -62.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='27 16.52 -53.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-27 16.52 -53.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='25 16.52 -44.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-25 16.52 -44.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='19 15.52 -35.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-19 15.52 -35.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='11 15.52 -27.5'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;intensity:0.5;decay:1;distance:14'
          position='-11 15.52 -27.5'
          rotation='-90 0 -90'
        ></a-entity>
      </a-scene>
    </>
  );
}

export default ShowRoomMainHall;
