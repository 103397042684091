import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getCurrentUser } from "./services/authService";

const authContext = React.createContext({
  name: "",
  role: "User",
  project: "",
  token: "",
  email: "",
  auth: "",
});
const { Provider } = authContext;
export function AuthProvider({ children }) {
  let token = "",
    role = "",
    email = "",
    authed = "false";
  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      authed = user.auth;
      role = user.role;
      token = user.token;
    }
  }, []);

  const [auth, setAuth] = useState({ authed: authed, role: role, token });
  return (
    <>
      <Provider value={{ auth, setAuth }}>{children}</Provider>
    </>
  );
}
export { authContext };
