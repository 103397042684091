import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./ThirdAscensionHall.module.css";
import auth, { getCurrentUser } from "../../services/authService";
function ThirdAscensionHall() {
  const [backDrop, setbackDrop] = useState(true);
  const [muted, setMuted] = useState(false);
  function playVideos() {
    // console.log("kjjkjkkj");
    var videos = document.querySelectorAll("video");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element.paused === true) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
  function playAudios() {
    // console.log("kjjkjkkj");
    var videos = document.getElementById("audio");

    if (videos.paused === true) {
      videos.play();
    } else {
      videos.pause();
    }
  }
  function playAudios2() {
    // console.log("kjjkjkkj");
    var videos = document.getElementById("audiocountdown");

    if (videos.paused === true) {
      videos.play();
    } else {
      videos.pause();
    }
  }
  const [loading, setloading] = useState(true);
  const [loadingCount, setloadingCount] = useState(0);
  useEffect(() => {
    if (localStorage.getItem("firstTimeWithLoading") == "true") {
      let interval = setInterval(() => {
        let count = loadingCount;
        if (loadingCount < 100) {
          {
            setloadingCount(parseInt(count) + 1);

            if (loadingCount >= 30 && loadingCount < 60) {
              document.getElementById("loadingsecondsection").className =
                styles["first-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 60 && loadingCount < 90) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["first-section"];
              document.getElementById("loadingfirstsection").style.display =
                "none";
              document.getElementById("loadingfourthsection").className =
                styles["other-section"];
            }
            if (loadingCount >= 90) {
              document.getElementById("loadingsecondsection").className =
                styles["other-section"];
              document.getElementById("loadingthirdsection").className =
                styles["other-section"];
              document.getElementById("loadingfirstsection").className =
                styles["other-section"];
              document.getElementById("loadingfourthsection").className =
                styles["first-section"];
            }
          }
        } else setloading(false);
      }, 300);
      return () => {
        clearInterval(interval);
      };
    }
  }, [loadingCount]);
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/Hall`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
    setloadingCount(0);
    playVideos();
    document.addEventListener("keydown", keyboardMotionBoundries);
    document.addEventListener("touchstart", motionBoundries);
    document.addEventListener("touchend", motionBoundries);
    document.addEventListener("touchmove", motionBoundries);
    document.addEventListener("touchleave", motionBoundries);
    function motionBoundries(event) {
      document.querySelectorAll(".listenonkey").forEach(function (obj) {
        var position =
          document.querySelector("a-scene").camera.el.object3D.parent.position;
        var rotation =
          document.querySelector("a-scene").camera.el.object3D.rotation;

        var cameraEl = document.querySelector("[camera]");
        console.log(
          "rig",
          document.querySelector("a-scene").camera.el.object3D.parent.position
        );
        if (position.z <= 0 && position.z >= -4) {
          if (position.x <= -7 || position.x >= 7) {
            // cameraEl.removeAttribute("wasd-controls");
            position.x <= -7 ? (position.x = -6.59) : (position.x = 6.59);

            // cameraEl.parentElement.setAttribute("wasd-controls-enabled", true);
          }
        } else if (position.z <= -4 && position.z >= -38) {
          if (position.z <= -46 && position.z >= -50) {
            if (position.x >= -6 && position.x <= 6) {
              position.x <= -6 ? (position.x = -7.99) : (position.x = 7.99);
              position.z = -46 ? (position.z = -45.99) : (position.z = -50.99);
            }
          }
          if (position.x <= -15 || position.x >= 15) {
            position.x <= -15 ? (position.x = -14.99) : (position.x = 14.99);
          }
        } else if (position.z <= -30 && position.z >= -70) {
          if (position.x <= -7 || position.x >= 7) {
            // cameraEl.removeAttribute("wasd-controls");
            position.x <= -7 ? (position.x = -6.59) : (position.x = 6.59);

            // cameraEl.parentElement.setAttribute("wasd-controls-enabled", true);
          }
        }
        if (position.z >= 0 || position.z <= -70) {
          position.z >= 0 ? (position.z = 0) : (position.z = -69);
          position.x <= -7 ? (position.x = -6.59) : (position.x = 6.59);
        }
      });
    }
    function keyboardMotionBoundries(event) {
      document.querySelectorAll(".listenonkey").forEach(function (obj) {
        var position =
          document.querySelector("a-scene").camera.el.object3D.position;
        var rotation =
          document.querySelector("a-scene").camera.el.object3D.rotation;

        var cameraEl = document.querySelector("[camera]");
        console.log("camera", position);
        if (position.z <= 0 && position.z >= -39) {
          if (position.x <= -6.5 || position.x >= 6.5) {
            // cameraEl.removeAttribute("wasd-controls");
            position.x <= -6.5 ? (position.x = -6) : (position.x = 6);

            // cameraEl.parentElement.setAttribute("wasd-controls-enabled", true);
          }
        } else if (position.z <= -39 && position.z >= -68) {
          if (position.z <= -46 && position.z >= -50) {
            if (position.x >= -6 && position.x <= 6) {
              position.x <= -6 ? (position.x = -7.99) : (position.x = 7.99);
              position.z = -46 ? (position.z = -45.99) : (position.z = -50.99);
            }
          }
          if (position.x <= -15 || position.x >= 15) {
            position.x <= -15 ? (position.x = -14.99) : (position.x = 14.99);
          }
        } else if (position.z <= -68 && position.z >= -102) {
          if (position.x <= -6.5 || position.x >= 6.5) {
            // cameraEl.removeAttribute("wasd-controls");
            position.x <= -6.5 ? (position.x = -6) : (position.x = 6);

            // cameraEl.parentElement.setAttribute("wasd-controls-enabled", true);
          }
        }
        if (position.z >= 0 || position.z <= -102) {
          position.z >= 0 ? (position.z = 0) : (position.z = -100.99);
        }
        if (position.z == 0 || position.z == -102) {
          position.x <= -7 ? (position.x = 0) : (position.x = 0);
        }
      });
    }
    document.getElementById("model").setAttribute("visible", true);
    document.getElementById("model1").setAttribute("visible", true);

    document.getElementById("background").setAttribute("visible", true);
    document
      .getElementById("env")
      .setAttribute(
        "environment",
        "preset: default;active:true;fog:0;ground:none"
      );
    var heatpoints = document.getElementsByClassName("heatpoint");
    for (let index = 0; index < heatpoints.length; index++) {
      const element = heatpoints[index];
      element.setAttribute("visible", true);
    }
  }, []);
  const handleMuted = () => {
    if (muted === true) {
      setMuted(false);
    } else {
      setMuted(true);
    }
  };
  return (
    <>
      <img
        src='/assets/glb/newmodel/s12-01.png'
        className={styles["toggle-asc-sound"]}
        style={{ height: "3rem" }}
        onClick={playAudios}
      />
      {loading && localStorage.getItem("firstTimeWithLoading") == "true" ? (
        <>
          <div className={styles["asc3-joinOuterContainer"]}>
            <h1 className={styles["welcome-text"]}> Welcome</h1>
            <label className={styles["Loading-title"]}>
              Loading .. {loadingCount} %
            </label>

            <div
              class='progress mt-2'
              style={{
                width: "30%",
                height: "0.5rem",
                backgroundColor: "#CCCCCC",
              }}
            >
              <div
                class='progress-bar'
                role='progressbar'
                style={{
                  width: `${loadingCount}%`,
                  backgroundColor: "#BE0000",
                  fontFamily: "Polaris !important",
                }}
                aria-valuenow={`${loadingCount}`}
                aria-valuemin='0'
                aria-valuemax='100'
              ></div>
            </div>
            <div id='loadingfirstsection' className={styles["first-section"]}>
              <img src='/assets/clicks.png' className={styles["img"]}></img>
              <label className={styles["instruction"]}>
                {" "}
                Click and drag your mouse right, left, up, and down <br /> to
                look around the Hall
              </label>
            </div>
            <div id='loadingsecondsection' className={styles["other-section"]}>
              <div className='row '>
                <div
                  className='col-md-6'
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src='/assets/wasd.png'
                    style={{
                      zIndex: 9999,
                      //   position: "absolute",
                      height: "7.5rem",
                    }}
                  ></img>
                </div>
                <div
                  className='col-md-6 '
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src='/assets/arrows.png'
                    style={{
                      zIndex: 9999,
                      //   position: "absolute",
                      height: "7.5rem",
                    }}
                  ></img>
                </div>
              </div>
              <label className={styles["instruction"]}>
                {" "}
                Navigate around the Hall using the keyboard arrows
                <br /> or WASD keys. ​
              </label>
            </div>
            <div id='loadingthirdsection' className={styles["other-section"]}>
              <img
                src='/assets/redDot.png'
                className={styles["img"]}
                style={{ width: "8.5rem" }}
              ></img>
              <label className={styles["instruction"]}>
                {" "}
                Click on the red dot on the Doors to enter it.​
              </label>
            </div>
            <div id='loadingfourthsection' className={styles["other-section"]}>
              <img
                src='/assets/glb/newmodel/asc-logo.png'
                className={styles["img"]}
                style={{ width: "7.5rem" }}
              ></img>

              <label label className={styles["instruction"]}>
                3<sup>rd</sup> Ascension Day. ​<br /> Towords Digital Future. ​
              </label>
            </div>

            <div className={styles["footer"]}>
              <label className={styles["for-better"]}>
                {" "}
                For a better experience, make sure you have a stable internet
                connection and aren’t connected to the VPN.
              </label>
              <label className={styles["for-better"]}>
                {" "}
                We will be more than happy to provide{" "}
                <a
                  href='https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTM5YmNlOTQtOGI1Zi00MzhlLWEyNTktY2FlMDYyOGYxMTg0%40thread.v2/0?context=%7b%22Tid%22%3a%2268283f3b-8487-4c86-adb3-a5228f18b893%22%2c%22Oid%22%3a%2258465933-6095-4878-b12f-e79236c8e8c5%22%7d'
                  target='_blank'
                  style={{ color: "#e60000" }}
                >
                  {" "}
                  technical support{" "}
                </a>
              </label>
              <label>© Brought to you by</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h6
                  className=' mt-2'
                  style={{
                    color: "white",
                    marginLeft: "0.25rem",
                    fontFamily: "Vodafone RG",
                  }}
                >
                  <a
                    href='https://envirtual.tvois.vodafone.com/showroom'
                    target='_blank'
                    style={{ color: "#e60000" }}
                  >
                    Tech _Vois Egypt - IT Platforms & Automation GSL
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {backDrop && localStorage.getItem("firstLogin") == "true" ? (
        <div
          id='loader-wrapper'
          className='clickEffect'
          style={{ overflowY: "scroll" }}
        >
          <div id='loader'></div>
          <div className='row'>
            <div
              className='loader-section section-left col-md-12'
              style={{ overflowY: "auto" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2%",
                }}
              >
                <h1
                  className='section-text'
                  style={{ color: "white", fontFamily: "Polaris !important" }}
                >
                  {" "}
                  Welcome
                </h1>
                <h4
                  className='section-text'
                  style={{ fontFamily: "Vodafone RG" }}
                >
                  {" "}
                  Click and drag your mouse right, left, up, and down to look
                  around the Hall.​
                </h4>

                <img
                  src='/assets/clicks.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <h4
                  className='section-text mt-2'
                  style={{ fontFamily: "Vodafone RG" }}
                >
                  {" "}
                  Navigate around the Hall using the keyboard arrows or WASD
                  keys.
                </h4>
                <div className='row '>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/wasd.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                  <div
                    className='col-md-6 '
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/arrows.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                </div>
                <h4
                  className='section-text mt-2'
                  style={{ fontFamily: "Vodafone RG" }}
                >
                  {" "}
                  Click on the red dot on the Hall area to enter it.
                </h4>
                <img
                  src='/assets/redDot.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <button
                  className='btn btn-outline-light'
                  style={{
                    cursor: "pointer",
                    fontFamily: "Polaris !important",
                  }}
                  onClick={() => {
                    // var audio = document.getElementById("audio");
                    // audio.muted = false;
                    setbackDrop(false);
                    localStorage.setItem("firstLogin", "true");
                    localStorage.setItem("firstTimeWithLoading", "false");
                    localStorage.setItem("secondTimeWithLoading", "true");
                    playAudios();
                    playAudios2();
                  }}
                >
                  {" "}
                  OK​
                </button>
              </div>{" "}
              {/* <img
                src='/assets/n1560902.gif'
                style={{
                  zIndex: 9999,
                  position: "absolute",
                  right: "5rem",
                  height: "5rem",
                }}
              ></img> */}
            </div>
          </div>{" "}
        </div>
      ) : null}
      <a-scene
        renderer='gammaOutput: true;antialias: true; colorManagement: true;
        sortObjects: true; '
        cursor='rayOrigin: mouse'
        loading-screen='dotsColor: white; backgroundColor: darkred'
        gltf-model='/draco-decoder'
      >
        <a-assets>
          <img id='heatPoint' src='/assets/redDot.png' />
          <audio id='audio' preload='auto' loop>
            <source
              src='/assets/thirdAscension/space.mp3'
              type='audio/mp3'
            ></source>
          </audio>
          <audio id='audiocountdown' preload='auto'>
            <source
              src='/assets/thirdAscension/countdown.mp3'
              type='audio/mp3'
            ></source>
          </audio>
          <img id='agenda' src='/assets/thirdAscension/agenda.jpg' />
        </a-assets>
        {/* <a-image
          src='#agenda'
          position='0 10.262 -22.88'
          scale='7.5 10 0'
          class='heatpoint'
          visible={false}
          material='shader: hologram;
                         numGlitchBars: 50;glitchRate:0.5'
        ></a-image> */}

        <a-sound
          src='#audio'
          loop='true'
          volume='0.5'
          position='0 0 0'
        ></a-sound>
        <a-sound src='#audiocountdown' loop='false' position='0 0 0'></a-sound>
        <a-simple-sun-sky
          id='background'
          sun-position='0.7 0.4 5'
        ></a-simple-sun-sky>
        <a-entity
          environment='preset: default;active:false;fog:0'
          id='env'
        ></a-entity>
        <a-entity
          class='listenonkey'
          id='rig'
          movement-controls='controls: touch;speed:0.8'
          position='0 7 30'
        >
          <a-camera
            id='camera'
            wasd-controls='acceleration: 200'
            position='0 0 0'
            twoway-motion='speed: 35;nonMobileLoad:false'
          ></a-camera>
        </a-entity>
        <a-entity light='type: ambient;intensity:0.2'></a-entity>
        <a-entity
          light='type:directional; 
                   shadowCameraVisible: false; '
          position='1 100 200'
        ></a-entity>
        <a-entity
          id='model'
          visible={false}
          position='-58 0.60 -10.5'
          rotation='0 90 0'
          scale='1.5 1.5 1.5'
          gltf-model='url(/assets/thirdAscension/hall1/mainhall2.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
        <a-entity
          id='model1'
          visible={false}
          scale='1.5 1.5 1.5'
          gltf-model='url(/assets/thirdAscension/hall1/animation.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
        <a-image
          visible={false}
          class='heatpoint'
          src='#heatPoint'
          position='22 7.262 -20.88'
          rotation='0 -90 0'
          scale='2.9 2 0.12'
          cursor-url='url:/asc3/breakroom'
        ></a-image>
        <a-circle
          visible={false}
          class='heatpoint'
          id='circle1'
          color='black'
          position='22.5 7.262 -20.88'
          rotation='0 -90 0'
          scale='2.9 2 0.12'
          radius='.7'
          material='opacity: 0.5'
          animation='property: radius; from: 0.01; to: 0.7; dur: 1000;
         easing: easeInOutSine; dir: alternate; loop: true;'
        ></a-circle>
        <a-image
          visible={false}
          class='heatpoint'
          src='#heatPoint'
          position='14.42 7.262 -36.23'
          rotation='0 135 0'
          scale='2.9 2 0.12'
          cursor-url='url:/asc3/chatEntrance'
        ></a-image>
        <a-circle
          visible={false}
          class='heatpoint'
          id='circle1'
          color='black'
          position='14.42 7.262 -36.90'
          rotation='0 -45 0'
          scale='2.9 2 0.12'
          radius='.7'
          material='opacity: 0.5'
          animation='property: radius; from: 0.01; to: 0.7; dur: 1000;
         easing: easeInOutSine; dir: alternate; loop: true;'
        ></a-circle>
        <a-image
          visible={false}
          class='heatpoint'
          src='#heatPoint'
          position='-26 7.262 -20.88'
          rotation='0 90 0'
          scale='2.9 2 0.12'
          cursor-url='url:/asc3/session'
        ></a-image>
        <a-circle
          visible={false}
          class='heatpoint'
          id='circle1'
          color='black'
          position='-26.5 7.262 -20.88'
          rotation='0 90 0'
          scale='2.9 2 0.12'
          radius='.7'
          material='opacity: 0.5'
          animation='property: radius; from: 0.01; to: 0.7; dur: 1000;
         easing: easeInOutSine; dir: alternate; loop: true;'
        ></a-circle>
      </a-scene>
    </>
  );
}

export default ThirdAscensionHall;
