import React from "react";
import styles from "./WIVATextContainer.module.css";
import onlineIcon from "../../../assets/icons/onlineIcon.png";
function WIVATextContainer({ users }) {
  return (
    <div className={styles["textContainer"]}>
      {users ? (
        <div>
          {/* {console.log(users)} */}
          <h4 className={styles["header"] + " mt-3"}>People in This Room:</h4>
          <span className={styles["sub-header"]}>
            Experience uninterrupted chat flow: <strong>Refresh</strong> this
            window on tab switches.
          </span>
          <div className={styles["activeContainer"]}>
            {users.map(({ name }) => (
              <div key={Math.random()} className={styles["activeItem"]}>
                <div className={styles["online-container"]}>
                  <span className={styles["circle"]}>
                    {name[0]}
                    <img
                      alt='Online Icon'
                      className={styles["online"]}
                      src={onlineIcon}
                    />
                  </span>
                </div>
                <span className={styles["name"]}> {name}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default WIVATextContainer;
