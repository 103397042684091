import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../../../components/ContactCard/ContactCard";
import { getCurrentUser } from "../../../services/authService";
import styles from "./DigitalTooling.module.css";

function DigitalToolingContactUs() {
  const history = useNavigate();
  React.useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/DigitalizationContactUs`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  return (
    <div className='container-fluid p-0' style={{ overflowY: "hidden" }}>
      <div className=' row'>
        <div
          className={styles["btn-back-envirtual-container"] + " col-md-12"}
          onClick={() => {
            history("/showroom/digitaltooling");
          }}
        >
          <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
          <label className={styles["back-btn-label"]}>Back</label>
        </div>
        <img
          src='/assets/showroom/mainhall/ic-bg14.png'
          className='mentor-card-screen-background p-0'
          alt=''
        />
        <div className='row'>
          <div className='col-md-3'></div>

          <div className='col-md-6 p-5 col-sm-9'>
            <iframe
              src={
                "https://vodafone.sharepoint.com/sites/WIVAFiles/_layouts/15/embed.aspx?UniqueId=5b237395-f74c-4bee-a050-60c063c8caef"
              }
              className={styles["video-background"]}
              frameborder='0'
              allowfullscreen
            ></iframe>
          </div>

          <div className='col-md-3'></div>
        </div>
      </div>
    </div>
  );
}

export default DigitalToolingContactUs;
