import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "./loginForm.css";
import { Link } from "react-router-dom";
import { authContext } from "../../contextGenerator";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import authProvider from "../Auth/authProvider";
import { useEffect } from "react";
import { getCurrentUser } from "../../services/authService";
import { setJwt } from "../../services/httpService";

const LoginForm = () => {
  const context = useContext(authContext);
  const history = useNavigate();
  let [cred, setCred] = React.useState();
  useEffect(() => {
    authProvider.then((r) => {
      setCred(r);
    });
    getCurrentUser().then((r) => {
      if (r != null) {
        history("/user/project");
      }
    });
  }, []);
  return cred ? (
    <AzureAD provider={cred}>
      {({ login, logout, authenticationState, error }) => {
        const isInProgress =
          authenticationState === AuthenticationState.InProgress;
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;
        const isUnauthenticated =
          authenticationState === AuthenticationState.Unauthenticated;
        if (isAuthenticated) {
          return (
            <React.Fragment>
              {() => {
                history("/user/project");
              }}
            </React.Fragment>
          );
        } else if (isUnauthenticated) {
          return (
            <div className='container-fluid p-0'>
              <div className='maindiv row'>
                <img
                  src='/assets/background.png'
                  className='mentor-card-screen-background'
                  alt=''
                />
                {error ? (
                  <h1 className=' focus-in-contract-bck text-white mb-5 login-title '>
                    <span>
                      Apologies, you are not authorized to join this event
                    </span>
                  </h1>
                ) : (
                  <>
                    <div className='col-md-3'></div>
                    <div className='subParentDiv col-md-6 col-xs-12'>
                      <div className='parent text-center'>
                        <p className='welcomeClass'>Welcome</p>
                        <p className='managementMeetingClass'>
                          {" "}
                          _VOIS Events Scheduler
                        </p>
                        {/* <p className=' summit-intro-heading'>
                        _VO<bold className='summit-intro-bold'>IS </bold>Summit
                        2022{" "}
                      </p> */}
                        <div>
                          <span
                            type='button'
                            className=' button-login mt-4  '
                            onClick={async () => {
                              login();
                            }}
                          >
                            Log in as Vodafoner
                          </span>

                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ color: "white", marginRight: 40 }}
                            href='https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/Employee-Privacy-Statement.aspx?csf=1&e=PahNAV'
                          >
                            Employee Privacy Statement
                          </a>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ color: "white" }}
                            href='https://vodafone.sharepoint.com/sites/PrivacyMatters/SitePages/External-Workforce.aspx?csf=1&e=hMss8f'
                          >
                            Contractor Privacy Statement
                          </a>
                          {/* <div>
                          <span
                            type='button'
                            className=' button-login mt-4 '
                            onClick={() => {
                              history("/login");
                            }}
                          >
                            Log in
                          </span>
                        </div> */}
                        </div>
                      </div>
                    </div>{" "}
                  </>
                )}
              </div>
              <img
                className='logoContainer mb-2'
                alt='login-background'
                src='/assets/ic-logo-navbar.png'
              ></img>
            </div>
          );
        }
      }}
    </AzureAD>
  ) : (
    <div></div>
  );
};

export default LoginForm;
