import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { authContext } from "../../contextGenerator";
import "./flippingCard.css";
import { Dropdown, Button, Icon } from "rsuite";
import Countdown from "react-countdown";
import * as XLSX from "xlsx";
import { getCurrentUser } from "../../services/authService";
// import "rsuite/dist/styles/rsuite-default.css";

function FlippingCard({ cardData, fromSuperAdmin, isAdmin }) {
  const startDate =
    cardData.project != null
      ? cardData.project.startDate == null
        ? new Date()
        : new Date(cardData.project.startDate)
      : cardData.startDate == null
      ? new Date()
      : new Date(cardData.startDate);

  const endDate =
    cardData.project != null
      ? cardData.project.endDate == null
        ? new Date()
        : new Date(cardData.project.endDate)
      : cardData.endDate == null
      ? new Date()
      : new Date(cardData.endDate);
  const loggedin = new Date();
  const [viewButton, setviewButton] = useState(false);
  const [image, setimageBytes] = useState("");
  const [PopOver, setPopOver] = React.useState(true);
  const [saveCriteria, setsaveCriteria] = useState([]);
  const handlePopOver = () => {
    if (PopOver === true) {
      setPopOver(false);
    } else {
      setPopOver(true);
    }
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span className='timing'>
          {days} d : {hours} h : {minutes} m : {seconds} s
        </span>
      );
    }
  };
  const [showAgenda, setshowAgenda] = useState(false);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}eventassets/${
  //         fromSuperAdmin ? cardData.path : cardData.project.path
  //       }/Generalassets/backgroundimage-img`
  //     )
  //     .then((res) => {
  //       setimageBytes(res.data);
  //     })
  //     .catch((ex) => {
  //       console.log(ex);
  //     });
  // }, []);
  const history = useNavigate();
  const context = useContext(authContext);

  const openAddParticipants = () => {
    history("/addparts", {
      state: {
        project: cardData,
        projectName: cardData.name,
        projectPath: `${window.location.origin + "/" + cardData.path}`,
      },
    });
  };
  const openAddAdmins = () => {
    history("/addadmins", {
      state: {
        project: cardData,
        projectName: cardData.name,
        projectPath: `${window.location.origin + "/" + cardData.path}`,
      },
    });
  };
  const openAddAssets = () => {
    localStorage.setItem("firstLogin", "true");
    localStorage.setItem("selectedEventName", cardData.path);
    localStorage.setItem("selectedEventid", cardData._id);
    localStorage.setItem("firstLogin", "true");
    history({
      pathname: "/addassets",
      state: {
        project: cardData,
        projectName: cardData.name,
        projectPath: `${window.location.origin + "/" + cardData.path}`,
      },
    });
  };
  const [Disabled, setDisabled] = useState(
    fromSuperAdmin ? cardData.IsDisabled : cardData.project.IsDisabled
  );

  const manageUsers = () => {
    console.log(cardData);
    if (cardData.project) {
      history({
        pathname: "/users",
        state: {
          project: cardData.project,
          projectName: cardData.project.name,
        },
      });
    } else {
      history({
        pathname: "/users",
        state: {
          project: cardData,
          projectName: cardData.name,
        },
      });
    }
  };

  const addWivaVisit = () => {
    getCurrentUser().then((r) =>
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivavisits/`, {
          email: r.preferred_username,
        })
        .then((res) => {})
    );
  };
  const [pushNotificationTitle, setpushNotificationTitle] = useState("");
  const [pushNotificationbody, setpushNotificationbody] = useState("");
  return (
    <>
      {fromSuperAdmin ? (
        <div
          class='modal fade'
          id={"sendMessage" + cardData.path}
          tabindex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div class='modal-dialog modal-lg' role='document'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='exampleModalLabel'>
                  Send Push Notification
                </h5>
              </div>
              <div class='modal-body row'>
                <div className='col-md-12'>
                  <p>Title:</p>
                  <input
                    style={{
                      borderRadius: "50px",
                      width: "100%",
                    }}
                    type='text'
                    onChange={(e) => setpushNotificationTitle(e.target.value)}
                  />
                </div>
                <div className='col-md-12'>
                  <p>Body:</p>
                  <textarea
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    cols={10}
                    rows={5}
                    onChange={(e) => setpushNotificationbody(e.target.value)}
                  />
                </div>
              </div>
              <div class='modal-footer'>
                <button
                  type='button'
                  class='btn btn-danger'
                  data-bs-dismiss='modal'
                  onClick={(e) => {
                    axios.post(
                      `${process.env.REACT_APP_SERVER_URL}participant/sendNot`,
                      {
                        title: pushNotificationTitle,
                        body: pushNotificationbody,
                        project: cardData._id,
                      }
                    );
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className='col-md-3 col-xs-12 mt-3'>
        <div className='flip'>
          <div
            className='front'
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {image != "" ? (
              <img className='frontImage' src={`${image}`}></img>
            ) : null}
            <h1
              style={{
                color: "white",
                textShadow:
                  "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
              }}
              className='text-shaddow'
            >
              {fromSuperAdmin ? cardData.name : cardData.project.name}
            </h1>
          </div>
          <div className='back'>
            {fromSuperAdmin ? null : cardData.project.describtion !=
                undefined &&
              cardData.project.describtion != null &&
              cardData.project.describtion != "" ? (
              <>
                <div
                  className='fa fa-info info-btn'
                  onMouseOver={handlePopOver}
                  // onMouseOut={handlePopOver}
                  data-bs-toggle='collapse'
                ></div>
                <div
                  className={`collapse info-description  ${
                    PopOver ? "hidden" : "show"
                  }`}
                  id='collapseOne'
                  data-parent='#accordion'
                >
                  <div>
                    <div
                      className='float-right'
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={handlePopOver}
                    >
                      x
                    </div>
                    <div className='mb-3 mt-2'>
                      <span className='mr-5'>
                        <div className='fa fa-calendar '></div> From :{" "}
                        {startDate.toLocaleDateString()}
                      </span>
                      <span className=''>
                        {" "}
                        {startDate.toLocaleTimeString()}
                      </span>
                    </div>
                    <div className='mb-3 mt-2'>
                      <span className='mr-3'>
                        <div className='fa fa-calendar'></div> To :{" "}
                        {endDate.toLocaleDateString()}{" "}
                      </span>
                      <span className='ml-5'>
                        {endDate.toLocaleTimeString()}
                      </span>
                    </div>
                    {cardData.project.describtion}
                  </div>
                </div>
              </>
            ) : null}

            <div className='mentor-card-button-container '>
              {fromSuperAdmin ? (
                <div
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                  }}
                >
                  <div className='dropdown dropstart'>
                    <button
                      className='btn btn-secondary btn-sm  dropdown-toggle'
                      type='button'
                      id='dropdownMenuButton'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      More
                    </button>
                    <div
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuButton'
                    >
                      <a
                        className='dropdown-item'
                        style={{ cursor: "pointer" }}
                        onClick={openAddParticipants}
                      >
                        Add Participants
                      </a>
                      <a
                        className='dropdown-item'
                        style={{ cursor: "pointer" }}
                        onClick={openAddAdmins}
                      >
                        Add Admins
                      </a>
                      <a
                        className='dropdown-item'
                        style={{ cursor: "pointer" }}
                        onClick={manageUsers}
                      >
                        Manage users
                      </a>
                      {cardData.name == "Women In _VOIS" ? (
                        <>
                          <a
                            className='dropdown-item'
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (cardData.project) {
                                history({
                                  pathname: "/statscriteria",
                                  state: {
                                    project: cardData.project,
                                    projectName: cardData.project.name,
                                  },
                                });
                              } else {
                                history("/statscriteria", {
                                  state: {
                                    project: cardData,
                                    projectName: cardData.name,
                                  },
                                });
                              }
                            }}
                          >
                            Statistics
                          </a>
                          <a
                            className='dropdown-item'
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (cardData.project) {
                                history({
                                  pathname: "/managetemplate",
                                });
                              } else {
                                history({
                                  pathname: "/managetemplate",
                                });
                              }
                            }}
                          >
                            Manage Template
                          </a>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {fromSuperAdmin == false ? (
                startDate.getTime() > loggedin.getTime() &&
                cardData.role != "Admin" ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p className='closingTime'>Starts In</p>
                    <Countdown date={startDate} renderer={renderer} />
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      rel='noreferrer'
                      target='_blank'
                      onClick={() => {
                        localStorage.setItem("firstLogin", "true");

                        if (fromSuperAdmin) {
                          //sendDataToParent(cardData.path);
                          if (cardData.name == "Women In _VOIS") {
                            addWivaVisit();
                          }
                          localStorage.setItem(
                            "selectedEventName",
                            cardData.path
                          );
                          window.location.pathname = cardData.path;
                        } else {
                          if (cardData.project.name == "Women In _VOIS") {
                            addWivaVisit();
                          }
                          localStorage.setItem(
                            "selectedEventName",
                            cardData.project.path
                          );

                          window.location.href = cardData.project.path;
                        }
                      }}
                      className=' mentor-card-button'
                    >
                      Start Event
                    </button>
                  </div>
                )
              ) : null}
              {fromSuperAdmin ? (
                <button
                  rel='noreferrer'
                  target='_blank'
                  onClick={() => {
                    localStorage.setItem("firstLogin", "true");

                    if (fromSuperAdmin) {
                      //sendDataToParent(cardData.path);
                      if (cardData.name == "Women In _VOIS") {
                        addWivaVisit();
                      }
                      localStorage.setItem("selectedEventName", cardData.path);
                      window.location.pathname = cardData.path;
                    } else {
                      if (cardData.project.name == "Women In _VOIS") {
                        addWivaVisit();
                      }
                      localStorage.setItem(
                        "selectedEventName",
                        cardData.project.path
                      );
                      window.location.href = cardData.project.path;
                    }
                  }}
                  className=' mentor-card-button'
                >
                  Start Event
                </button>
              ) : null}
            </div>
            {fromSuperAdmin ? (
              <>
                <div className='mentor-card-button-container mt-2'>
                  <button
                    rel='noreferrer'
                    target='_blank'
                    onClick={openAddAssets}
                    className=' mentor-card-button'
                  >
                    Add Event Assets
                  </button>
                </div>
                {cardData.path.toLowerCase().includes("wiv") ||
                cardData.path.toLowerCase().includes("wiv") ? (
                  <a
                    className=' mentor-card-button mt-2'
                    onClick={() => {
                      history("/managewivaassets");
                    }}
                  >
                    Manage Assets
                  </a>
                ) : null}
              </>
            ) : isAdmin ? (
              <a
                className=' mentor-card-button mt-2'
                onClick={() => {
                  history("/managewivaassets");
                }}
              >
                Manage Assets
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default FlippingCard;
