import axios from "axios";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import auth, { getCurrentUser } from "../../services/authService";
import styles from "./ThirdAscensionPhotoBoothEntrance2.module.css";
function ThirdAscensionPhotoBoothEntrance2() {
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/PhotoboothEntrance2`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  let history = useNavigate();
  return (
    <div className={styles["mentor-card-screen-background"]}>
      <div className='col-md-12 col-xs-12'>
        <div className='row'>
          <div
            className={styles["btn-back-envirtual-container"] + " col-md-12"}
            onClick={() => {
              history("/asc3/photoboothentrance");
            }}
          >
            <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
            <label className={styles["back-btn-label"]}>Back</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-9 col-xs-0'></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "85vh",
            }}
            className='col-md-3 col-xs-12'
          >
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className='mt-4 ml-3'
            >
              <div
                className={styles["takePhoto"]}
                onClick={() => {
                  history("/asc3/takephoto");
                }}
              ></div>
              <button
                className={styles["btn-styling"]}
                onClick={() => {
                  history("/asc3/gallerywall");
                }}
              >
                View Gallery
              </button>
              <button
                className={styles["btn-styling"] + " mt-4"}
                onClick={() => {
                  history("/asc3/mosaicwall");
                }}
              >
                View Mosaic Wall
              </button>
            </div>
          </div>
        </div>

        {/* <img
          className='logoContainer mb-2'
          alt='login-background'
          src='/assets/ic-logo-navbar.png'
        ></img> */}
      </div>
    </div>
  );
}

export default ThirdAscensionPhotoBoothEntrance2;
