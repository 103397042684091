import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Axios from "axios";
function AddParticipants(props) {
  const [data, setdata] = useState([]);

  const history = useNavigate();

  const onSubmit = () => {
    console.log(process.env.REACT_APP_SERVER_URL);
    Axios.post(`${process.env.REACT_APP_SERVER_URL}projects/Addusers/`, {
      id: props.location.state._id,
      emails: data,
    }).then((data) => history("/user/project"));
  };
  let counter;
  const addTextBox = (value) => {
    let elem = document.querySelector("#textBoxes");
    console.log(data);
    if (elem.firstChild !== null) {
      counter = parseInt(elem.firstChild.id) + 1;
    } else {
      counter = 0;
    }
    var inp = document.createElement("input");
    inp.type = "email";
    inp.className = "form-control col-md-5 col-xs-12 mt-2 mb-2 mr-2 ml-4";
    inp.id = counter;
    var remove = document.createElement("label");
    remove.innerHTML = "x";
    remove.className = counter;
    remove.onclick = removeValue.bind(counter);

    if (typeof value == "string") {
      remove.setAttribute("data-email", value);
      inp.value = value;
      let newdata = data;
      newdata.push(value);
      setdata(newdata);
      inp.onload = handleChange.bind(this);
    }
    elem.prepend(remove);
    elem.prepend(inp);

    inp.onchange = handleChange.bind(this);
  };

  const handleChange = (e) => {
    var change = data;

    if (parseInt(e.target.id) <= change.length) {
      change[parseInt(e.target.id)] = e.target.value.toLowerCase();
    } else {
      change.push(e.target.value.toLowerCase());
    }
    setdata(change);
  };

  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        addTextBox(element[0]);
      }
    };
    reader.readAsBinaryString(f);
  };
  function removeValue(e) {
    let email = e.target.getAttribute("data-email");
    let elem = document.getElementById(e.target.className);
    elem.parentElement.removeChild(e.target);
    elem.parentElement.removeChild(elem);
    let newData = data;
    let i = newData.indexOf(email);
    console.log(e.target.className);
    if (i > -1) {
      let deleted = newData.splice(i, 1);
      console.log(deleted);
    }
    setdata(newData);
  }
  return (
    <div className='maindiv row'>
      <img
        src='/assets/background.png'
        className='mentor-card-screen-background'
        alt=''
      />

      <Link
        type='button'
        className='btn btn-light mb-2 '
        style={{ position: "fixed", right: 15, top: 5 }}
        to='/user/project'
      >
        Back to Projects
      </Link>
      <div className='col-md-3'></div>
      <div
        className='col-md-6 col-xs-6 mt-5 bs-stepper'
        style={{ position: "relative" }}
      >
        <div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4>Add Project Participants</h4>
              <button
                className='btn btn-light text-center'
                style={{ borderRadius: "50px" }}
                onClick={addTextBox}
              >
                +
              </button>
              <input
                type='file'
                className='btn btn-light text-center mb-2'
                onChange={handleUpload}
              ></input>
            </div>
            <div className='row' id='textBoxes'></div>

            <button
              type='submit'
              className='btn btn-light mr-3 ml-3 float-right'
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default AddParticipants;
