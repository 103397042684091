import React, { useEffect } from "react";
import styles from "./WivaNewsLetter.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Carousel, { consts } from "@itseasy21/react-elastic-carousel";
import { getCurrentUser } from "../../services/authService";
import { isMobile } from "react-device-detect";
function WIVANewsLetter() {
  const portraitArrows = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='23'
          viewBox='0 0 25 23'
          fill='red'
          style={{ transform: "rotate(-90deg)" }}
        >
          <path
            d='M7.78305 2.70678L0.714418 14.954C0.242644 15.7727 -0.00371398 16.7 4.208e-05 17.6428C0.00379814 18.5857 0.257536 19.5111 0.735819 20.3261C1.2141 21.1411 1.90012 21.8171 2.72509 22.2864C3.55006 22.7557 4.48499 23.0018 5.43614 22.9999L19.5548 22.9999C20.5074 23.0055 21.4446 22.7618 22.2718 22.2934C23.099 21.825 23.7868 21.1485 24.2659 20.3323C24.7449 19.516 24.9981 18.5889 25 17.6445C25.0019 16.7002 24.7523 15.7721 24.2765 14.954L17.2203 2.70678C16.7447 1.8843 16.0585 1.20087 15.231 0.725583C14.4036 0.250297 13.4642 1.31881e-06 12.5079 1.36061e-06C11.5515 1.40241e-06 10.6122 0.250297 9.7847 0.725584C8.95724 1.20087 8.27103 1.8843 7.79545 2.70678L7.78305 2.70678ZM13.1682 4.9964L20.2276 17.2405C20.2949 17.3573 20.3301 17.4896 20.3296 17.6242C20.3292 17.7587 20.2931 17.8908 20.2249 18.0072C20.1568 18.1236 20.0591 18.2202 19.9415 18.2873C19.8239 18.3545 19.6905 18.3899 19.5548 18.3899L5.43614 18.3899C5.29978 18.3926 5.16529 18.3582 5.04724 18.2905C4.9292 18.2227 4.8321 18.1243 4.76648 18.0058C4.69657 17.8884 4.65914 17.7548 4.65797 17.6185C4.6591 17.4855 4.69545 17.3552 4.76338 17.2405L11.8227 4.9964C11.8905 4.87889 11.9885 4.78122 12.1066 4.7133C12.2247 4.64537 12.3589 4.6096 12.4955 4.6096C12.632 4.6096 12.7662 4.64537 12.8843 4.7133C13.0025 4.78122 13.1004 4.87889 13.1682 4.9964Z'
            fill='#e60000'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='23'
          viewBox='0 0 25 23'
          fill='none'
          style={{ transform: "rotate(-90deg)" }}
        >
          <path
            d='M17.217 20.2932L24.2856 8.04605C24.7574 7.22732 25.0037 6.30003 25 5.35715C24.9962 4.41428 24.7425 3.48895 24.2642 2.67394C23.7859 1.85893 23.0999 1.18289 22.2749 0.713595C21.4499 0.244299 20.515 -0.0017578 19.5639 9.4258e-05L5.4452 9.36409e-05C4.4926 -0.00550995 3.55537 0.238195 2.72819 0.706589C1.901 1.17498 1.21316 1.85148 0.73414 2.66774C0.255119 3.48401 0.00188236 4.41114 1.121e-05 5.35548C-0.00185994 6.29982 0.247701 7.22792 0.723484 8.04604L7.77971 20.2932C8.25529 21.1157 8.9415 21.7991 9.76896 22.2744C10.5964 22.7497 11.5358 23 12.4921 23C13.4485 23 14.3878 22.7497 15.2153 22.2744C16.0428 21.7991 16.729 21.1157 17.2045 20.2932L17.217 20.2932ZM11.8318 18.0036L4.77244 5.75949C4.70512 5.64266 4.66993 5.51035 4.67039 5.37581C4.67085 5.24126 4.70694 5.10919 4.77506 4.99281C4.84317 4.87643 4.94093 4.77983 5.05853 4.71266C5.17614 4.64549 5.30947 4.61012 5.4452 4.61007L19.5639 4.61007C19.7002 4.60742 19.8347 4.64182 19.9528 4.70954C20.0708 4.77726 20.1679 4.87572 20.2335 4.99424C20.3034 5.1116 20.3409 5.24518 20.342 5.38148C20.3409 5.51446 20.3046 5.64482 20.2366 5.75949L13.1773 18.0036C13.1095 18.1211 13.0115 18.2188 12.8934 18.2867C12.7753 18.3546 12.6411 18.3904 12.5045 18.3904C12.368 18.3904 12.2338 18.3546 12.1157 18.2867C11.9975 18.2188 11.8996 18.1211 11.8318 18.0036Z'
            fill='#e60000'
          />
        </svg>
      );
    return (
      <span onClick={onClick} disabled={isEdge} className={styles["space"]}>
        {pointer}
      </span>
    );
  };
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='23'
          viewBox='0 0 25 23'
          fill='red'
        >
          <path
            d='M7.78305 2.70678L0.714418 14.954C0.242644 15.7727 -0.00371398 16.7 4.208e-05 17.6428C0.00379814 18.5857 0.257536 19.5111 0.735819 20.3261C1.2141 21.1411 1.90012 21.8171 2.72509 22.2864C3.55006 22.7557 4.48499 23.0018 5.43614 22.9999L19.5548 22.9999C20.5074 23.0055 21.4446 22.7618 22.2718 22.2934C23.099 21.825 23.7868 21.1485 24.2659 20.3323C24.7449 19.516 24.9981 18.5889 25 17.6445C25.0019 16.7002 24.7523 15.7721 24.2765 14.954L17.2203 2.70678C16.7447 1.8843 16.0585 1.20087 15.231 0.725583C14.4036 0.250297 13.4642 1.31881e-06 12.5079 1.36061e-06C11.5515 1.40241e-06 10.6122 0.250297 9.7847 0.725584C8.95724 1.20087 8.27103 1.8843 7.79545 2.70678L7.78305 2.70678ZM13.1682 4.9964L20.2276 17.2405C20.2949 17.3573 20.3301 17.4896 20.3296 17.6242C20.3292 17.7587 20.2931 17.8908 20.2249 18.0072C20.1568 18.1236 20.0591 18.2202 19.9415 18.2873C19.8239 18.3545 19.6905 18.3899 19.5548 18.3899L5.43614 18.3899C5.29978 18.3926 5.16529 18.3582 5.04724 18.2905C4.9292 18.2227 4.8321 18.1243 4.76648 18.0058C4.69657 17.8884 4.65914 17.7548 4.65797 17.6185C4.6591 17.4855 4.69545 17.3552 4.76338 17.2405L11.8227 4.9964C11.8905 4.87889 11.9885 4.78122 12.1066 4.7133C12.2247 4.64537 12.3589 4.6096 12.4955 4.6096C12.632 4.6096 12.7662 4.64537 12.8843 4.7133C13.0025 4.78122 13.1004 4.87889 13.1682 4.9964Z'
            fill='#e60000'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='23'
          viewBox='0 0 25 23'
          fill='none'
        >
          <path
            d='M17.217 20.2932L24.2856 8.04605C24.7574 7.22732 25.0037 6.30003 25 5.35715C24.9962 4.41428 24.7425 3.48895 24.2642 2.67394C23.7859 1.85893 23.0999 1.18289 22.2749 0.713595C21.4499 0.244299 20.515 -0.0017578 19.5639 9.4258e-05L5.4452 9.36409e-05C4.4926 -0.00550995 3.55537 0.238195 2.72819 0.706589C1.901 1.17498 1.21316 1.85148 0.73414 2.66774C0.255119 3.48401 0.00188236 4.41114 1.121e-05 5.35548C-0.00185994 6.29982 0.247701 7.22792 0.723484 8.04604L7.77971 20.2932C8.25529 21.1157 8.9415 21.7991 9.76896 22.2744C10.5964 22.7497 11.5358 23 12.4921 23C13.4485 23 14.3878 22.7497 15.2153 22.2744C16.0428 21.7991 16.729 21.1157 17.2045 20.2932L17.217 20.2932ZM11.8318 18.0036L4.77244 5.75949C4.70512 5.64266 4.66993 5.51035 4.67039 5.37581C4.67085 5.24126 4.70694 5.10919 4.77506 4.99281C4.84317 4.87643 4.94093 4.77983 5.05853 4.71266C5.17614 4.64549 5.30947 4.61012 5.4452 4.61007L19.5639 4.61007C19.7002 4.60742 19.8347 4.64182 19.9528 4.70954C20.0708 4.77726 20.1679 4.87572 20.2335 4.99424C20.3034 5.1116 20.3409 5.24518 20.342 5.38148C20.3409 5.51446 20.3046 5.64482 20.2366 5.75949L13.1773 18.0036C13.1095 18.1211 13.0115 18.2188 12.8934 18.2867C12.7753 18.3546 12.6411 18.3904 12.5045 18.3904C12.368 18.3904 12.2338 18.3546 12.1157 18.2867C11.9975 18.2188 11.8996 18.1211 11.8318 18.0036Z'
            fill='#e60000'
          />
        </svg>
      );
    return (
      <span onClick={onClick} disabled={isEdge} className={styles["space"]}>
        {pointer}
      </span>
    );
  };
  const [newsLetterLink, setNewsLetter] = React.useState("");
  const [newsLetterLinks, setNewsLetters] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [subscribed, setSubscribed] = React.useState(false);
  const [isPortrait, setIsPortrait] = React.useState(false);
  useEffect(() => {
    if (isMobile)
      if (window.matchMedia("(orientation: portrait)").matches) {
        // Portrait orientation
        setIsPortrait(true);
        // Do something when in portrait mode
      }
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivaconfig/getconfigbyCat`, {
        configGroup: "News Letter",
      })

      .then((res) => {
        debugger;
        if (res.data) {
          setNewsLetters(res.data.map((el) => el));
          setNewsLetter(res.data[0].configValue);
        }
        setLoading(false);
      });
    getCurrentUser().then((r) => {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}wivnewsletter/checksubscribed`,
          {
            email: r.preferred_username,
          }
        )

        .then((res) => {
          if (res.data == "true") setSubscribed(true);
        });
    });
  }, []);
  const subscribeToNewsLetter = () => {
    getCurrentUser().then((r) => {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivnewsletter/subscribe`, {
          email: r.preferred_username,
        })

        .then((res) => {
          debugger;
          setSubscribed(true);
        });
    });
  };
  const unsubscribeToNewsLetter = () => {
    getCurrentUser().then((r) => {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivnewsletter/unsubscribe`, {
          email: r.preferred_username,
        })

        .then((res) => {
          debugger;
          setSubscribed(false);
        });
    });
  };
  const history = useNavigate();
  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          {subscribed ? (
            <button
              className={styles["drop-down-btn-unsubscribe"]}
              onClick={unsubscribeToNewsLetter}
            >
              Un-Subscribe
            </button>
          ) : (
            <button
              className={styles["drop-down-btn"]}
              onClick={subscribeToNewsLetter}
            >
              Subscribe
            </button>
          )}

          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={styles["back-div"]}
            onClick={() => {
              history("/wiv");
            }}
          >
            <path
              d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
              fill='#e60000'
            />
            <path
              d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
              fill='#e60000'
            />
          </svg>
          <div className={styles["joinInnerContainer"]}>
            <div className={styles["main-session-stage"]}>
              <img
                src='/wiv/wivnewsletter.svg'
                className={styles["onimage-bg"]}
              ></img>
              {loading == false ? (
                <>
                  <div className={styles["stage-frame-div"]}>
                    <iframe
                      src={newsLetterLink}
                      className={styles["video-background"]}
                      frameborder='0'
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className={styles["play-list"]}>
                    <Carousel
                      verticalMode={
                        isMobile ? (isPortrait ? false : true) : true
                      }
                      renderArrow={
                        isMobile
                          ? isPortrait
                            ? portraitArrows
                            : myArrow
                          : myArrow
                      }
                      itemsToShow={isMobile ? (isPortrait ? 2 : 3) : 3}
                    >
                      {newsLetterLinks.map((r) => {
                        return (
                          <>
                            <>
                              <div>
                                {r.islive ? (
                                  <span
                                    className={
                                      styles["badge-iframe-completed"] +
                                      " badge badge-success"
                                    }
                                  >
                                    Latest
                                  </span>
                                ) : null}
                                <iframe
                                  id='myIframe'
                                  src={r.configValue}
                                  style={{
                                    paddingBottom: "1%",
                                    border: "none",
                                  }}
                                  className={styles["video-background-small"]}
                                  frameborder='0'
                                  allowfullscreen
                                  onClick={() => {
                                    setNewsLetter(r);
                                  }}
                                ></iframe>
                              </div>
                              <div
                                className={styles["video-background-small"]}
                                onClick={() => {
                                  setNewsLetter(r.configValue);
                                }}
                                style={{ position: "absolute" }}
                              >
                                <div
                                  className={styles["clickable-part-small"]}
                                ></div>
                              </div>
                            </>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIVANewsLetter;
