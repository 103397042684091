import axios, { Axios } from "axios";
import React, { useState, useEffect } from "react";
import CriteriaOptionCard from "./criteriaOptionCard";

import ReactPaginate from "react-paginate";
import StatsChart from "./statsChart";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import styles from "./statsChart.module.css";
import StatsChart2 from "./statsChart2";
import StatsLinkChart from "./StatsLinkChart";
import StatsLinkChart2 from "./StatsLinkChart2";
import StatsNPSchart from "./StatsNPSchart";
function StatsCriteria(props) {
  const location = useLocation();
  const [loadBytes, setLoadBytes] = React.useState(false);

  const [Values, setValues] = useState([]);
  const [data, setData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, []);

  const exportvisitsToExcel = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}wivavisits/raw`, {})
      .then((res) => {
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(
          res.data.map((el) => {
            return { email: el.email, date: el.date };
          })
        );

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");

        /* generate an XLSX file */
        XLSX.writeFile(wb, "raw-visits-Stats" + new Date() + ".xlsx");
      });
  };
  const exportlinksToExcel = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}wivavisits/raw`, {})
      .then((res) => {
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(
          res.data.map((el) => {
            return {
              email: el.email,
              link: el.link,
              date: el.date,
              createdAt: new Date(el.createdAt).toLocaleString(),
            };
          })
        );

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");

        /* generate an XLSX file */
        XLSX.writeFile(wb, "raw-links-Stats" + new Date() + ".xlsx");
      });
  };
  const exportNPSToExcel = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}wivanps/raw`, {})
      .then((res) => {
        /* make the worksheet */
        var ws = XLSX.utils.json_to_sheet(
          res.data.map((el) => {
            return {
              email: el.email,
              value: el.surveyVal,
              createdAt: new Date(el.createdAt).toLocaleString(),
            };
          })
        );

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");

        /* generate an XLSX file */
        XLSX.writeFile(wb, "raw-nps-Stats" + new Date() + ".xlsx");
      });
  };
  return (
    <div>
      <img
        src='/assets/_VOIS_Hackathon_keyart2.jpg'
        className='breakroom-listing-screen-background'
        alt=''
      />
      <div className='breakRoom-container-fluid'>
        <div className=' pl-5 nav-button-padding'>
          <img
            src='/assets/background.png'
            className='mentor-card-screen-background'
            alt=''
          />

          <a
            type='button'
            className='btn btn-light mb-2 '
            style={{ position: "absolute", right: 15, top: 5 }}
            onClick={() => {
              window.location.assign("/user/project");
            }}
          >
            Back to Projects
          </a>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12 text-center'>
            <h2 className='mentors-header-page-title'>
              Welcome To Events Stats Page Creator
            </h2>
          </div>
        </div>

        <div className='row'>
          <div className={styles["section-header"] + " col-md-12"}>
            <h3 style={{ color: "white" }}>Visits</h3>

            <button
              style={{ color: "white" }}
              className='btn btn-dark mt-2'
              onClick={() => {
                exportvisitsToExcel();
              }}
            >
              Export Raw data to excel
            </button>
          </div>
          <div className='col-md-12'>
            <div className={styles["chart-div"] + " row"}>
              <StatsChart parentprops={location}></StatsChart>
              <StatsChart2 parentprops={location}></StatsChart2>
            </div>
          </div>
          <div className={styles["section-header"] + " col-md-12"}>
            <h3 style={{ color: "white" }}>Links</h3>

            <button
              style={{ color: "white" }}
              className='btn btn-dark mt-2'
              onClick={() => {
                exportlinksToExcel();
              }}
            >
              Export Raw data to excel
            </button>
          </div>
          <div className='col-md-12'>
            <div className={styles["chart-div"] + " row"}>
              <StatsLinkChart parentprops={location}></StatsLinkChart>
              <StatsLinkChart2 parentprops={location}></StatsLinkChart2>
            </div>
          </div>
          <div className={styles["section-header"] + " col-md-12"}>
            <h3 style={{ color: "white" }}>NPS</h3>

            <button
              style={{ color: "white" }}
              className='btn btn-dark mt-2'
              onClick={() => {
                exportNPSToExcel();
              }}
            >
              Export Raw data to excel
            </button>
          </div>
          <div className='col-md-12'>
            <div className={styles["chart-div"] + " row"}>
              <StatsNPSchart parentprops={location}></StatsNPSchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCriteria;
