import React, { useEffect } from "react";
import styles from "./ThirdAscensionGallerywall.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import auth, { getCurrentUser } from "../../services/authService";
function ThirdAscensionGallerywall() {
  const [imageLinks, setImageLinks] = React.useState([]);
  const [imageBytes, setImageBytes] = React.useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/GalleryWall`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}mosaicWall/asc3-1`)
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            imageLinks.push(res.data[i]);

            axios.get(`${res.data[i]}`).then((res) => {
              imageBytes.push(res.data);
              if (imageLinks.length === imageBytes.length) {
                setLoadBytes(true);
              }
            });
          }
        }
      });
  }, []);

  const imageContainer = () => {
    return imageLinks.map((img) => {
      return (
        <img
          className={`col-md-2 asc-mosaic-wall-images  p-0`}
          style={{
            backgroundImage: `url(${img})`,
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          src={img}
        ></img>
      );
    });
  };

  return (
    <div className='breakRoom-container-fluid'>
      <div className=' pl-5 nav-button-padding'>
        <img
          src='/assets/thirdAscension/gallerywall-bg.png'
          className='mentor-card-screen-background'
          alt=''
        />
      </div>
      <div
        className={styles["btn-back-envirtual-container"] + " col-md-12"}
        onClick={() => {
          history("/asc3/photoboothentrance2");
        }}
      >
        <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
        <label className={styles["back-btn-label"]}>Back</label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
        }}
        className='col-md-12 col-xs-12  mt-5'
      >
        <div
          className={styles["asc-mosaic-background "]}
          style={{
            position: "relative",
            left: "4px",
            overflow: "hidden",
            width: "95%",
          }}
        >
          {loadBytes === true ? (
            <>
              <div className='row' id='mosaicContainer'>
                {imageContainer()}
              </div>
            </>
          ) : (
            <div className='text-center'>
              <div className='spinner-grow text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-secondary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-success' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-danger' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-warning' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-info' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-light' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-dark' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ThirdAscensionGallerywall;
