import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import GameBoard from "./components/GameBoard";
import Banner from "./components/Banner";
import { Link } from "react-router-dom";
import { GAME_STATUS } from "./constants";
const bg = "../../../assets/background.png";
const useStyles = createUseStyles({
  header: {
    textAlign: "center",
    color: "white",
  },
  footer: {
    textAlign: "center",
  },
  bg: {
    position: "fixed",
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    overflowX: "scroll",
    backgroundImage: `url(${bg})`,
  },
});

const RememberCards = () => {
  const [gameStatus, setGameStatus] = useState(GAME_STATUS.CREATING);
  const classes = useStyles();

  const gameFinished = () => {
    setGameStatus(GAME_STATUS.FINISHED);
  };

  const resetGame = () => {
    setGameStatus(GAME_STATUS.RESETTING);
  };

  const setGameToInProgress = () => {
    setGameStatus(GAME_STATUS.IN_PROGRESS);
  };

  return (
    <div className={classes.bg}>
      <div className=' pl-5 nav-button-padding'>
        <img
          src='/assets/background.png'
          className='mentor-card-screen-background'
          alt=''
        />

        <Link
          type='button'
          className='btn btn-light mb-2 '
          style={{ position: "fixed", right: 15, top: 5 }}
          to='/breakroom'
        >
          Back to Fun Area
        </Link>
      </div>
      <header className={classes.header}>
        <h1>Card Match Game</h1>
      </header>
      <div>
        <GameBoard
          gameStatus={gameStatus}
          handleStart={setGameToInProgress}
          handleFinish={gameFinished}
        />
        {gameStatus === GAME_STATUS.FINISHED && (
          <Banner handleClick={resetGame} />
        )}
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
};

export default RememberCards;
