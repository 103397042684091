import React, { useEffect } from "react";
import styles from "./EarthSplashScreen.module.css";
import EarthAr from "../EarthAr";
import { isMobile } from "react-device-detect";

function EarthSplashScreen() {
  const [step, setStep] = React.useState(1);
  const [os, setOS] = React.useState(isMobile);
  useEffect(() => {
    console.log(os);
  }, []);
  const updateOneStep = () => {
    let newstep = step + 1;
    setStep(newstep);
  };
  const updateOneStepBack = () => {
    let newstep = step - 1;
    setStep(newstep);
  };
  const goToProject = () => {
    setStep(null);
  };
  return (
    <div className={styles["main-container"]}>
      {" "}
      <div
        className='home_logo_size'
        alt=''
        style={{
          animation: "changeBG  3s 3s forwards",
          animationTimingFunction: "linear",
        }}
      />
      {os ? (
        step == 1 ? (
          <div
            className={styles["screen-container"]}
            style={{
              animation: "navigator  3s 5s forwards",
              animationTimingFunction: "linear",
            }}
          >
            <div className={styles["header-div"]}>
              {step != 1 ? (
                <div className={styles["backDiv"]}>
                  <img src='/earth/backArrow.svg'></img>
                  <span className={styles["back-text"]}>Back</span>
                </div>
              ) : (
                <div className={styles["backDiv"]}>
                  <img src='/earth/backArrow.svg' className='d-none'></img>
                  <span className='d-none'>Back</span>
                </div>
              )}
              <div className={styles["skipDiv"]} onClick={goToProject}>
                <span className={styles["skip-text"]}>SKIP</span>
                <img src='/earth/skip-arrow.svg'></img>
              </div>
            </div>
            <div className={styles["main-page-container"]}>
              <img className={styles["step-img"]} src='/earth/step1.png'></img>
              <span className={styles["step-title"]}>zOOM IN AND OUT</span>
              <span className={styles["step-description"]}>
                You can zoom in and out of the globe by using a two-finger
                gesture. To zoom in, simply touch the screen with two fingers
                and spread them apart. To zoom out, touch the screen with two
                fingers and pinch them together.
              </span>
              <div className={styles["step-footer"]}>
                <div className={styles["stepper-container"]}>
                  <div className={styles["active-step"]}></div>
                  <div className={styles["inActive-step"]}></div>
                  <div className={styles["inActive-step"]}></div>
                </div>
                <span className={styles["next-btn"]} onClick={updateOneStep}>
                  Next
                </span>
              </div>
            </div>
          </div>
        ) : step == 2 ? (
          <div
            className={styles["screen-container"]}
            style={{
              animation: "navigator  3s 5s forwards",
              animationTimingFunction: "linear",
            }}
          >
            <div className={styles["header-div"]}>
              {step != 1 ? (
                <div className={styles["backDiv"]} onClick={updateOneStepBack}>
                  <img src='/earth/backArrow.svg'></img>
                  <span className={styles["back-text"]}>Back</span>
                </div>
              ) : null}
              <div className={styles["skipDiv"]} onClick={goToProject}>
                <span className={styles["skip-text"]}>SKIP</span>
                <img src='/earth/skip-arrow.svg'></img>
              </div>
            </div>
            <div className={styles["main-page-container"]}>
              <img className={styles["step-img"]} src='/earth/step2.png'></img>
              <span className={styles["step-title"]}>ROTATE THE GLOBE!</span>
              <span className={styles["step-description"]}>
                You can rotate the globe by using a one-finger gesture. To
                rotate an image clockwise, simply touch the screen with one
                finger and drag it to the right. To rotate an image
                counterclockwise, touch the screen with one finger and drag it
                to the left.
              </span>
              <div className={styles["step-footer"]}>
                <div className={styles["stepper-container"]}>
                  <div className={styles["inActive-step"]}></div>
                  <div className={styles["active-step"]}></div>

                  <div className={styles["inActive-step"]}></div>
                </div>
                <span className={styles["next-btn"]} onClick={updateOneStep}>
                  Next
                </span>
              </div>
            </div>
          </div>
        ) : step == 3 ? (
          <div
            className={styles["screen-container"]}
            style={{
              animation: "navigator  3s 5s forwards",
              animationTimingFunction: "linear",
            }}
          >
            <div className={styles["header-div"]}>
              {step != 1 ? (
                <div className={styles["backDiv"]}>
                  <img src='/earth/backArrow.svg'></img>
                  <span
                    className={styles["back-text"]}
                    onClick={updateOneStepBack}
                  >
                    Back
                  </span>
                </div>
              ) : null}
              {step != 3 ? (
                <div className={styles["skipDiv"]} onClick={goToProject}>
                  <span className={styles["skip-text"]}>SKIP</span>
                  <img src='/earth/skip-arrow.svg'></img>
                </div>
              ) : null}
            </div>
            <div className={styles["main-page-container"]}>
              <img className={styles["step-img"]} src='/earth/step3.png'></img>
              <span className={styles["step-title"]}>Select the country</span>
              <span className={styles["step-description"]}>
                Point your camera at the globe and the ray will automatically
                highlight the country you are pointing at. or use the search
                box.
              </span>
              <div className={styles["step-footer"]}>
                <div className={styles["stepper-container"]}>
                  <div className={styles["inActive-step"]}></div>
                  <div className={styles["inActive-step"]}></div>
                  <div className={styles["active-step"]}></div>
                </div>
                <span className={styles["next-btn"]} onClick={goToProject}>
                  Start
                </span>
              </div>
            </div>
          </div>
        ) : (
          <EarthAr></EarthAr>
        )
      ) : (
        <div
          className={styles["screen-container"] + " row"}
          style={{
            animation: "navigator  3s 5s forwards",
            animationTimingFunction: "linear",
          }}
        >
          <div className={styles["webview-div-holder"] + " col-md-6"}>
            <span className={styles["webview-div-holder-title"]}>
              Use your mobile phone or tablet
            </span>
            <span className={styles["webview-div-holder-description"]}>
              We're glad you're here. We designed our app to be used on mobile
              devices. We believe that you'll have a better experience if you
              use our app on a mobile or tablet device.
            </span>
          </div>
          <div className={styles["webview-div-img-holder"] + " col-md-6"}>
            <img src='/earth/webview.png'></img>
          </div>
        </div>
      )}
    </div>
  );
}

export default EarthSplashScreen;
