import axios from "axios";
import React, { useEffect } from "react";
import Countdown from "react-countdown";
import styles from "./AscensionHome.module.css";
function AscensionHome() {
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/Home`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
  }, []);
  let startDate = new Date(Date.UTC(2023, 0, 26, 8, 25));
  let loggedin = new Date();
  useEffect(() => {}, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      window.location.href = "/asc3";
    } else {
      // Render a countdown
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles["asc3-timer"]}>
            <span className={styles["remaining"]}>{days + " "}</span>
            <p className={styles["remaining2"]}> DAYS</p>
          </div>
          <div className={styles["asc3-timer"]}>
            <span className={styles["remaining"]}>{hours + " "}</span>
            <p className={styles["remaining2"]}> HOURS</p>
          </div>
          <div className={styles["asc3-timer"]}>
            <span className={styles["remaining"]}>{minutes + " "}</span>
            <p className={styles["remaining2"]}> MINUTES</p>
          </div>
          <div className={styles["asc3-timer"]}>
            <span className={styles["remaining"]}>{seconds + " "}</span>
            <p className={styles["remaining2"]}> SECONDS</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='breakRoom-container-fluid'>
      <div className=' pl-5 nav-button-padding'>
        <img
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            backgroundRepeat: "no-repeat !important",
            backgroundSize: "100% 132% !important",
            top: "0",
            left: "0",
            opacity: 1,
          }}
          className='section-text'
          src='/assets/thirdAscension/countDown.png'
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          alignItems: "center",
          height: "80vh",
        }}
        className='col-md-12 col-xs-12  mt-5'
      >
        <div
          style={{
            position: "relative",
            left: "4px",
            overflow: "hidden",
            width: "95%",
            justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src='/assets/glb/newmodel/asc-logo.png'
              className={styles["img"]}
              style={{ width: "7.5rem" }}
            ></img>
            <label label className={styles["instruction"]}>
              3<sup>rd</sup>Technology Ascension ​<br /> Towords Digital Future
            </label>
          </div>
          <label className={styles["coming"]}>coming soon</label>
          <Countdown date={startDate} renderer={renderer} />
          {startDate.getTime() > loggedin.getTime() ? null : (
            <button
              className='downloadbtn'
              onClick={() => {
                window.location.href = `/summit2023`;
              }}
            >
              Join Now{" "}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AscensionHome;
