import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./addSuperAdmin.css";
import { Link } from "react-router-dom";

const AddSuperAdmin = () => {
  const [email, setEmail] = useState("");
  const history = useNavigate();
  function validateEmail(email) {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  const emailHandler = (e) => {
    if (
      !e.target.value.startsWith("+") &&
      !e.target.value.startsWith("=") &&
      !e.target.value.startsWith("-")
    ) {
      setEmail(e.target.value);
    }
  };
  const onSubmit = () => {
    if (validateEmail(email)) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}superadmin/addsuperadmin`, {
          email: email,
        })
        .then((res) => {
          console.log(res);
          history("/projects");
        });
    }
  };
  return (
    <div className='maindiv row'>
      <img
        src='/assets/background.png'
        className='mentor-card-screen-background'
        alt=''
      />
      <Link
        type='button'
        className='btn btn-light mb-2 '
        style={{ position: "fixed", right: 15, top: 5, width: " 15vw" }}
        to='/projects'
      >
        Back to Projects
      </Link>
      <div className='col-md-3'></div>
      <div className='subParentDiv col-md-6 col-xs-12'>
        <div className='parent text-center'>
          <img
            className='logoContainer mb-2'
            alt='login-background'
            src='/assets/ic-logo-navbar.png'
          ></img>
          <h1 className='add-admin-header'>Add super admin</h1>
          <div className='row'>
            <label className='controller-label col-md-2 mr-5'>Email: </label>
            <input
              type='email'
              onChange={emailHandler}
              className='btn btn-light text-center mb-2 col-md-8'
            ></input>
          </div>
          <div>
            <button
              type='button'
              className='btn  btn-outline-light button-login mt-4  '
              onClick={onSubmit}
            >
              <span className='btnText'>Add</span>
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default AddSuperAdmin;
