import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function AddAssets(props) {
  const history = useNavigate();
  const UploadImage = () => {
    let fileUploader = document.getElementById("photoboothUploader");
    fileUploader.click();
  };
  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}mosaicWall/photoboothframe`,
        {
          imageBytes: reader.result,
          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
          eventName: localStorage.getItem("selectedEventName"),
        }
      );
    };
  };
  const UploadMainCardImage = () => {
    let fileUploader = document.getElementById("maincardUploader");
    fileUploader.click();
  };
  const handleMainCardUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}eventassets/backgroundimage`,
        {
          imageBytes: reader.result,
          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
          eventName: localStorage.getItem("selectedEventName"),
        }
      );
    };
  };

  return (
    <div>
      <img
        src='/assets/_VOIS_Hackathon_keyart2.jpg'
        className='breakroom-listing-screen-background'
        alt=''
      />
      <div className='breakRoom-container-fluid'>
        <div className=' pl-5 nav-button-padding'>
          <img
            src='/assets/background.png'
            className='mentor-card-screen-background'
            alt=''
          />

          <Link
            type='button'
            className='btn btn-light mb-2 '
            style={{ position: "fixed", right: 15, top: 5 }}
            to='/projects'
          >
            Back to Projects
          </Link>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12 text-center'>
            <h2 className='mentors-header-page-title'>
              Welcome To Events Assets Manager
            </h2>
            <h6 className='mentors-header-page-title'>
              Create YOUR frames and events specs as you imagine.
            </h6>
          </div>
        </div>

        <div className='row cards-flip-container'>
          <div className='col-md-6 col-lg-4 col-xs-12 car-margin d-flex justify-content-center pr-4'>
            <div className='playground-flip'>
              <div
                className='playground-front'
                style={{
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
                  backgroundImage: `url(/assets/uploadphotoboothframe.jfif)`,
                  backgroundSize: "102% 107%",
                }}
              >
                <h1 className='text-shadow'>PhotoBooth Frame</h1>
              </div>
              <div className='playground-back text-center'>
                <h2>Upload A frame to be set for this event photobooth</h2>
                <div className='mentor-card-button-container '>
                  <input
                    type='file'
                    className='btn btn-light text-center mb-2'
                    onChange={handleUpload}
                    style={{ display: "none" }}
                    id='photoboothUploader'
                  ></input>
                  <button
                    rel='noreferrer'
                    to='/AddPhotoFrame'
                    className='  playground-card-button'
                    onClick={UploadImage}
                  >
                    Upload Image
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-4 col-xs-12 car-margin d-flex justify-content-center pr-4'>
            <div className='playground-flip'>
              <div
                className='playground-front'
                style={{
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
                  backgroundImage: `url(/assets/maincard-background.jpg)`,
                  backgroundSize: "102% 107%",
                }}
              >
                <h1 className='text-shadow'>MainCard Background</h1>
              </div>
              <div className='playground-back text-center'>
                <h2>Upload An image to be set for this event main card</h2>
                <div className='mentor-card-button-container '>
                  <input
                    type='file'
                    className='btn btn-light text-center mb-2'
                    onChange={handleMainCardUpload}
                    style={{ display: "none" }}
                    id='maincardUploader'
                  ></input>
                  <button
                    rel='noreferrer'
                    to='/AddPhotoFrame'
                    className='  playground-card-button'
                    onClick={UploadMainCardImage}
                  >
                    Upload Image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default AddAssets;
