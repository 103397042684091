import React, { useEffect } from "react";
import styles from "./WIVAMessage.module.css";
import onlineIcon from "../../../assets/icons/onlineIcon.png";
import offlineIcon from "../../../assets/icons/x-circle.png";
import { toArray } from "react-emoji-render";
import { convert } from "html-to-text";
import "./WivaMessage.css";
import parse from "html-react-parser";
import EmojiPicker from "emoji-picker-react";
function WIVAMessage({
  message: { _id, text, user, repliedtoHTML, repliedtoHTMLIndex, reactions },
  name,
  users,
  onmessageReply,
  msgindex,
  addEmoji,
}) {
  const [reactionCount, setReactionCount] = React.useState(0);
  useEffect(() => {
    if (reactions != undefined) setReactionCount(reactions.length);
  }, []);
  function removeFromUsersArray(array, nameToRemove) {
    array.forEach((obj, l) => {
      if (obj.users && Array.isArray(obj.users)) {
        const index = obj.users.findIndex((user) => user === nameToRemove);
        if (index !== -1) {
          obj.users.splice(index, 1);
          if (obj.users.length == 0) {
            array.splice(l, 1);
          }
        }
      }
    });
    return array;
  }
  function updateObjectArray(arr, keyValue) {
    let found = false;
    debugger;
    if (arr == undefined) {
      arr = [];
    }
    arr = removeFromUsersArray(arr, user);
    arr.forEach((pair, l) => {
      if (pair.key === keyValue) {
        pair.value++;
        pair.users.push(user);
        found = true;
      }
    });
    if (!found) {
      let newObj = {};

      arr.push({ key: keyValue, value: 1, users: [user] });
    }
    return arr;
  }

  const selectEmoji = (emojiData) => {
    reactions = updateObjectArray(reactions, emojiData.imageUrl);
    addEmoji({ _id, reactions });
    // setSelectedEmojiList(emojiData);
    console.log(emojiData);
  };
  const repliedtoMsg = repliedtoHTML ? repliedtoHTML : "";
  // if (document.getElementById(`repliedContainer-${msgindex}`))
  //   document.getElementById(`repliedContainer-${msgindex}`).innerHTML =
  //     repliedtoMsg;

  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if (user?.toLowerCase() === trimmedName) {
    isSentByCurrentUser = true;
  }
  const replyto = (message, user) => {
    onmessageReply({ message, user, msgindex });
  };

  const renderHTMLWithStyles = (htmlString) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  const parseEmojis = (value) => {
    const emojisArray = toArray(value);

    // toArray outputs React elements for emojis and strings for other
    const newValue = emojisArray.reduce((previous, current) => {
      if (typeof current === "string") {
        return previous + current;
      }
      return previous + current.props.children;
    }, "");

    return newValue;
  };
  function wrapMentionsWithHtmlTag(inputString, tag, className) {
    // Regular expression to match mentions starting with '@' and ending with a space
    const mentionRegex = /@(\S+?)(?=\s|$)/g; // Add the global flag back

    let wrappedString = inputString
      .toString()
      .replace(mentionRegex, `<${tag} class="${className}">@$1</${tag}>&nbsp;`);
    wrappedString = wrappedString
      .replaceAll("%20", " ")
      .replaceAll("%3D", "=")
      .replaceAll("%22", "'")
      .replaceAll("%3C", "<")
      .replaceAll("%3A", ":")
      .replaceAll("%3E", ">")
      .replaceAll("%0A", "");
    let content = toArray(wrappedString);

    return renderHTMLWithStyles(parseEmojis(wrappedString));
  }
  return isSentByCurrentUser ? (
    <div className={styles["messageContainer"] + " justifyEnd"}>
      <div className={styles["message-full"]}>
        <div className={styles["messageBox"] + " " + styles["backgroundBlue"]}>
          <div id={`repliedContainer-${msgindex}`}>
            {wrapMentionsWithHtmlTag(repliedtoMsg, "span", "wiva-tag")}
          </div>

          <p
            className={styles["messageTextright"] + " " + styles["colorWhite"]}
          >
            {wrapMentionsWithHtmlTag(text, "span", "wiva-tag")}
          </p>
          <div className={styles["double-tick"]}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='7'
              viewBox='0 0 8 7'
              fill='none'
            >
              <path
                d='M1.59491 7L0 5.47559L0.917918 4.59823L1.59491 5.24537L7.08224 0L8 0.877355L1.59491 7Z'
                fill='#19D15C'
              />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='7'
              viewBox='0 0 8 7'
              fill='none'
            >
              <path
                d='M1.59491 7L0 5.47559L0.917918 4.59823L1.59491 5.24537L7.08224 0L8 0.877355L1.59491 7Z'
                fill='#19D15C'
              />
            </svg>
          </div>
        </div>
        {reactions != undefined ? (
          reactions.length > 0 ? (
            <div
              className={
                styles["reactions-messageBox-right"] +
                " " +
                styles["backgroundLight"]
              }
            >
              {reactions.map((react) => (
                <div className={styles["reaction-div"]}>
                  <span className={styles["reaction-number-right"]}>
                    {react.value}
                  </span>
                  <img
                    src={react.key}
                    className={styles["reaction-emoji"]}
                  ></img>
                </div>
              ))}
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  ) : (
    <div className='messageContainer justifyStart'>
      <div className={styles["online-container"] + " pr-2"}>
        <span className={styles["circle"]}>
          {user[0]}
          {users ? (
            users.filter((r) => r.name == user).length != 0 ? (
              <img
                alt='Online Icon'
                className={styles["online"]}
                src={onlineIcon}
              />
            ) : (
              <img
                alt='Online Icon'
                className={styles["online"]}
                src={offlineIcon}
              />
            )
          ) : null}
        </span>

        {/* <img alt='Online Icon' className={styles["online"]} src={onlineIcon} /> */}
      </div>
      <div className={styles["message-full"]}>
        <div className={styles["messageBox"] + " " + styles["backgroundLight"]}>
          <div id={`repliedContainer-${msgindex}`}>
            {wrapMentionsWithHtmlTag(repliedtoMsg, "span", "wiva-tag")}
          </div>
          <p
            className={
              styles["messageTextleft"] +
              " " +
              styles["colorDark-name"] +
              " pl-10 "
            }
          >
            {user}
          </p>
          <p
            className={
              styles["messageTextleft"] + " " + styles["colorDark"] + " pl-10 "
            }
          >
            {wrapMentionsWithHtmlTag(text, "span", "wiva-tag")}
          </p>
        </div>
        {reactions != undefined ? (
          reactions.length > 0 ? (
            <div
              className={
                styles["reactions-messageBox-left"] +
                " " +
                styles["backgroundLight"]
              }
            >
              {reactions.map((react) => (
                <div className={styles["reaction-div"]}>
                  <span className={styles["reaction-number-left"]}>
                    {react.value}
                  </span>
                  <img
                    src={react.key}
                    className={styles["reaction-emoji"]}
                  ></img>
                </div>
              ))}
            </div>
          ) : null
        ) : null}
      </div>
      {user != "admin" ? (
        <div class='dropdown'>
          <button
            className={styles["dropdown-toggle"]}
            type='button'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <img
              src='/wiv/3-vertical-dots-icon.svg'
              className={styles["more-ddl-icon"]}
            />
          </button>
          <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
            <span
              class='dropdown-item'
              onClick={() => {
                replyto(text, user);
              }}
            >
              <img
                src='/wiv/reply-all-arrow-icon.svg'
                className={styles["more-ddl-icon"]}
              />
              Reply
            </span>
          </div>

          <button
            type='button'
            className={styles["emojis"]}
            onClick={() => {
              if (
                document.getElementById("emojidiv" + _id).style.display ==
                "none"
              )
                document.getElementById("emojidiv" + _id).style.display =
                  "block";
              else {
                document.getElementById("emojidiv" + _id).style.display =
                  "none";
              }
            }}
          >
            {" "}
            <img
              src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f603.png'
              className={styles["emoji-img"]}
            ></img>
          </button>
          <div
            id={"emojidiv" + _id}
            className={styles["emojis-div"]}
            style={{
              display: "none",
            }}
          >
            <EmojiPicker onEmojiClick={selectEmoji} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default WIVAMessage;
