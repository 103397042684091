import React, { useEffect, useRef, useMemo, useState } from "react";
import styles from "./AdminDashBoard.module.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as XLSX from "xlsx";
import io from "socket.io-client";
import axios from "axios";
import { getCurrentUser } from "../../../services/authService";
const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;
let socket;
let socketID;
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function AdminDashBoard() {
  const forceUpdate = useForceUpdate();
  function getRandomItem() {
    let arr = [
      "#171717",
      "#2E2E2E",
      "#454545",
      "#5C5C5C",
      "#737373",
      "#8A8A8A",
      "#A1A1A1",
      "#B8B8B8",
      "#CFCFCF",
      "#DEDEDE",
    ];
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
  }
  const [data, setData] = useState([]);
  const [showSurvey, setShowSurvey] = React.useState(false);
  const [name, setName] = useState("");

  const [userName, setUserName] = useState();
  const [room, setRoom] = useState("WebAR");
  const [eventName, setEventName] = useState("WebAR");
  const toggleSurvey = (survey) => {
    socket.emit("togglesurvey", { showSurvey: survey }, (error) => {
      setShowSurvey(survey);
      forceUpdate();
      if (error) {
        alert(error);
      }
    });
  };
  useEffect(() => {
    socket = io(ENDPOINT, {
      path: "/socket/",
    });

    getCurrentUser().then((r) => {
      setName(r.preferred_username.split(".")[0]);
      setUserName(r.preferred_username);

      socket.on("connect", function () {
        // console.log("Connected! ID: " + socket.id);

        socketID = socket.id;
      });
      setRoom("WebAR");
      setName(name);
      setEventName("WebAR");
      socket.emit(
        "join",
        {
          name: r.preferred_username.split(".")[0],
          userName: r.preferred_username,
          room: "webAR",
          eventName: "WebAR",
        },
        (error) => {
          // console.log("join error", error);
          localStorage.setItem("chatSocket", socketID);
          if (error) {
            alert(error);
          }
        }
      );
    });
    // var toggleEl = document.querySelector("#button");
    // toggleEl.addEventListener("click", function (evt) {
    //   toggleEl.emit("clicked");
    //   ;
    // });
    return function () {
      if (socket.readyState === 1) {
        // <-- This is important
        socket.close();
      }
    };
  }, [ENDPOINT, localStorage.getItem("chatSocket")]);
  useEffect(() => {
    let chart = am4core.create("demographicdatachart", am4charts.PieChart3D);
    axios.get(`${process.env.REACT_APP_SERVER_URL}survey`).then((res) => {
      setData(res.data);
      chart.paddingRight = 20;
      chart.data = res.data.map((el) => {
        return {
          country: el.agreement,
          litres: el.count,
          color: getRandomItem(),
        };
      });

      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable tooltips
      pieSeries.slices.template.tooltipText = "";
      pieSeries.slices.template.propertyFields.stroke = "color";
      pieSeries.slices.template.propertyFields.fill = "color";
      chart.legend = new am4charts.Legend();
      chart.legend.maxHeight = 150;
      chart.legend.scrollable = true;
      chart.legend.valueLabels.template.text = "{value.value}";
      chart.legend.useDefaultMarker = true;
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
    });
  }, []);
  const exportAgreementToExcel = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      data.map((el) => {
        return { agreement: el.agreement, count: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "Summary-Survey-Stats" + new Date() + ".xlsx");
  };
  return (
    <div className={styles["page-content"]}>
      <div className={styles["main-cards"] + " row"}>
        <div className='col-md-12 col-sm-12 '>
          {showSurvey ? (
            <button
              type='button'
              class='btn btn-dark'
              onClick={() => toggleSurvey(false)}
            >
              Hide Survey
            </button>
          ) : (
            <button
              type='button'
              class='btn btn-dark'
              onClick={() => toggleSurvey(true)}
            >
              Show Survey
            </button>
          )}
        </div>
        <div className='col-md-12 col-sm-12 '>
          <div className={styles["card-body"]}>
            <span className={styles["title"]}>Survey Data</span>

            <div id='demographicdatachart' className={styles["chart"]}></div>
            <button
              className={styles["button-style"]}
              onClick={() => {
                exportAgreementToExcel();
              }}
            >
              Export to excel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashBoard;
