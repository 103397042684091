import React from "react";
import { Link } from "react-router-dom";
import { WorkplaceIcon, WorkplaceShareButton } from "react-share";
import styles from "./WIVAChooseFrame.module.css";
import WivaNavBar from "../../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../../WIVASideNavBar/WIVASideNavBar";
import { useNavigate } from "react-router-dom";
function WIVAChooseFrame({ frameUpdate }) {
  const [myframe, setMyFrame] = React.useState("");
  function forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
  const setFrame = (e) => {
    frameUpdate(e);
  };
  const history = useNavigate();
  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          <WivaNavBar></WivaNavBar>

          <WIVASideNavBar></WIVASideNavBar>

          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className={styles["back-div"]}
            onClick={() => {
              history("/wiv/");
            }}
          >
            <path
              d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
              fill='#4A4D4E'
            />
            <path
              d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
              fill='#4A4D4E'
            />
          </svg>

          <div className={styles["joinInnerContainer"]}>
            <div className={styles["first-div-row"] + " row  m-0"}>
              <div className={styles["section-div"] + " col-md-12 col-sm-12"}>
                <div className={styles["tip-div"]}>
                  <span className={styles["tip-number"]}>1</span>
                  <span className={styles["tip-text"]}>
                    Choose One Design For Your Photo Frame
                  </span>
                </div>
                <div className='row'>
                  <div className='col-md-3  col-sm-3 col-6'>
                    <div
                      className={styles["wiv-frame-div"] + " mr-2 "}
                      onClick={() => {
                        setMyFrame("/wiv/frames/frame1.svg");
                      }}
                    >
                      <span className={styles["wiv-video-1"]}>
                        Your Photo Here
                      </span>
                      <img
                        src='/wiv/frames/frame1.svg'
                        className={
                          myframe == "/wiv/frames/frame1.svg"
                            ? styles["summit-frame-selected"]
                            : styles["summit-frame"]
                        }
                      ></img>
                    </div>
                  </div>
                  <div className='col-md-3  col-sm-3 col-6'>
                    <div
                      className={styles["wiv-frame-div"] + " mr-2 "}
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={() => {
                        setMyFrame("/wiv/frames/frame2.svg");
                      }}
                    >
                      <span className={styles["wiv-video"]}>
                        Your Photo Here
                      </span>
                      <img
                        src='/wiv/frames/frame2.svg'
                        className={
                          myframe == "/wiv/frames/frame2.svg"
                            ? styles["summit-frame-selected"]
                            : styles["summit-frame"]
                        }
                      ></img>
                    </div>
                  </div>
                  <div className='col-md-3  col-sm-3 col-6'>
                    <div
                      className={styles["wiv-frame-div"] + " mr-2 "}
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={() => {
                        setMyFrame("/wiv/frames/frame3.svg");
                      }}
                    >
                      <span className={styles["wiv-video"]}>
                        Your Photo Here
                      </span>
                      <img
                        src='/wiv/frames/frame3.svg'
                        className={
                          myframe == "/wiv/frames/frame3.svg"
                            ? styles["summit-frame-selected"]
                            : styles["summit-frame"]
                        }
                      ></img>
                    </div>
                  </div>
                  <div className='col-md-3  col-sm-3 col-6'>
                    <div
                      className={styles["wiv-frame-div"] + " mr-2 "}
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={() => {
                        setMyFrame("/wiv/frames/frame4.svg");
                      }}
                    >
                      <span className={styles["wiv-video"]}>
                        Your Photo Here
                      </span>
                      <img
                        src='/wiv/frames/frame4.svg'
                        className={
                          myframe == "/wiv/frames/frame4.svg"
                            ? styles["summit-frame-selected"]
                            : styles["summit-frame"]
                        }
                      ></img>
                    </div>
                  </div>
                  <div className={styles["finalize-div"] + " col-12"}>
                    <button
                      className={styles["btn-styling"]}
                      disabled={myframe == ""}
                      onClick={() => {
                        setFrame(myframe);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIVAChooseFrame;
