import WivaNavBar from "../WIVANavBar/WivaNavBar";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import queryString from "query-string";
import styles from "./WivaChatEntryPage.module.css";
import Select from "react-select";
import Axios from "axios";
import auth, { getCurrentUser } from "../../services/authService";
import { Link, useNavigate } from "react-router-dom";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function WIVAChatEnryPage() {
  const forceUpdate = useForceUpdate();
  const history = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState("");
  const [joinOldRoom, setjoinOldRoom] = useState("");
  const [btnClicked, setbtnClicked] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [name, setName] = useState();
  const location = useLocation();
  useEffect(() => {
    const { joinroom } = queryString.parse(location.search);
    setjoinOldRoom(joinroom);
    Axios.get(`${process.env.REACT_APP_SERVER_URL}chat/wiv`).then((res) => {
      setRooms(res.data);
      forceUpdate();
      console.log(rooms);
    });
    getCurrentUser().then((r) => setName(r.preferred_username.split(".")[0]));
  }, []);

  function handleRoomNameintB(e) {
    setRoom(e.target.value);
    setbtnDisabled(false);
  }
  function handleRoomNameChange(roomName) {
    setRoom(roomName);
    history("/wiv/livechat", {
      state: {
        roomname: roomName,
      },
    });
  }

  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          <WivaNavBar></WivaNavBar>

          <WIVASideNavBar></WIVASideNavBar>
          <div className={styles["row"] + " row"}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              className={styles["back-div"]}
              onClick={() => {
                history("/wiv/");
              }}
            >
              <path
                d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
                fill='#4A4D4E'
              />
              <path
                d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
                fill='#4A4D4E'
              />
            </svg>

            <div className='col-md-12  p-0'>
              <div className={styles["joinInnerContainer"]}>
                <div className={styles["first-div-row"] + " row p-0 m-0"}>
                  <div
                    className={styles["chat-room-div2"] + " col-md-6 col-sm-6"}
                  >
                    <div className={styles["chat-room-img-div"]}>
                      <img
                        src='/wiv/Chatentry.svg'
                        className={styles["chat-entrance-img"]}
                      ></img>
                    </div>
                    <div>
                      <div className={styles["main-divs"] + " row"}>
                        <span className={styles["main-text-header"]}>
                          Join Our Chat Rooms
                        </span>
                        <span className={styles["main-text"]}>
                          Join Different Chat Rooms To Gain Experience With Your
                          Field{" "}
                        </span>
                      </div>
                      <div className={styles["main-btns-div"]}>
                        <div className={styles["btn-inner-div"]}>
                          <button
                            className={styles["btn"] + " btn "}
                            onClick={() => {
                              history("/wiv/createchatroom");
                            }}
                          >
                            Create New Room
                          </button>
                        </div>
                        <div className={styles["btn-inner-div"]}>
                          {rooms.length > 0 ? (
                            <div class='dropdown'>
                              <button
                                class='btn btn-secondary dropdown-toggle'
                                type='button'
                                id='dropdownMenuButton'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                                className={styles["btn"]}
                              >
                                Select Chat Room
                                <i
                                  class={styles["arrow"] + " fa fa-caret-down "}
                                ></i>
                              </button>
                              <div
                                class='dropdown-menu'
                                aria-labelledby='dropdownMenuButton'
                                style={{
                                  maxHeight: "57vh",
                                  overflowY: "scroll",
                                }}
                              >
                                <ul style={{ margin: 0 }}>
                                  {rooms.map((el) => {
                                    return (
                                      <li
                                        style={{
                                          listStyle: "disc",
                                          margin: 0,
                                          padding: 0,
                                        }}
                                        data-bs-toggle='tooltip'
                                        data-placement='top'
                                        title={el.description}
                                      >
                                        <a
                                          className={
                                            styles["chat-drop-down-item"] +
                                            " dropdown-item"
                                          }
                                          style={{ whiteSpace: "normal" }}
                                          onClick={() => {
                                            handleRoomNameChange(el.roomName);
                                          }}
                                          value={el.roomName}
                                        >
                                          {el.roomName}
                                        </a>
                                      </li>
                                    );
                                  })}{" "}
                                </ul>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className={styles["main-text-for-or-mobile-portrait"]}>
                    <hr className={styles["new4"] + " mr-2"}></hr>
                    OR
                    <hr className={styles["new4"] + " ml-2"}></hr>
                  </span>
                  <div
                    className={styles["chat-room-div2"] + " col-md-6 col-sm-6"}
                  >
                    <div className={styles["chat-room-img-div"]}>
                      <img
                        src='/wiv/MentorEntry.png'
                        className={styles["chat-entrance-img"]}
                      ></img>
                    </div>
                    <div>
                      <div className={styles["main-divs"] + " row"}>
                        <span className={styles["main-text-header"]}>
                          Sign Up
                        </span>
                        <span className={styles["main-text"]}>
                          Join your colleagues to develop your skills in your
                          field
                        </span>
                      </div>
                      <div className={styles["main-btns-div"]}>
                        <div className={styles["btn-inner-div"]}>
                          <button
                            className={styles["btn"] + " btn "}
                            onClick={() => {
                              window.open(
                                "https://vodafone.sharepoint.com/sites/VOISDevelopmentalRelationships",
                                "_blank" // <- This is what makes it open in a new window.
                              );
                            }}
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIVAChatEnryPage;
