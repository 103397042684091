import React, { useEffect, useRef, useState } from "react";
import styles from "./WivaViewMailTemplate.module.css";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import WivaNavBar from "../../WIVANavBar/WivaNavBar";
import { getCurrentUser } from "../../../services/authService";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
var BackgroundStyle = Quill.import("attributors/style/background");

Quill.register(BackgroundStyle, true);
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function WivaViewMailTemplate() {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [templateData, setTemplateData] = React.useState(
    location.state.templateData
  );
  const [templateDataBody, setTemplateDataBody] = React.useState("");
  const [uploadMembers, setuploadedMembers] = React.useState([]);
  const [subject, setSubject] = React.useState(location.state.subject);
  const [to, setSTo] = React.useState(new Date());
  const [from, setSFrom] = React.useState(new Date());
  const [id, setID] = React.useState(location.state.id);
  const [TemplateType, setTemplateType] = React.useState("system");

  const [searchText, setSearchText] = useState("");
  const [reciepients, setReciepients] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const getMailTemplate = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivatemplates/getone`, {
        id,
      })
      .then((res) => {
        // res.data.Template = res.data.Template.replace("{{code}}", "55555");
        // console.log(res.data.Template.replace("{{code}}", "55555"));
        setTemplateData(res.data.Template);
        setSubject(res.data.MailSubject);
        setTemplateType(res.data.MailType);
        setSFrom(res.data.from);
        setSTo(res.data.to);
      });
  };

  useEffect(() => {
    if (document.getElementById("bodytochange")) {
      console.log(document.getElementById("bodytochange"));
      setTemplateDataBody(
        document.getElementById("bodytochange").innerHTML.toString()
      );
      forceUpdate();
    }
  }, [templateData]);
  const EditTemplate = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivatemplates/editDefault`, {
        templateData,
        subject,
        from,
        to,
        id,
      })
      .then((res) => {
        getMailTemplate();
      });
  };
  useEffect(() => {
    getMailTemplate();
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, []);
  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html;
  };
  const sendEmail = () => {
    getCurrentUser().then((r) => {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivatemplates/sendtest`, {
          id,
          email: r.preferred_username,
        })
        .then((res) => {});
    });
  };
  const uploadImage = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      debugger;
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivatemplates/uploadimg`, {
          imageBytes: reader.result,
          configName: f.name.split(".")[0],
          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
          eventName: "wiva",
          id,
          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
        })
        .then((r) => {
          window.location.reload();
        });
    };
  };
  const sendToAll = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivatemplates/sendToAll`, {
        id,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='editModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Template
              </h5>
              <button
                type='button'
                className='close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Mail Body:</p>
                <ReactQuill
                  theme='snow'
                  value={templateDataBody}
                  onChange={(e) => {
                    let temp = convertStringToHTML(templateData);
                    temp.getElementById("bodytochange").innerHTML = e;
                    document.getElementById("bodytochange").innerHTML = e;
                    setTemplateDataBody(e);
                    var rootElement = document.getElementById("templateviewer");

                    // Convert DOM to HTML string
                    var htmlString = rootElement.outerHTML;
                    setTemplateData(htmlString);
                  }}
                  modules={modules}
                  formats={formats}
                  bounds={"#root"}
                />
              </div>
              <div className='col-md-12'>
                <p>Mail Subject:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setSubject(el.target.value);
                  }}
                  value={subject}
                ></input>
              </div>
              <div
                className='col-md-12'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p htmlFor='projects'>FROM:</p>
                {/* <p>Projects:</p> */}
                <DateTimePicker
                  className={styles["picker"]}
                  value={from ? new Date(from) : new Date()}
                  onChange={(e) => {
                    setSFrom(e);

                    forceUpdate();
                  }}
                />
              </div>
              <div
                className='col-md-12'
                style={{ display: "flex", flexDirection: "column" }}
              >
                <p htmlFor='projects'>TO: </p>
                {/* <p>Projects:</p> */}
                <DateTimePicker
                  className={styles["picker"]}
                  value={to ? new Date(to) : new Date()}
                  onChange={(e) => {
                    setSTo(e);

                    forceUpdate();
                  }}
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                disabled={templateData == "" || subject == ""}
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={EditTemplate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid p-0'>
        <WivaNavBar></WivaNavBar>
        <div className={styles["joinInnerContainer"] + " row"}>
          <div className={styles["config-table-header"] + " col-md-12"}>
            Mail Template
            <div className={styles["actions-btn-div"]}>
              <button
                className={styles["drop-down-btn"] + " btn mr-2"}
                data-bs-toggle='modal'
                data-bs-target='#wivaAdd'
                id='wivaAddbtn'
                onClick={() => {
                  document.getElementById("imageupload").click();
                }}
              >
                Upload Image
              </button>
              <input
                type='file'
                id='imageupload'
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadImage(e);
                }}
              ></input>
              <button
                className={styles["drop-down-btn"] + " btn mr-2"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  sendEmail();
                }}
              >
                Test
              </button>
              <button
                className={styles["drop-down-btn"] + " btn mr-2"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  sendToAll();
                }}
              >
                Send To All
              </button>
              <button
                className={styles["drop-down-btn"] + " btn mr-2"}
                style={{
                  cursor: "pointer",
                }}
                data-bs-toggle='modal'
                data-bs-target={"#editModal"}
              >
                {" "}
                <span
                  className='fa fa-pencil mr-2'
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  data-bs-toggle='modal'
                  data-bs-target={"#editModal"}
                ></span>
                Edit
              </button>

              {/* <button
                className={styles["drop-down-btn"] + " btn "}
                onClick={exportconfig}
              >
                Export To Excel
              </button> */}
            </div>
          </div>
          <div className={"col-md-12"}>
            <span
              style={{ wordBreak: "break-all" }}
              id='templateviewer'
              dangerouslySetInnerHTML={{ __html: templateData }}
            ></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default WivaViewMailTemplate;
