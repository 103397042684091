import axios from "axios";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import auth, { getCurrentUser } from "../../services/authService";
import styles from "./ThirdAscensionPhotoBoothEntrance.module.css";
function ThirdAscensionPhotoBoothEntrance() {
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/PhotoboothEntrance`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  let history = useNavigate();
  return (
    <div>
      <div className={styles["mentor-card-screen-background"]}>
        <div className='col-md-12 col-xs-12'>
          <div className='row'>
            <div
              className={styles["btn-back-envirtual-container"] + " col-md-12"}
              onClick={() => {
                history("/asc3/breakroom");
              }}
            >
              <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
              <label className={styles["back-btn-label"]}>Back</label>
            </div>
          </div>
          <div className='row'>
            <div className={styles["main-div"] + " col-md-6 col-xs-12"}>
              <span className={styles["header-text"]}>Photo booth</span>
              <span className={styles["description-text"] + " mt-4"}>
                Take a photo with our frame to get the spirit of connection{" "}
              </span>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className='mt-4'
              >
                <button
                  className={styles["btn-styling"]}
                  onClick={() => {
                    history("/asc3/photoboothentrance2");
                  }}
                >
                  Discover Photobooth
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdAscensionPhotoBoothEntrance;
