import React, { useEffect, useRef } from "react";
import styles from "./WIVAInput.module.css";
import ReactEmoji from "react-emoji";
import EmojiPicker from "emoji-picker-react";
import { toArray } from "react-emoji-render";
function WIVAInput({
  setMessage,
  sendMessage,
  message,
  repliedtoMsg,
  clearreply,
  messagesUsers,
  selectedEmojiList,
  setSelectedEmojiList,
}) {
  const selectEmoji = (emojiData) => {
    debugger;
    setMessage(
      document.getElementById("chat-textbox").innerHTML +
        ` <img src=${emojiData.imageUrl} class="wivaemoji-img"> `
    );

    console.log(emojiData);
  };
  const renderHTMLWithStyles = (htmlString) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  const parseEmojis = (value) => {
    const emojisArray = toArray(value);

    // toArray outputs React elements for emojis and strings for other
    const newValue = emojisArray.reduce((previous, current) => {
      if (typeof current === "string") {
        return previous + current;
      }
      return previous + current.props.children;
    }, "");

    return newValue;
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    // Move the cursor to the end of the textarea when the component mounts
    if (textareaRef.current) {
      moveCursorToEnd(textareaRef.current);
    }
  }, [message]);
  const moveCursorToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false); // Collapse the range to the end
    selection.removeAllRanges();
    selection.addRange(range);
  };
  return (
    <>
      {repliedtoMsg != null ? (
        <div className={styles["messageBox"] + " " + styles["backgroundLight"]}>
          <div
            className={styles["reply-span"]}
            data-ind={repliedtoMsg.msgindex}
          >
            <p
              className={
                styles["messageTextleft"] +
                " " +
                styles["colorDark-name"] +
                " pl-10 "
              }
            >
              {repliedtoMsg.user}
            </p>
            <p
              className={
                styles["messageTextleft"] +
                " " +
                styles["colorDark"] +
                " pl-10 "
              }
              dangerouslySetInnerHTML={{ __html: repliedtoMsg.message }}
            ></p>
          </div>
          <img
            src='/wiv/close-svgrepo-com.svg'
            className={styles["removereply"]}
            onClick={clearreply}
          ></img>
        </div>
      ) : null}{" "}
      <form className={styles["form"]}>
        <ul class='wivaautocomplete-list' id='suggestions'></ul>
        <div
          ref={textareaRef}
          contentEditable
          className={styles["input"]}
          id='chat-textbox'
          type='text'
          placeholder='Type a message...'
          value={message}
          autoComplete='false'
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              sendMessage(event);
            }
            if (event.key === "@") {
              document.getElementById("suggestions").innerHTML = "";
              document.getElementById("suggestions").style.visibility =
                "visible";
              document.getElementById("suggestions").focus();
            }
          }}
          dangerouslySetInnerHTML={{ __html: message }}
          onInput={({ target: { innerHTML } }) => {
            const mentionInput = this;
            const suggestionsDropdown = document.getElementById("suggestions");

            // Event listener for input changes
            const inputValue = innerHTML;

            // Clear previous suggestions
            suggestionsDropdown.innerHTML = "";

            // Find all mentions in the input text
            const mentionMatches = inputValue.match(/@(\w+)/g);

            if (mentionMatches) {
              mentionMatches.forEach((mention) => {
                const searchTerm = mention.slice(1); // Remove "@" from the mention
                const filteredUsers = messagesUsers.filter((user) =>
                  user.toLowerCase().includes(searchTerm.toLowerCase())
                );

                // Populate the suggestions dropdown for each mention
                filteredUsers.forEach((user) => {
                  const suggestionItem = document.createElement("div");
                  suggestionItem.classList.add("wivasuggestion-item");
                  suggestionItem.textContent = `${user}`;
                  suggestionItem.addEventListener("click", function () {
                    // Replace the mention with the selected mention
                    const updatedValue = inputValue.replace(
                      mention,
                      `@${user.replace(" ", "-")} `
                    );
                    setMessage(updatedValue);
                    // Clear the suggestions dropdown
                    suggestionsDropdown.innerHTML = "";
                    suggestionsDropdown.style.visibility = "hidden";
                    document.getElementById("chat-textbox").focus();
                  });
                  suggestionsDropdown.appendChild(suggestionItem);
                });
              });
            } else {
              messagesUsers.forEach((user) => {
                // Populate the suggestions dropdown for each mention

                const suggestionItem = document.createElement("div");
                suggestionItem.classList.add("wivasuggestion-item");
                suggestionItem.textContent = `${user}`;
                suggestionItem.addEventListener("click", function () {
                  // Replace the mention with the selected mention
                  const updatedValue = inputValue.replace(
                    inputValue[inputValue.length - 1],
                    `@${user.replace(" ", "-")} `
                  );
                  setMessage(updatedValue);
                  // Clear the suggestions dropdown
                  suggestionsDropdown.innerHTML = "";
                  suggestionsDropdown.style.visibility = "hidden";
                  document.getElementById("chat-textbox").focus();
                });
                suggestionsDropdown.appendChild(suggestionItem);
              });
            }

            setMessage(innerHTML.replace("&lt;", "<").replace("&gt;", "/>"));
          }}
        ></div>
        <div
          id='emojidiv'
          style={{
            display: "none",
            position: "absolute",
            right: "10%",
            bottom: "10%",
            zIndex: 100000,
          }}
        >
          <EmojiPicker onEmojiClick={selectEmoji} />
        </div>
        <button
          type='button'
          className={styles["emojis"]}
          onClick={() => {
            if (document.getElementById("emojidiv").style.display == "none")
              document.getElementById("emojidiv").style.display = "block";
            else {
              document.getElementById("emojidiv").style.display = "none";
            }
          }}
        >
          <img
            src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f603.png'
            className={styles["emoji-img"]}
          ></img>
        </button>
        <button
          className={styles["send-btn"] + " btn"}
          onClick={(e) => {
            sendMessage(e);
          }}
        >
          &#x2191;
        </button>
      </form>
    </>
  );
}

export default WIVAInput;
