import React from "react";
import styles from "./WIVAInfoBar.module.css";
function WIVAInfoBar({ room, desc }) {
  return (
    <div className={styles["infoBar"]}>
      <div className={styles["leftInnerContainer"]}>
        <span className={styles["header"]}>{room}</span>
        <span className={styles["sub-header"]}>{desc}</span>
      </div>
    </div>
  );
}

export default WIVAInfoBar;
