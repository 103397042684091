import React from "react";

import ScrollToBottom from "react-scroll-to-bottom";

import WIVAMessage from "./WIVAMessage/WIVAMessage";

import styles from "./WIVAMessages.module.css";
function WIVAMessages({
  messages,
  name,
  users,
  onmessageReply,
  setMessageReaction,
}) {
  return (
    <ScrollToBottom className={styles["messages"]}>
      {/* {console.log("messages", messages)} */}
      {messages.map((message, i) => {
        return (
          <div key={i}>
            <WIVAMessage
              message={message}
              name={name}
              users={users}
              onmessageReply={onmessageReply}
              msgindex={i}
              addEmoji={setMessageReaction}
            />
          </div>
        );
      })}
    </ScrollToBottom>
  );
}

export default WIVAMessages;
