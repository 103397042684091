import React, { useEffect } from "react";
import styles from "./WivaNavBar.module.css";
import { getCurrentUser } from "../../services/authService";
import axios from "axios";
import $ from "jquery";
function WivaNavBar() {
  const [isTab, setIsTab] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [surveyValue, setSurveyValue] = React.useState(0);
  useEffect(() => {
    getCurrentUser().then((r) => {
      setUser(r["preferred_username"].split(".")[0]);
    });

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivaconfig/getconfig`, {
        configName: "nps survey",
      })
      .then((x) => {
        debugger;
        if (x.data.islive == true) {
          localStorage.removeItem("nps-dismissed");
        } else {
          localStorage.setItem("nps-dismissed", "true");
        }
      });
    if ($("#iframe")) {
      $("#iframe").focus();
    }

    if (!window.opener) setIsTab(true)

  }, []);
  useEffect(() => {
    var boxes = document.querySelectorAll(".survey-value");

    // Attach event listener to each div
    boxes.forEach(function (box) {
      box.addEventListener("click", function () {
        debugger;

        // Deselect all boxes
        boxes.forEach(function (otherBox) {
          otherBox.className = styles["survey-value"];
        });
        this.className = styles["survey-value-selected"];
        setSurveyValue(Number(this.innerText));
        // Select all boxes except the clicked one
      });
    });
  }, [localStorage.getItem("nps-dismissed")]);
  const submitSurvey = () => {
    getCurrentUser().then((r) => {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivanps/submitsurvey`, {
          email: r.preferred_username,
          value: surveyValue,
        })
        .then((x) => {
          localStorage.setItem("nps-dismissed", "true");
        });
    });
  };
  return (
    <>
      <nav
        class='navbar navbar-expand-lg navbar-light bg-light'
        className={styles["navbar-wiv"]}
      >
        <a class='navbar-brand' href='/wiv'>
          <span className={styles["nav-bar-img"]}>
            Women in _VO<span className={styles["nav-bar-img-bold"]}>IS</span>{" "}
            Arena (WIVA)
          </span>
        </a>
        <span className={styles["nav-bar-hello"]}>Welcome {user}!</span>
      </nav>
      {window.location.href.endsWith("/wiv/#") ||
      window.location.href.endsWith("/wiv/") ||
      window.location.href.endsWith("/wiv") ||
      window.location.href.endsWith("/wiv#") ||
      window.location.href.endsWith("/wiv/livechat") ||
      window.location.href.endsWith("/wiv/home/#") ||
      window.location.href.endsWith("/wiv/home/") ||
      window.location.href.endsWith("/wiv/home") ||
      window.location.href.endsWith("/wiv/home#") ? (
        window.location.href.endsWith("/wiv/#") ||
        window.location.href.endsWith("/wiv/") ||
        window.location.href.endsWith("/wiv") ||
        window.location.href.endsWith("/wiv#") ? (
          <div className={styles["joinroom"]}>
            <img
              src='/wiv/joinRoomBG.png'
              className={styles["joinroom-img"]}
            ></img>
            <div className={styles["joinroom-text-div"]}>
              <span className={styles["joinroom-text"]}>
                Chat and interact with WIV using avatars, and experience
                networking like never before! Hurry! Capacity is limited.
                <button
                  className={styles["joinroom-btn"]}
                  onClick={() => {
                    window.location.href = "/wiv/home";
                  }}
                >
                  JOIN NOW
                </button>
              </span>
            </div>
          </div>
        ) : null
      ) : (
        !isTab && <button
          className={
            styles["drop-down-btn"]
          }
          onClick={() => {
            window.close();
          }}
        >
          Close Window
        </button>
      )}
      
      {window.location.href.endsWith("/wiv/#") ||
      window.location.href.endsWith("/wiv/") ||
      window.location.href.endsWith("/wiv") ||
      window.location.href.endsWith("/wiv#") ? (
        localStorage.getItem("nps-dismissed") == "false" ||
        localStorage.getItem("nps-dismissed") == null ||
        localStorage.getItem("nps-dismissed") == undefined ? (
          <div className={styles["nps-survey"]}>
            <span className={styles["nps-suevey-header"]}>
              <span className={styles["header-text"]}>
                Your feedback is very important to WIVA!
              </span>
              <span
                className={styles["skip-for-now"]}
                onClick={() => {
                  localStorage.setItem("nps-dismissed", "true");
                  if ($("#iframe")) {
                    $("#iframe").focus();
                  }
                }}
              >
                Skip for now
              </span>
            </span>
            <span className={styles["nps-survey-body"]}>
              <span className={styles["nps-survey-body-text"]}>
                How likely are you to recommend WIVA to your colleagues?
              </span>
              <div className={styles["survey-value-div"]}>
                <div className={styles["survey-value"] + " survey-value"}>
                  1
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  2
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  3
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  4
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  5
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  6
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  7
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  8
                </div>
                <div className={styles["survey-value"] + " survey-value"}>
                  9
                </div>
                <div
                  className={
                    styles["survey-value"] +
                    " " +
                    styles["survey-value-last"] +
                    " survey-value"
                  }
                >
                  10
                </div>
              </div>
              <div className={styles["survey-desc"]}>
                <span className={styles["survey-desc-text"]}>
                  Not At All Likely
                </span>
                <span className={styles["survey-desc-text"]}>
                  Extremely Likely
                </span>
              </div>
              <span
                className={styles["survey-submit-button"]}
                onClick={submitSurvey}
              >
                Submit
              </span>
            </span>{" "}
          </div>
        ) : null
      ) : null}{" "}
    </>
  );
}

export default WivaNavBar;
