import React, { useEffect } from "react";

function Hall() {
  useEffect(() => {
    document.addEventListener("keydown", function (e) {
      let position =
        document.querySelector("a-scene").camera.el.object3D.position;
      if (position.z >= -16) {
        if (e.which == 38) {
          //up key code
          window
            .$("#model2")
            .attr(
              "rotation",
              `${window.$("#model2").attr("rotation").x}  ${
                window.$("#model2").attr("rotation").y - 1
              } ${window.$("#model2").attr("rotation").z}`
            );
        } else if (e.which == 40) {
          //up key code
          window
            .$("#model2")
            .attr(
              "rotation",
              `${window.$("#model2").attr("rotation").x}  ${
                window.$("#model2").attr("rotation").y + 1
              } ${window.$("#model2").attr("rotation").z}`
            );
        }
      } else if (position.z < -16 && position.z >= -25) {
        if (e.which == 38) {
          //up key code
          window
            .$("#model3")
            .attr(
              "rotation",
              `${window.$("#model3").attr("rotation").x}  ${
                window.$("#model3").attr("rotation").y + 1
              } ${window.$("#model3").attr("rotation").z}`
            );
        } else if (e.which == 40) {
          //up key code
          window
            .$("#model3")
            .attr(
              "rotation",
              `${window.$("#model3").attr("rotation").x}  ${
                window.$("#model3").attr("rotation").y - 1
              } ${window.$("#model3").attr("rotation").z}`
            );
        }
      } else if (position.z < -25 && position.z >= -40) {
        if (e.which == 38) {
          //up key code
          window
            .$("#model4")
            .attr(
              "rotation",
              `${window.$("#model4").attr("rotation").x}  ${
                window.$("#model4").attr("rotation").y - 1
              } ${window.$("#model4").attr("rotation").z}`
            );
        } else if (e.which == 40) {
          //up key code
          window
            .$("#model4")
            .attr(
              "rotation",
              `${window.$("#model4").attr("rotation").x}  ${
                window.$("#model4").attr("rotation").y + 1
              } ${window.$("#model4").attr("rotation").z}`
            );
        }
      }
    });
  }, []);
  return (
    <a-scene
      renderer='antialias: true; colorManagement: true;
        sortObjects: true; '
      cursor='rayOrigin: mouse'
      loading-screen='dotsColor: white; backgroundColor: darkred'
      fog='type: linear;color: #FAF2DF;near:0.1;far:25'
    >
      <a-assets>
        <img id='sky' src='/assets/hackathon/sky.jpg' />
      </a-assets>
      <a-sky src='#sky'></a-sky>
      <a-entity
        class='listenonkey'
        id='rig'
        movement-controls='controls: touch;speed:0.8'
        position='150.879 2 18'
      >
        <a-camera
          look-controls='enabled:false'
          id='camera'
          wasd-controls='acceleration: 250'
          position='0 0 0'
          twoway-motion='speed: 35;nonMobileLoad:false'
          camera-motion
        >
          {" "}
        </a-camera>
      </a-entity>
      <a-entity light='type: ambient;intensity:0.8'></a-entity>
      <a-entity
        light='type:directional; 
                   shadowCameraVisible: false; '
        position='1 100 200'
      ></a-entity>

      {/* <a-entity
        id='model'
        visible={false}
        position='-58 0.60 -10.5'
        rotation='0 90 0'
        scale='1.5 1.5 1.5'
        gltf-model='url(/assets/thirdAscension/hall1/Environment4KShadless.glb)'
        animation-mixer='clip:*;loop: infinity;'
      ></a-entity> */}
      <a-entity
        id='model1'
        scale='20 20 100'
        position='0 0 1000'
        gltf-model='url(/assets/hackathon/floor6.glb)'
        animation-mixer='clip:*;loop: infinity;'
      ></a-entity>

      <a-entity position='155 2 0z'>
        <a-entity
          id='model2'
          lerp='properties: position, rotation, scale'
          //   position='-400.879 15 -18.759'
          rotation='0 -95 0'
          //   scale='3 3 3'
          gltf-model='url(/assets/hackathon/Card01.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
      </a-entity>
      <a-entity position='145 2 -10'>
        <a-entity
          id='model3'
          lerp='properties: position, rotation, scale'
          //   position='-400.879 15 -18.759'
          rotation='0 -50 0'
          //   scale='3 3 3'
          gltf-model='url(/assets/hackathon/Card01.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
      </a-entity>
      <a-entity position='155 2 -20'>
        <a-entity
          id='model4'
          lerp='properties: position, rotation, scale'
          //   position='-400.879 15 -18.759'
          rotation='0 -95 0'
          //   scale='3 3 3'
          gltf-model='url(/assets/hackathon/Card01.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
      </a-entity>
    </a-scene>
  );
}

export default Hall;
