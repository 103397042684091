import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AscensionDayGame.module.css";
function AscensionDayGame() {
  const [played, setPlayed] = React.useState(false);
  const [leaderBoard, setLeaderBoard] = React.useState([
    { name: "shehab harhash", count: 9, time: 3.5 },
    { name: "shehab harhash", count: 9, time: 3.5 },
    { name: "shehab harhash", count: 9, time: 3.5 },
    { name: "shehab harhash", count: 9, time: 3.5 },
    { name: "shehab harhash", count: 9, time: 3.5 },
  ]);
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/Game`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
  }, []);
  let history = useNavigate();
  return (
    <div>
      <div className={styles["mentor-card-screen-background"]}>
        <div className='col-md-12 col-xs-12'>
          <div className='row'>
            <div
              className={styles["btn-back-envirtual-container"] + " col-md-12"}
              onClick={() => {
                history("/asc3/gameentrance");
              }}
            >
              <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
              <label className={styles["back-btn-label"]}>Back</label>
            </div>
          </div>
          <div className='row'>
            {played == false ? (
              <div className={" col-md-12 col-xs-12"}>
                <span className={styles["title"]}>Game Instructions</span>
                <div className={styles["main-div"]}>
                  <div className={styles["instructions-div"]}>
                    <label className={styles["instruction-label"]}>
                      1- It’s time to test your Knowledge,This game Consists of
                      10 general questions about <br />
                      IT Platforms & Automation GSL.
                    </label>
                    <label className={styles["instruction-label"]}>
                      2- Each question will be worth of 1 point, All questions
                      are set to a <br />5 mins range to be answered.
                    </label>
                    <div className={styles["btn-styling-div"]}>
                      <button
                        className={styles["btn-styling"]}
                        onClick={() => {
                          history("/asc3/startGame");
                        }}
                      >
                        Start Game
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={" col-md-12 col-xs-12"}>
                <span className={styles["title-leader-board"]}>
                  leader board
                </span>
                <span className={styles["title-leader-board-div"]}>
                  <img src='/assets/thirdAscension/SketchLogo.svg' />
                  <span className={styles["title2-leader-board"]}>Top 5</span>
                </span>
                <div className={styles["main-div-leader-board"]}>
                  <div className={styles["instructions-div-leaderBoard"]}>
                    {leaderBoard.map((e, i) => (
                      <span className={styles["leader-main-item"]}>
                        <div className={styles["leader-name-div"]}>
                          <label className={styles["leader-item-number"]}>
                            #{i + 1}
                          </label>
                          <label className={styles["leader-item-name"]}>
                            {e.name}
                          </label>
                        </div>
                        <div className={styles["leader-qt"]}>
                          <div className={styles["leader-t"]}>
                            <img
                              src='/assets/thirdAscension/Question.svg'
                              className={styles["img-leader"]}
                            ></img>
                            <label className={styles["leader-item-name"]}>
                              {e.count}/10
                            </label>
                          </div>
                          <div className={styles["leader-t"]}>
                            <img
                              src='/assets/thirdAscension/Timer (1).svg'
                              className={styles["img-leader"]}
                            ></img>
                            <label className={styles["leader-item-name"]}>
                              {e.time}
                            </label>
                          </div>
                        </div>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AscensionDayGame;
