import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import auth, { getCurrentUser } from "../../services/authService";
import styles from "./EntranceVideo.module.css";
function ThirdAscensionEntranceVideo() {
  const history = useNavigate();
  const [muted, setMuted] = useState(true);
  useEffect(() => {
    localStorage.setItem("firstTimeWithLoading", "true");

    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/Entrance`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });

    localStorage.setItem("firstLogin", "true");
  }, []);
  const handleMuted = () => {
    if (muted === true) {
      setMuted(false);
    } else {
      setMuted(true);
    }
  };
  return (
    <>
      <img
        src='/assets/glb/newmodel/s12-01.png'
        className={styles["toggle-asc-sound"]}
        style={{ height: "3rem" }}
        onClick={handleMuted}
      />

      <img
        src='/assets/glb/newmodel/ff1-01.png'
        style={{ height: "3rem" }}
        className={styles["toggle-asc-Next"]}
        href='#'
        onClick={() => {
          window.location.href = "/asc3/hall";
        }}
      />

      <ReactPlayer
        id='asc3-video'
        playing={true}
        url='/assets/thirdAscension/opening.mp4'
        autoPlay={true}
        muted={muted}
        width='100%'
        height='100vh'
        onReady={() => {
          document.getElementById("asc3-video").firstChild.style.objectFit =
            "fill";
          document.getElementById("asc3-video").firstChild.style.height =
            "100vh";
          document.getElementById("asc3-video").firstChild.style.overFlow =
            "hidden";
          document.getElementById("asc3-video").firstChild.style.overFlowX =
            "hidden";
        }}
        onEnded={() => {
          window.location.href = "/asc3/hall";
        }}
      />
    </>
  );
}

export default ThirdAscensionEntranceVideo;
