import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UserCard from "./userCard";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";

function UserManagementProfiles() {
  const [users, setUsers] = useState({});
  const [projects, setProjects] = useState();
  const [event, setEvent] = useState();
  const [email, setEmail] = useState();
  const [numberOfElements, setNumberOfElements] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [filtered, setFiltered] = useState(false);
  const [currentEventFiltered, setcurrentEventFiltered] = useState(false);
  let history = useNavigate();
  let location = useLocation();

  // const groupBy = (array, key) => {
  //   // Return the end result
  //   return array.reduce((result, currentValue) => {
  //     // If an array already present for key, push it to the array. Else create an array and push the object
  //     (result[currentValue[key]] = result[currentValue[key]] || []).push(
  //       currentValue
  //     );

  //     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
  //     return result;
  //   }, {}); // empty object is the initial value for result object
  // };
  const searchEvents = async () => {
    setFiltered(true);
    setActivePage(1);
    //
    if (localStorage.getItem("filtredProject")) {
      setcurrentEventFiltered(true);
      console.log(currentEventFiltered);
    }
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}participant/findParticipant`, {
        params: { email, name: event, page: activePage, filtered: true },
      })
      .then((res) => {
        // setUsers(groupBy(res.data.participants, "email"));
        setUsers(res.data.participants);
        setNumberOfElements(res.data.numberOfElements);
        setPageLimit(res.data.limit);
      });
  };
  useEffect(() => {
    if (location?.state?.project?.name) {
      localStorage.setItem("filtredProject", location?.state?.project?.name);
      setEvent(location?.state?.project?.name);
      setFiltered(true);
      setActivePage(1);
    }
    if (filtered) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}participant/findParticipant`, {
          params: { email, name: event, page: activePage, filtered: true },
        })
        .then((res) => {
          // setUsers(groupBy(res.data.participants, "email"));
          setUsers(res.data.participants);
          setNumberOfElements(res.data.numberOfElements);
          setPageLimit(res.data.limit);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}participant?page=${activePage}`
        )
        .then((res) => {
          // setUsers(groupBy(res.data.participants, "email"));
          setUsers(res.data.participants);
          setNumberOfElements(res.data.numberOfElements);
          setPageLimit(res.data.limit);
        });
    }

    axios.get(`${process.env.REACT_APP_SERVER_URL}projects`).then((res) => {
      setProjects(res.data);
    });
  }, [activePage, filtered, currentEventFiltered]);
  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber);
    history({
      pathname: `${location.pathname}`,
      search: `?page=${pageNumber}`,
    });
  };

  return (
    <div>
      <img
        src='/assets/_VOIS_Hackathon_keyart2.jpg'
        className='breakroom-listing-screen-background'
        alt=''
      />
      <div className='breakRoom-container-fluid'>
        <div className=' pl-5 nav-button-padding'>
          <img
            src='/assets/background.png'
            className='mentor-card-screen-background'
            alt=''
          />
          <img
            style={{ position: "absolute", top: "0.25rem", left: "0.5rem" }}
            className=' mb-2'
            alt='login-background'
            src='/assets/ic-logo-navbar.png'
          ></img>
          <button
            type='button'
            className='btn btn-light mb-2 '
            style={{ position: "absolute", right: 15, top: 5 }}
            to='/user/project'
            onClick={() => {
              localStorage.removeItem("filtredProject");
              history("/user/project");
            }}
          >
            Back to Projects
          </button>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12 text-center'>
            <h2 className='mentors-header-page-title'>
              Welcome To User Manager
            </h2>
            <h6 className='mentors-header-page-title'>
              You Can Link a user with any of events.
            </h6>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            style={{
              width: "30%",
              position: "absolute",
              marginLeft: "20%",
              marginTop: "2%",
            }}
            type='text'
            className='form-control mb-4'
            name='search'
            id='search-bar'
            placeholder='email'
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <DropdownButton
            alignRight
            hidden={localStorage.getItem("filtredProject")}
            title={
              event
                ? event.length > 10
                  ? event.substring(0, 9) + "..."
                  : event
                : "select event"
            }
            id='dropdown-menu-align-right'
            style={{
              width: "30%",
              position: "absolute",
              marginLeft: "52%",
              marginTop: "2%",
            }}
            onSelect={(e) => {
              setEvent(e);
            }}
          >
            {projects?.map((item) => {
              return (
                <Dropdown.Item eventKey={item.name}>{item.name}</Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Button
            onClick={searchEvents}
            style={{ position: "absolute", marginLeft: "61%", marginTop: "2%" }}
          >
            Search
          </Button>
        </div>

        <div className='row cards-flip-container mt-5'>
          {Object.keys(users)?.map((r) => {
            users[r] = [
              ...new Map(users[r].map((item) => [item.role, item])).values(),
            ];
            return <UserCard email={r} user={users[r]}></UserCard>;
          })}
        </div>
        <div className='row justify-content-center'>
          {numberOfElements ? (
            <Pagination
              itemClass='page-item'
              linkClass='page-link'
              activePage={activePage}
              itemsCountPerPage={pageLimit}
              totalItemsCount={numberOfElements}
              onChange={handlePagination}
            />
          ) : null}
        </div>
      </div>
      {numberOfElements == 0 && (
        <>
          <p class='text-light h2 col-md-6  text-center'>no user found</p>
        </>
      )}
    </div>
  );
}

export default UserManagementProfiles;
