import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/esm/Page/TextLayer.css"; // Import required styles
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from "../../Catalogues.module.css";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;
function RTCCatalogue() {
  const history = useNavigate();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfFile] = useState("/assets/showroom/oit/OIT-RTC.pdf");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setPage((prevPage) =>
      numPages ? Math.min(prevPage + 1, numPages) : prevPage
    );
  };

  return (
    <div className={styles["catalogue-bg"] + " container-fluid"}>
      <div className='row'>
        <div
          className={styles["back-btn"]}
          onClick={() => history("/oit/rtchall")}
        >
          <img src='/assets/back-btn.svg' alt='Back' />
          <label>Back</label>
        </div>

        <div className={styles["main-div"] + " col-md-12"}>
          <div className='magazine'>
            <button
              className='btn btn-outline-light m-5'
              disabled={page === 1}
              onClick={handlePrevious}
            >
              Previous
            </button>
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<span>Loading...</span>}
            >
              <Page pageNumber={page} />
            </Document>
            <button
              className={
                styles["btn-next"] + " btn btn-outline-light m-5 pr-4 pl-4 "
              }
              disabled={page === numPages}
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RTCCatalogue;
