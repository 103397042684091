import React, { useEffect, useState } from "react";

import Axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4animated from "@amcharts/amcharts4/themes/animated";
import styles from "./statsChart.module.css";
import * as XLSX from "xlsx";
function StatsChart(props) {
  const [data, setData] = useState();
  const clearDB = () => {
    Axios.post(`${process.env.REACT_APP_SERVER_URL}stats/clearpage`, {
      id: props.parentprops.state.project._id,
    }).then((res) => {
      window.location.reload(true);
    });
  };
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    Axios.get(`${process.env.REACT_APP_SERVER_URL}wivavisits/`, {}).then(
      (res) => {
        console.log(res.data);
        setData(res.data);

        chart.paddingRight = 20;
        chart.data = res.data.map((el) => {
          return {
            country: el._id,
            visits: el.count,
          };
        });

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.minHeight = 110;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = "visits";
        series.dataFields.categoryX = "country";
        series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
        series.columns.template.strokeWidth = 0;

        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.fillOpacity = 0.8;

        // on hover, make corner radiuses bigger
        var hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        series.columns.template.adapter.add("fill", function (fill, target) {
          return chart.colors.getIndex(target.dataItem.index);
        });

        // Cursor
        chart.cursor = new am4charts.XYCursor();

        chart = chart;
      }
    );
  }, []);
  const ExportChart = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(
      data.map((el) => {
        return { date: el._id, count: el.count };
      })
    );

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, "visits-count-Stats" + new Date() + ".xlsx");
  };
  return (
    <>
      <div className={styles["chart-card"] + " col-md-5"}>
        <span className={styles["chart-card-header"]}>Visits</span>
        <div id='chartdiv' className={styles["chart-size"]}></div>
        <button
          className={styles["drop-down-btn"] + " btn "}
          onClick={() => {
            ExportChart();
          }}
        >
          Export Data
        </button>
      </div>
    </>
  );
}

export default StatsChart;
