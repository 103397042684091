import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../services/authService";

function SEAHall() {
  const [backDrop, setbackDrop] = useState(true);
  const [videoSrc, setvideoSrc] = useState("#video1");
  function playVideos() {
    var videos = document.querySelectorAll("video");
    for (let index = 0; index < videos.length; index++) {
      const element = videos[index];
      if (element)
        if (element.paused === true) {
          console.log("paused");
          element.play();
        } else {
          element.pause();
          console.log("play");
        }
    }
  }
  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}stats/setpage/SEAHall`, {
              id: res.data._id,
            })
            .then((res) => {});
        }
      });
  }, []);
  return (
    <>
      {" "}
      {backDrop ? (
        <div
          id='loader-wrapper'
          className='clickEffect'
          style={{ overflowY: "scroll" }}
        >
          <div id='loader'></div>
          <div className='row'>
            <div
              className='loader-section section-left col-md-12'
              style={{ overflowY: "auto" }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2%",
                }}
              >
                <h1 className='section-text' style={{ color: "white" }}>
                  {" "}
                  Welcome to _VO
                  <span className='summit-intro-bold pr-3'>IS</span>
                  ShowRoom
                </h1>
                <h4 className='section-text'>
                  {" "}
                  Click and drag your mouse right, left, up, and down to look
                  around the ShowRoom.​
                </h4>

                <img
                  src='/assets/clicks.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <h4 className='section-text mt-2'>
                  {" "}
                  Navigate around the ShowRoom using the keyboard arrows or WASD
                  keys.
                </h4>
                <div className='row '>
                  <div
                    className='col-md-6'
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/wasd.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                  <div
                    className='col-md-6 '
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src='/assets/arrows.png'
                      style={{
                        zIndex: 9999,
                        //   position: "absolute",
                        height: "7.5rem",
                      }}
                    ></img>
                  </div>
                </div>
                <h4 className='section-text mt-2'>
                  {" "}
                  Click on the red dot on the ShowRoom area to enter it.
                </h4>
                <img
                  src='/assets/redDot.png'
                  style={{
                    zIndex: 9999,
                    //   position: "absolute",
                    height: "7.5rem",
                  }}
                ></img>
                <button
                  className='btn btn-outline-light'
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // var audio = document.getElementById("audio");
                    // audio.muted = false;
                    setbackDrop(false);
                    localStorage.setItem("firstLogin", "true");
                    localStorage.setItem("firstTimeWithLoading", "false");
                    localStorage.setItem("secondTimeWithLoading", "true");
                    playVideos();
                    // var player = window.videojs("#example-video");
                    // player.play();
                  }}
                >
                  {" "}
                  OK​
                </button>
              </div>{" "}
              {/* <img
                src='/assets/n1560902.gif'
                style={{
                  zIndex: 9999,
                  position: "absolute",
                  right: "5rem",
                  height: "5rem",
                }}
              ></img> */}
            </div>
          </div>{" "}
        </div>
      ) : null}
      <a-scene
        deallocate
        renderer='gammaOutput: true;antialias: true; colorManagement: true;
          sortObjects: true; '
        cursor='rayOrigin: mouse'
        loading-screen='dotsColor: white; backgroundColor: darkred'
        seahall
      >
        <a-assets>
          <video
            id='video1'
            autoPlay
            loop='loop'
            src='/assets/showroom/general/video1.mp4'
            muted
            preload='auto'
          ></video>
          <video
            id='video2'
            autoPlay
            loop='loop'
            src='/assets/showroom/general/video2.mp4'
            muted
            preload='auto'
          ></video>
        </a-assets>
        <a-entity
          class='listenonkey'
          id='rig'
          movement-controls='controls: touch;speed:0.5'
          position='0 8 20'
          rotation='0 -90 0'
        >
          <a-camera
            id='camera'
            wasd-controls='acceleration: 300'
            position='0 0 0'
            twoway-motion='speed: 35;nonMobileLoad:false'
          ></a-camera>
        </a-entity>
        <a-light
          light='type: spot; angle: 180;'
          color='white'
          position='0 0 0'
          intensity='0.5'
        ></a-light>
        <a-entity
          light='type:directional; 
                   shadowCameraVisible: false; '
          position='1 35 1'
        ></a-entity>
        <a-entity
          id='model'
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/walls-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model2'
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/plants-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model3'
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/Names_007.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model4 '
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/lcd-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model5'
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/exit-processed.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='model6'
          position='0 0 0'
          gltf-model='url(/assets/showroom/sea/desk.glb)'
          animation-mixer='clip:*;loop: infinity;'
          shadow='receive: true'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='25 8 60'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/testing'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='1 8 60'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/dsm'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-25 8 60'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/devops'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-24 8 -27'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/digitaltooling'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-8 8 -27'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/coe'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='9 8 -27'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/pmo'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='25.5 8 -27'
          rotation='0 0 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/productivity'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-50 8 41'
          rotation='0 90 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/bpm'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-50 8 -10.5'
          rotation='0 90 0'
          scale='8.5 15 0'
          class='intersectable'
          cursor-url='url:/showroom/rpa'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity
          id='texttest'
          geometry='primitive: box'
          material='transparency:true;opacity:0.0'
          //material='color:white'
          position='-50 8 15'
          rotation='0 90 0'
          scale='16 15 0'
          class='intersectable'
          cursor-url='url:/showroom'
          // light='type: point; intensity: 2.0'
        ></a-entity>
        <a-entity id='Agenda1' position='56 13.5 -23.5' rotation='0 -37 0'>
          <a-video
            width='12'
            height='6.5'
            src={videoSrc}
            position='0 0.663 0'
          ></a-video>
        </a-entity>
        <a-entity id='Agenda2' position='56.33 14 55.216' rotation='0 37 0'>
          <a-video width='9' height='6.5' src='#video2'></a-video>
        </a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='59 22 1.43'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='59 22 15'
          rotation='-90 0 -90'
        ></a-entity>
        <a-entity
          light='type: spot; angle: 45;decay:0.8;distance:15'
          position='59 22 31'
          rotation='-90 0 -90'
        ></a-entity>

        {/* <a-entity id='Agenda2' position='90 75 300'>
        <a-video width='100' height='60' src='#video2'></a-video>
      </a-entity> */}
      </a-scene>
    </>
  );
}

export default SEAHall;
