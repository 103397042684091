import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import "./addProject.css";
import * as XLSX from "xlsx";
import Axios from "axios";
function AddProject() {
  let data = {
    project: { Name: "", path: "" },
    emails: [],
  };

  const projectName = useRef("");
  const history = useNavigate();
  let stepper;
  const textboxDiv = useRef();
  useEffect(() => {
    stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
  }, []);

  const onSubmit = () => {
    console.log(process.env.REACT_APP_SERVER_URL);
    Axios.post(`${process.env.REACT_APP_SERVER_URL}projects`, data).then(
      (data) => history("/project")
    );
  };
  let counter;
  const addTextBox = (value) => {
    let elem = document.querySelector("#textBoxes");
    console.log(data);
    if (elem.firstChild !== null) {
      counter = parseInt(elem.firstChild.id) + 1;
    } else {
      counter = 0;
    }
    var inp = document.createElement("input");
    inp.type = "email";
    inp.className = "form-control col-md-5 col-xs-12 mt-2 mb-2 mr-2 ml-4";
    inp.id = counter;
    var remove = document.createElement("label");
    remove.innerHTML = "x";
    remove.className = counter;
    remove.onclick = removeValue.bind(counter);

    if (typeof value == "string") {
      remove.setAttribute("data-email", value);
      inp.value = value;
      data.emails.push(value);
      inp.onload = handleChange.bind(this);
    }
    elem.prepend(remove);
    elem.prepend(inp);

    inp.onchange = handleChange.bind(this);
  };

  const handleChange = (e) => {
    var change = data.emails;

    if (parseInt(e.target.id) <= change.length) {
      change[parseInt(e.target.id)] = e.target.value.toLowerCase();
    } else {
      change.push(e.target.value.toLowerCase());
    }
    data = { project: data.project, emails: change };
  };
  function changeProjectName(e) {
    let emails = data.emails;

    data = {
      project: { Name: e.target.value },
      emails: emails,
    };
  }
  function changeExternalLink(e) {
    let emails = data.emails;

    data = {
      project: { Name: data.project.Name, path: e.target.value },
      emails: emails,
    };
    console.log(data);
  }

  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        addTextBox(element[0]);
      }
    };
    reader.readAsBinaryString(f);
  };
  function removeValue(e) {
    let email = e.target.getAttribute("data-email");
    let elem = document.getElementById(e.target.className);
    elem.parentElement.removeChild(e.target);
    elem.parentElement.removeChild(elem);
    let i = data.emails.indexOf(email);
    console.log(e.target.className);
    if (i > -1) {
      let deleted = data.emails.splice(i, 1);
      console.log(deleted);
    }
  }
  return (
    <div className='maindiv row'>
      <img
        src='/assets/background.png'
        className='mentor-card-screen-background'
        alt=''
      />

      <Link
        type='button'
        className='btn btn-light mb-2 '
        style={{ position: "fixed", right: 15, top: 5 }}
        to='/project'
      >
        Back to Projects
      </Link>
      <div className='col-md-3'></div>
      <div className='col-md-6 col-xs-6 mt-5' style={{ position: "relative" }}>
        <div id='stepper1' className='bs-stepper'>
          <div className='bs-stepper-header'>
            <div className='step' data-bs-target='#test-l-1'>
              <button className='step-trigger'>
                <span className='bs-stepper-circle'>1</span>
                <span className='bs-stepper-label'>Project Details</span>
              </button>
            </div>
            <div className='line'></div>
            <div className='step' data-bs-target='#test-l-2'>
              <button className='step-trigger'>
                <span className='bs-stepper-circle'>2</span>
                <span className='bs-stepper-label'>Project Participants</span>
              </button>
            </div>
          </div>
          <div className='bs-stepper-content'>
            <div id='test-l-1' className='content'>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1'>Project Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputEmail1'
                  onChange={changeProjectName}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1'>External Link</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputEmail1'
                  onChange={changeExternalLink}
                />
              </div>

              <button
                type='button'
                className='btn btn-light float-right'
                onClick={() => stepper.next()}
              >
                Next
              </button>
            </div>
            <div id='test-l-2' className='content'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h4>Add Project Emails</h4>
                <button
                  className='btn btn-light text-center'
                  style={{ borderRadius: "50px" }}
                  onClick={addTextBox}
                >
                  +
                </button>
                <input
                  type='file'
                  className='btn btn-light text-center mb-2'
                  onChange={handleUpload}
                ></input>
              </div>
              <div className='row' id='textBoxes'></div>

              <button
                type='submit'
                className='btn btn-light mr-3 ml-3 float-right'
                onClick={onSubmit}
              >
                Submit
              </button>
              <button
                type='button'
                className='btn btn-light  float-right'
                onClick={() => stepper.previous()}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default AddProject;
