// ProtectedRoute.js
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getIdToken } from "../Auth/authProvider";
import { getCurrentUser } from "../../services/authService";
import axios from "axios";

const AdminProtectedRoute = ({ element: Element, ...rest }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCurrentUser().then((r) => {
      if (r) {
        setHasToken(true);

        axios
          .post(`${process.env.REACT_APP_SERVER_URL}auth`, {
            email: r.preferred_username,
          })
          .then((x) => {
            if (x.data == "true") {
              setIsSuperAdmin(true);
            } else {
              setIsSuperAdmin(false);
            }
            setLoading(false);
          });
      } else {
        setHasToken(false);
        setLoading(false);
      }
    });
  }, []);
  const navigate = useNavigate();
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src='/assets/Loading_icon.gif'></img>
      </div>
    );
  } else if (hasToken == false || isSuperAdmin == false) {
    console.log("super", isSuperAdmin);
    navigate("/home");
  } else return <Outlet />;
};

export default AdminProtectedRoute;
