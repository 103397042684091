import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import domtoimage from "dom-to-image";
import "./WIVAPhotobooth.css";
import { WorkplaceIcon, WorkplaceShareButton } from "react-share";
import auth, {
  getCredentials,
  getCurrentUser,
} from "../../services/authService";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import ChooseFrame from "./WIVAChooseFrame/WIVAChooseFrame";
import styles from "./WIVAPhotobooth.module.css";
import axios from "axios";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
function WIVAPhotobooth() {
  let globalobj = {};

  const getData = () => {
    fetch("/appSettings.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        globalobj = response.json();
        console.log(globalobj);
      })
      .then(function (myJson) {
        console.log(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const [frame, setFrame] = React.useState("/assets/empty-img.jfif");
  const [imgURL, setImgUrl] = React.useState([]);
  const [tookPhoto, setTookPhoto] = React.useState(false);
  useEffect(() => {}, []);
  const history = useNavigate();
  const [loadBytes, setLoadBytes] = React.useState(true);
  let videoStream;
  let file;
  useEffect(() => {
    let width = 200,
      height = 150,
      filter = "none",
      streaming = false;

    const video = document.getElementById("video");

    const photoButton = document.getElementById("photo-button");
    // const photoFilter = document.getElementById("photo-filter");
    // const clearButton = document.getElementById("clear-button");
    const canvas = document.getElementById("canvas");
    const photos = document.getElementById("photos-div");

    // getting the stream from the webcam and import it into the video element
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then((stream) => {
        // link to the video source
        videoStream = stream;
        video.srcObject = stream;

        video.play();
      })
      .catch((err) => {});

    // Play video when ready
    if (frame != "/assets/empty-img.jfif") {
      video.addEventListener(
        "canplay",
        function (e) {
          if (!streaming) {
            // Set video / canvas height
            height = video.videoHeight / (video.videoWidth / width);

            video.setAttribute("width", width);
            video.setAttribute("height", height);

            canvas.setAttribute("width", width);
            canvas.setAttribute("height", height);

            streaming = true;
          }
        },
        false
      );

      // Photo button event
      photoButton.addEventListener(
        "click",
        function (e) {
          takePicture();
          setTookPhoto(true);
          e.preventDefault();
        },
        false
      );
      // Filter event
      // photoFilter.addEventListener("change", function (e) {
      //   // Set filter to chosen option
      //   filter = e.target.value;
      //   // Set filter to video
      //   video.style.filter = filter;

      //   e.preventDefault();
      // });

      // // Clear event
      // clearButton.addEventListener("click", function (e) {
      //   // Clear photos
      //   photos.innerHTML = "";
      //   // Change filter back to none
      //   filter = "none";
      //   // Set video filter
      //   video.style.filter = filter;
      //   // Reset select list
      //   photoFilter.selectedIndex = 0;
      // });
    }
    // Take picture from canvas
    function takePicture() {
      setLoadBytes(false);
      // Create canvas
      const context = canvas.getContext("2d");
      if (width && height) {
        // set canvas props
        // canvas.width = 500;
        // canvas.height = 500;

        // Draw an image of the video on the canvas
        context.drawImage(video, 0, 0, width, height);

        // Create image from the canvas
        const imgUrl = canvas.toDataURL("image/png");
        const frameImage = document.createElement("Div");
        let imgName = "";
        frameImage.style.backgroundImage = frame != "" ? `url(${frame})` : null;
        getCurrentUser().then((r) => {
          imgName = r.preferred_username.split(".")[0] + ".png";
        });
        getCredentials().then((r) => {
          im.push(`${r.ENVIRTUAL_BASE_URL}/summit/photoBooth/` + imgName);
          setImgUrl(im);
          btnDiv &&
            ReactDOM.render(
              ReactDOM.createPortal(
                getLink(`${r.ENVIRTUAL_BASE_URL}/summit/photoBooth/` + imgName),
                btnDiv
              ),
              document.createElement("div")
            );
        });

        let im = imgURL;

        function blobToBase64(blob) {
          return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        }

        domtoimage
          .toBlob(frameImage)
          .then(function (blob) {
            btn.href = URL.createObjectURL(blob);

            btn.download = `WIVA_Photobooth${Date.now()}.png`;
            blobToBase64(blob).then((r) => {
              axios.post(`${process.env.REACT_APP_SERVER_URL}mosaicWall`, {
                imageBytes: imgUrl,
                userName: imgName,

                eventName: "wiv",
              });
            });
            setLoadBytes(true);
          })
          .catch((err) => console.log(err));

        frameImage.className = styles["summit-mainframe"];
        // create frame container DOM
        const frameContainer = document.createElement("DIV");
        frameContainer.setAttribute("class", " col-md-12");
        frameContainer.style.display = "flex";
        frameContainer.style.justifyContent = "center";
        frameContainer.style.alignItems = "center";
        frameContainer.style.flexDirection = "column";

        // Create img element DOM
        const img = document.createElement("img");
        img.className = styles["summit-video"];
        // Set img src
        img.setAttribute("src", imgUrl);

        frameImage.appendChild(img);

        const btnDiv = document.createElement("DIV");

        btnDiv.style.display = "flex";
        const btn = document.createElement("a");

        btn.className = styles["btn-styling"];
        btn.innerHTML = "Download";
        const btn2 = document.createElement("a");
        btn2.className = styles["btn-styling2"];

        btn2.innerHTML = "Wall of Fame";
        btn2.addEventListener("click", () => {
          history("/wiv/mosaicwall");
        });
        photos.appendChild(frameContainer);
        debugger;
        // Add image to photos
        frameContainer.appendChild(frameImage);

        frameContainer.appendChild(btnDiv);

        btnDiv.appendChild(btn);
        btnDiv.appendChild(btn2);

        // btnDiv.appendChild(ReactDOM.(getLink(), btnDiv));
      }
    }
    return function () {
      if (videoStream != undefined)
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
    };
  }, [frame]);
  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const frameSetting = (e) => {
    setFrame(e);
  };

  const getLink = (link) => {
    return (
      <WorkplaceShareButton
        hashtag='#_voissummit2023'
        url={link}
        id='sharetoworkplace'
        style={{ display: "none" }}
      >
        <WorkplaceIcon size={32} round logoFillColor='white' />
      </WorkplaceShareButton>
    );
  };
  return (
    <div>
      <canvas id='canvas' width={1000} height={1000}></canvas>
      {frame == "/assets/empty-img.jfif" ? (
        <ChooseFrame frameUpdate={frameSetting}></ChooseFrame>
      ) : (
        <div className={styles["container-fluid"] + " "}>
          <div className={styles["row"] + " row"}>
            <div className='col-md-12 p-0'>
              <WivaNavBar></WivaNavBar>

              <WIVASideNavBar></WIVASideNavBar>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                className={styles["back-div"]}
                onClick={() => {
                  tookPhoto == false
                    ? setFrame("/assets/empty-img.jfif")
                    : history("/wiv/photoboothentrance");
                }}
              >
                <path
                  d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
                  fill='#4A4D4E'
                />
                <path
                  d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
                  fill='#4A4D4E'
                />
              </svg>

              <div className={styles["joinInnerContainer"]}>
                <div className={styles["first-div-row"] + " row  m-0"}>
                  <div className='col-md-12'>
                    {" "}
                    {tookPhoto == false ? (
                      <div className={styles["tip-div"]}>
                        <span className={styles["tip-number"]}>2</span>
                        <span className={styles["tip-text"]}>
                          Take Your Photo
                        </span>
                      </div>
                    ) : (
                      <div className={styles["tip-div"]}>
                        <span className={styles["tip-number"]}>3</span>
                        <span className={styles["tip-text"]}>
                          Download Your Favourite Photo
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 col-sm-12'>
                    <div className='row'>
                      <div
                        className={styles["summit-frame-container"] + " col-12"}
                        style={
                          tookPhoto == false
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        <div
                          className={styles["summit-mainframe"]}
                          style={{ backgroundImage: `url(${frame})` }}
                        >
                          <video id='video' className={styles["summit-video"]}>
                            Stream not available..
                          </video>
                        </div>

                        <button
                          id='photo-button'
                          disabled={loadBytes == true ? false : true}
                          className={styles["btn-styling"]}
                        >
                          Take photo
                        </button>
                      </div>
                      <div
                        className={styles["summit-frame-container"] + " col-12"}
                        style={
                          tookPhoto == false
                            ? { display: "none" }
                            : { display: "flex" }
                        }
                      >
                        <div id='photos-div' className='row'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WIVAPhotobooth;
