import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import auth, {
  getCredentials,
  getCurrentUser,
} from "../../services/authService";
import styles from "./ThirdAscensionKeyNotes.module.css";
import ReactPlayer from "react-player";
function ThirdAscensionKeyNotes() {
  const allRef = useRef();
  const pdfRef = useRef();
  const recordRef = useRef();
  const gridRef = useRef();
  const listRif = useRef();

  const history = useNavigate();
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [filesmode, setfilesmode] = React.useState("All");
  const [screenmode, setscreenmode] = React.useState("grid");
  const [gridlink, setgridlink] = React.useState(
    "/assets/thirdAscension/SquaresFour.svg"
  );
  const [listlink, setlistlink] = React.useState(
    "/assets/thirdAscension/ListDashes.svg"
  );
  const [files, setfiles] = useState([]);

  useEffect(() => {
    getCredentials().then((r) => {
      if (filesmode == "All") {
        pdffile("/keynotes/kareem.pdf", "Future of Technology.pdf");
        getFileFromUrl(
          "14",
          `${r.ENVIRTUAL_BASE_URL}/videos/kareem.mp4`,
          "Future of Technology.mp4"
        );
        pdffile("/keynotes/mira.pdf", "Future of Data Analytics.pdf");
        getFileFromUrl(
          "60",
          `${r.ENVIRTUAL_BASE_URL}/videos/amr-mira.m4v`,
          "Future of Data Analytics.mp4"
        );
        pdffile("/keynotes/mgahed.pdf", "Process Transformation.pdf");
        getFileFromUrl(
          "23",
          `${r.ENVIRTUAL_BASE_URL}/videos/amr-mgahed.m4v`,
          "Process Transformation.mp4"
        );
        pdffile("/keynotes/heba.pdf", "Productivity & Economic growth.pdf");
        getFileFromUrl(
          "40",
          `${r.ENVIRTUAL_BASE_URL}/videos/Heba.m4v`,
          "Productivity & Economic growth.mp4"
        );
        pdffile("/keynotes/saeed.pdf", "DevOps Maturity.pdf");
        getFileFromUrl(
          "49",
          `${r.ENVIRTUAL_BASE_URL}/videos/Ahmed-Saeed.m4v `,
          "DevOps Maturity.mp4"
        );
        pdffile("/keynotes/opening.pdf", "Opening-keynote.pdf");
        getFileFromUrl(
          "31",
          `${r.ENVIRTUAL_BASE_URL}/videos/Tamer.m4v`,
          "Opening-keynote.mp4"
        );
        pdffile("/keynotes/mai.pdf", "Most Agile Transformation Fail.pdf");
        getFileFromUrl(
          "38",
          `${r.ENVIRTUAL_BASE_URL}/videos/Mai.m4v`,
          "Most Agile Transformation Fail.mp4"
        );
        pdffile("/keynotes/noha.pdf", "Autonomous DSM.pdf");
        getFileFromUrl(
          "30",
          `${r.ENVIRTUAL_BASE_URL}/videos/Noha.m4v`,
          "Autonomous DSM.mp4"
        );
      } else if (filesmode == "PDFs") {
        pdffile("/keynotes/kareem.pdf", "Future of Technology.pdf");

        pdffile("/keynotes/mira.pdf", "Future of Data Analytics.pdf");

        pdffile("/keynotes/mgahed.pdf", "Process Transformation.pdf");

        pdffile("/keynotes/heba.pdf", "Productivity & Economic growth.pdf");

        pdffile("/keynotes/saeed.pdf", "DevOps Maturity.pdf");

        pdffile("/keynotes/opening.pdf", "Opening-keynote.pdf");

        pdffile("/keynotes/mai.pdf", "Most Agile Transformation Fail.pdf");

        pdffile("/keynotes/noha.pdf", "Autonomous DSM.pdf");
      } else if (filesmode == "Records") {
        getFileFromUrl(
          "14",
          `${r.ENVIRTUAL_BASE_URL}/videos/kareem.mp4`,
          "Future of Technology.mp4"
        );
        getFileFromUrl(
          "60",
          `${r.ENVIRTUAL_BASE_URL}/videos/amr-mira.m4v`,
          "Future of Data Analytics.mp4"
        );
        getFileFromUrl(
          "23",
          `${r.ENVIRTUAL_BASE_URL}/videos/amr-mgahed.m4v`,
          "Process Transformation.mp4"
        );
        getFileFromUrl(
          "40",
          `${r.ENVIRTUAL_BASE_URL}/videos/Heba.m4v`,
          "Productivity & Economic growth.mp4"
        );
        getFileFromUrl(
          "49",
          `${r.ENVIRTUAL_BASE_URL}/videos/Ahmed-Saeed.m4v `,
          "DevOps Maturity.mp4"
        );
        getFileFromUrl(
          "31",
          `${r.ENVIRTUAL_BASE_URL}/videos/Tamer.m4v`,
          "Opening-keynote.mp4"
        );
        getFileFromUrl(
          "38",
          `${r.ENVIRTUAL_BASE_URL}/videos/Mai.m4v`,
          "Most Agile Transformation Fail.mp4"
        );
        getFileFromUrl(
          "30",
          `${r.ENVIRTUAL_BASE_URL}/videos/Noha.m4v`,
          "Autonomous DSM.mp4"
        );
      }
    });

    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/PostKeyNote`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
    setTimeout(() => {
      setLoadBytes(true);
    }, 10000);
  }, [files]);
  async function getFileFromUrl(size, url, name, defaultType = "*/*") {
    let newfiles = files;

    let newfile = {
      name: name,
      size: size,
      fileurl: url,
    };
    newfiles.push(newfile);
    setfiles(newfiles);
    return;
  }
  async function pdffile(url, name, defaultType = "*/*") {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });

    let newfiles = files;

    let newfile = {
      name: file.name,
      size: file.size,
      fileurl: URL.createObjectURL(data),
      generatedfile: file,
    };
    newfiles.push(newfile);
    setfiles(newfiles);

    return file;
  }
  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function downloadvideo(uri, name) {
    fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "video/mp4",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }
  async function downloadAll() {
    const zip = require("jszip")();

    for (let file = 0; file < files.length; file++) {
      const response = await fetch(files[file].fileurl);
      const data = await response.blob();
      const file2 = new File([data], files[file].name, {
        type: response.headers.get("content-type"),
      });

      let newfiles = files;

      let newfile = {
        name: file2.name,
        size: file2.size,
        fileurl: URL.createObjectURL(data),
        generatedfile: file2,
      };
      newfiles[file] = newfile;
      setfiles(newfiles);

      // Zip file with the file name.
      zip.file(files[file].generatedfile.name, files[file].generatedfile);
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      downloadURI(URL.createObjectURL(content), `2nd-Asc-Post-Keynotes.zip`);
    });
  }
  return (
    <div>
      <div
        className={
          styles["mentor-card-screen-background"] + " breakRoom-container-fluid"
        }
      >
        <div className={styles["mask"]}></div>
        <div
          className='row asc-cards-flip-container'
          style={{ margin: "0", padding: "0", overflowY: "auto" }}
        >
          <div
            className={styles["btn-back-envirtual-container"] + " col-md-12"}
            onClick={() => {
              history("/asc3/hall");
            }}
          >
            <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
            <label className={styles["back-btn-label"]}>Back</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className='col-md-12 col-xs-12  '
          >
            <div className='row' style={{ margin: "0.5rem 0rem" }}>
              <div
                className='col-md-6'
                style={{
                  display: "flex",

                  justifyContent: "flex-end",
                }}
              >
                {/* <button
                  className='btn  mb-2 mt-3  '
                  style={{
                    color: "white",
                    backgroundColor: "#e60000",
                    borderRadius: "8px",
                    zIndex: "1",
                    display: "flex",
                    alignSelf: "flex-end",
                  }}
                  onClick={downloadAll}
                >
                  Download All
                </button> */}
              </div>
            </div>

            <div className=''>
              <div className={styles["main-postKeynotes-page-header"]}>
                <button
                  className={styles["btn-selcted"]}
                  ref={allRef}
                  onClick={(el) => {
                    recordRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    pdfRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    allRef.current.className = styles["btn-selcted"];

                    setLoadBytes(false);

                    setTimeout(() => {
                      setLoadBytes(true);
                    }, 3000);
                    setfiles([]);
                    setfilesmode("All");
                  }}
                >
                  All
                </button>
                <button
                  className={styles["main-postKeynotes-page-button"]}
                  ref={pdfRef}
                  onClick={(el) => {
                    recordRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    pdfRef.current.className = styles["btn-selcted"];
                    allRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    setLoadBytes(false);

                    setTimeout(() => {
                      setLoadBytes(true);
                    }, 3000);
                    setfiles([]);
                    setfilesmode("PDFs");
                  }}
                >
                  PDFs
                </button>
                <button
                  className={styles["main-postKeynotes-page-button"]}
                  ref={recordRef}
                  onClick={(el) => {
                    recordRef.current.className = styles["btn-selcted"];
                    pdfRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    allRef.current.className =
                      styles["main-postKeynotes-page-button"];
                    setLoadBytes(false);

                    setTimeout(() => {
                      setLoadBytes(true);
                    }, 3000);
                    setfiles([]);
                    setfilesmode("Records");
                  }}
                >
                  Records
                </button>
                <div
                  style={{
                    position: "absolute",
                    right: "2.85rem",
                  }}
                >
                  <img
                    src={gridlink}
                    ref={gridRef}
                    id='grid'
                    className={styles["btn-selcted"] + " mr-2"}
                    style={{ width: "2.5rem", cursor: "pointer" }}
                    onClick={(el) => {
                      gridRef.current.src =
                        "/assets/thirdAscension/SquaresFour.svg";
                      gridRef.current.className = styles["btn-selcted"];
                      listRif.current.src =
                        "/assets/thirdAscension/ListDashes.svg";
                      listRif.current.className =
                        styles["main-postKeynotes-page-button"];
                      setscreenmode("grid");
                      setfiles([]);
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                    }}
                  ></img>
                  <img
                    id='list'
                    src={listlink}
                    ref={listRif}
                    className={styles["main-postKeynotes-page-button"]}
                    style={{ width: "2.5rem", cursor: "pointer" }}
                    onClick={(el) => {
                      gridRef.current.src =
                        "/assets/thirdAscension/SquaresFour (1).svg";
                      gridRef.current.className =
                        styles["main-postKeynotes-page-button"];
                      listRif.current.src =
                        "/assets/thirdAscension/ListDashes (1).svg";
                      listRif.current.className = styles["btn-selcted"];
                      setscreenmode("list");
                      setfiles([]);
                      setLoadBytes(false);

                      setTimeout(() => {
                        setLoadBytes(true);
                      }, 3000);
                    }}
                  ></img>
                </div>
              </div>
              {loadBytes ? (
                screenmode == "grid" ? (
                  <div className=' row'>
                    {files.map((el) => {
                      return (
                        <div className={styles["carddimensions"]}>
                          {el.name.includes(".pdf") ? (
                            <>
                              <img
                                src='/assets/thirdAscension/DownloadSimple.svg'
                                className={styles["small-download-cards"]}
                                onClick={() => {
                                  downloadURI(el.fileurl, el.name);
                                }}
                              ></img>
                              <div
                                onClick={() => {
                                  window.open(el.fileurl, "_blank");
                                }}
                                className={styles["card-content"]}
                              >
                                <img
                                  src='/assets/thirdAscension/pdf.svg'
                                  style={{
                                    width: "10rem",
                                    objectFit: "contain",
                                    borderRadius: "16px",
                                  }}
                                ></img>
                                <p
                                  className={styles["card-text"]}
                                  style={{ marginTop: "2rem" }}
                                >
                                  {" "}
                                  {el.name}
                                </p>
                              </div>
                            </>
                          ) : el.name.includes(".mp4") ||
                            el.name.includes(".m4v") ? (
                            <>
                              <img
                                src='/assets/thirdAscension/DownloadSimple.svg'
                                className={styles["small-download-cards"]}
                                onClick={() => {
                                  downloadvideo(el.fileurl, el.name);
                                }}
                              ></img>
                              <div
                                onClick={() => {
                                  window.open(el.fileurl, "_blank");
                                }}
                                className={styles["card-content"]}
                              >
                                <ReactPlayer
                                  controls={false}
                                  muted={true}
                                  playing={true}
                                  autoPlay={true}
                                  url={el.fileurl}
                                  width='15rem'
                                  height='7.5rem'
                                  style={{
                                    width: "15rem",
                                    borderRadius: "16px",
                                  }}
                                />
                                {/* <video
                                  src={el.fileurl}
                                  style={{
                                    width: "15rem",
                                    borderRadius: "16px",
                                  }}
                                ></video> */}
                                <p
                                  className={styles["card-text"]}
                                  style={{ marginTop: "2rem" }}
                                >
                                  {" "}
                                  {el.name}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={styles["table-bg"] + " row"}>
                    <table class='table t-width'>
                      <thead>
                        <tr className={styles["table-header"]}>
                          <th
                            scope='col'
                            className={styles["table-cells"]}
                            style={{
                              width: "50%",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Name
                          </th>
                          <th
                            scope='col'
                            style={{
                              width: "25%",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Size
                          </th>

                          <th
                            scope='col'
                            style={{
                              width: "25%",
                              border: "none",
                              color: "white",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {files.map((el) => {
                          console.log(el);
                          return (
                            <tr>
                              <td
                                onClick={() => {
                                  window.open(el.fileurl, "_blank");
                                }}
                                className={styles["table-cells"]}
                                style={{ cursor: "pointer", color: "white" }}
                              >
                                {el.name}
                              </td>
                              <td style={{ color: "white" }}>
                                {el.name.includes(".pdf")
                                  ? Math.round(parseInt(el.size) / 1000) >= 1024
                                    ? Math.round(
                                        parseInt(el.size) / 1000 / 1024
                                      ) + " MB"
                                    : Math.round(parseInt(el.size) / 1000) +
                                      " KB"
                                  : el.size + " MB"}{" "}
                              </td>

                              <td>
                                <img
                                  src='/assets/thirdAscension/DownloadSimple.svg'
                                  className={
                                    styles["table-small-download-cards"]
                                  }
                                  onClick={() => {
                                    downloadvideo(el.fileurl, el.name);
                                  }}
                                ></img>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              ) : (
                <div
                  className='main-postKeynotes-page-body row'
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div className='text-center'>
                    <div className='spinner-grow text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-secondary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-success' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-danger' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-warning' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-info' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-light' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                    <div className='spinner-grow text-dark' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdAscensionKeyNotes;
