import React from "react";
import styles from "./WivaTemplateConfig.module.css";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVACommunityMembers from "./WIVACommunityMembers/WIVACommunityMembers";
import WivaMialTemplates from "./WivaMailTemplates/WivaMialTemplates";
function WivaTemplateConfig() {
  return (
    <div className='container-fluid p-0'>
      <WivaNavBar></WivaNavBar>
      <div className={styles["joinInnerContainer"] + " row"}>
        <WIVACommunityMembers></WIVACommunityMembers>
        <WivaMialTemplates></WivaMialTemplates>
      </div>
    </div>
  );
}

export default WivaTemplateConfig;
