import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setJwt } from "../../services/httpService";
import { fetchUserData, getIdToken } from "../Auth/authProvider";

function Entrance() {
  const history = useNavigate();

  useEffect(() => {
    fetchUserData().then((re) => {
      getIdToken().then((r) => {
        if (r) setJwt();
        else history("/");
      });
    });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src='/assets/Loading_icon.gif'></img>
    </div>
  );
}

export default Entrance;
