import axios from "axios";
import * as ReactDOM from "react-dom";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import domtoimage from "dom-to-image";
import "./ThirdAscensionTakePhoto.css";
import { WorkplaceIcon, WorkplaceShareButton } from "react-share";
import auth, { getCurrentUser } from "../../services/authService";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

import styles from "./ThirdAscensionTakePhoto.module.css";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
function ThirdAscensionTakePhoto() {
  const getCredentials = async () =>
    fetch("/appSettings.json")
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch(console.log);

  const [frame, setFrame] = React.useState(
    "/assets/thirdAscension/card-pb.png"
  );
  const [imgURL, setImgUrl] = React.useState([]);
  const [tookPhoto, setTookPhoto] = React.useState(false);

  useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/TakePhoto`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  const history = useNavigate();
  const [loadBytes, setLoadBytes] = React.useState(true);
  const [name, setName] = React.useState();
  let videoStream;
  let file;

  useEffect(() => {
    getCurrentUser().then((r) => {
      setName(
        r.preferred_username.split(".")[0] +
          " " +
          r.preferred_username.split(".")[1].split("@")[0]
      );
    });
    let width = 149,
      height = 0,
      filter = "none",
      streaming = false;

    const video = document.getElementById("video");

    const photoButton = document.getElementById("photo-button");
    // const photoFilter = document.getElementById("photo-filter");
    // const clearButton = document.getElementById("clear-button");
    const canvas = document.getElementById("canvas");
    const photos = document.getElementById("photos");

    // getting the stream from the webcam and import it into the video element
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then((stream) => {
        // link to the video source
        videoStream = stream;
        video.srcObject = stream;

        video.play();
      })
      .catch((err) => {});

    // Play video when ready
    if (frame != "/assets/empty-img.jfif") {
      video.addEventListener(
        "canplay",
        function (e) {
          if (!streaming) {
            // Set video / canvas height
            height = video.videoHeight / (video.videoWidth / width);

            video.setAttribute("width", width);
            video.setAttribute("height", height);
            canvas.setAttribute("width", width);
            canvas.setAttribute("height", height);

            streaming = true;
          }
        },
        false
      );

      // Photo button event
      photoButton.addEventListener(
        "click",
        function (e) {
          takePicture();
          setTookPhoto(true);
          document.getElementById("camera-sound").play();
          e.preventDefault();
        },
        false
      );
      // Filter event
      // photoFilter.addEventListener("change", function (e) {
      //   // Set filter to chosen option
      //   filter = e.target.value;
      //   // Set filter to video
      //   video.style.filter = filter;

      //   e.preventDefault();
      // });

      // // Clear event
      // clearButton.addEventListener("click", function (e) {
      //   // Clear photos
      //   photos.innerHTML = "";
      //   // Change filter back to none
      //   filter = "none";
      //   // Set video filter
      //   video.style.filter = filter;
      //   // Reset select list
      //   photoFilter.selectedIndex = 0;
      // });
    }
    // Take picture from canvas
    function takePicture() {
      setLoadBytes(false);
      // Create canvas
      const context = canvas.getContext("2d");
      if (width && height) {
        // set canvas props
        canvas.width = width;
        canvas.height = height;

        // Draw an image of the video on the canvas
        context.drawImage(video, 0, 0, width, height);

        // Create image from the canvas
        const imgUrl = canvas.toDataURL("image/png");
        const frameImage = document.createElement("Div");

        frameImage.style.backgroundImage = frame != "" ? `url(${frame})` : null;
        frameImage.style.flexDirection = "column";

        let imgName;
        getCurrentUser().then((r) => {
          const photoBoothName = document.createElement("h3");
          photoBoothName.style.color = "white";

          photoBoothName.style.left = "51px";
          photoBoothName.style.fontWeight = "bolder";
          photoBoothName.style.textTransform = "capitalize ";

          photoBoothName.style.setProperty(
            "font-family",
            "Polaris",
            "important"
          );
          photoBoothName.style.fontSize = "24px";
          photoBoothName.style.lineHeight = "31px ";
          frameImage.appendChild(photoBoothName);
          photoBoothName.innerHTML =
            r.preferred_username.split(".")[0] +
            " " +
            r.preferred_username.split(".")[1].split("@")[0];
          imgName =
            r.preferred_username.split(".")[0] +
            " " +
            r.preferred_username.split(".")[1].split("@")[0] +
            ".png";

          getCredentials().then((r) =>
            im.push(`${r.ENVIRTUAL_BASE_URL}/asc3/photoBooth/` + imgName)
          );
          domtoimage
            .toBlob(frameImage)
            .then(function (blob) {
              btncoat.href = URL.createObjectURL(blob);

              btncoat.download = `Envirtual${Date.now()}.png`;
              blobToBase64(blob).then((r) => {
                axios.post(
                  `${process.env.REACT_APP_SERVER_URL}mosaicWall/summitbooth`,
                  {
                    imageBytes: r,
                    eventName: "asc3-1",
                    userName: imgName,
                  }
                );
                axios.post(`${process.env.REACT_APP_SERVER_URL}mosaicWall`, {
                  imageBytes: imgUrl,
                  userName: imgName,

                  eventName: localStorage.getItem("selectedEventName"),
                });
              });
              setLoadBytes(true);
            })
            .catch((err) => console.log(err));
        });

        let im = imgURL;

        setImgUrl(im);

        function blobToBase64(blob) {
          return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        }
        frameImage.style.backgroundRepeat = "no-repeat";
        frameImage.style.width = "26rem";
        frameImage.style.height = "26rem";
        frameImage.style.backgroundSize = "contain";

        frameImage.style.borderRadius = "8px";
        frameImage.style.display = "flex";
        frameImage.style.justifyContent = "center";
        frameImage.style.alignItems = "center";

        // create frame container DOM
        const frameContainer = document.createElement("DIV");
        frameContainer.setAttribute("class", " col-md-12");
        frameContainer.style.display = "flex";
        frameContainer.style.justifyContent = "center";
        frameContainer.style.alignItems = "center";
        frameContainer.style.flexDirection = "column";

        // Create img element DOM
        const img = document.createElement("img");

        // Set img src
        img.setAttribute("src", imgUrl);
        img.style.width = "129px";
        img.style.height = "129px";
        img.style.objectFit = "cover";
        img.style.borderRadius = "50%";

        img.style.marginTop = "14.25%";
        img.style.marginRight = "0.75rem";
        img.style.marginBottom = "2rem";
        // Set image filter
        img.style.filter = filter;
        frameImage.appendChild(img);

        // Add image to photos
        frameContainer.appendChild(frameImage);

        const btnDivParent = document.createElement("DIV");
        btnDivParent.className = styles["main-container"];
        btnDivParent.style.marginLeft = "5rem";

        const btnDiv = document.createElement("DIV");
        btnDiv.setAttribute("class", "mt-2 ");
        btnDiv.style.display = "flex";
        btnDiv.style.flexDirection = "column";
        const btncoat = document.createElement("a");
        const btn = document.createElement("img");
        btn.className = styles["btn-styling"];
        btn.style.width = "3rem";
        btn.setAttribute("src", "/assets/thirdAscension/download.svg");
        btncoat.appendChild(btn);
        const btn2 = document.createElement("img");
        btn2.className = styles["btn-styling"];
        btn2.setAttribute("class", "mt-3");
        btn2.style.cursor = "pointer";
        btn2.setAttribute("src", "/assets/thirdAscension/gallerywall.png");
        let btn2click = () => {
          history("/asc3/gallerywall");
        };

        btn2.onclick = btn2click;

        const btn3 = document.createElement("img");
        btn3.className = styles["btn-styling"];
        btn3.className = "mt-3";
        btn3.setAttribute("src", "/assets/thirdAscension/mosaicwall.png");
        // Add image to photos
        btnDivParent.appendChild(frameImage);
        btnDivParent.appendChild(btnDiv);
        photos.appendChild(btnDivParent);
        btnDiv.appendChild(btncoat);
        btnDiv.appendChild(btn2);
        btnDiv.appendChild(btn3);

        // btnDiv.appendChild(ReactDOM.(getLink(), btnDiv));
      }
    }
    return function () {
      if (videoStream != undefined)
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
    };
  }, [frame]);
  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const frameSetting = (e) => {
    setFrame(e);
  };

  const getLink = () => {
    return (
      <WorkplaceShareButton
        hashtag='#3rd_Ascension_Day'
        url={imgURL[imgURL.length - 1]}
        id='sharetoworkplace'
        style={{ display: "none" }}
      >
        <WorkplaceIcon size={32} round logoFillColor='white' />
      </WorkplaceShareButton>
    );
  };
  return (
    <div>
      {" "}
      <audio
        id='camera-sound'
        src='/assets/thirdAscension/camera-shutter.wav'
      ></audio>
      <canvas id='canvas'></canvas>
      {frame == "/assets/empty-img.jfif" ? null : tookPhoto == false ? (
        <div>
          <div className='breakRoom-container-fluid'>
            <div
              className={
                styles["mentor-card-screen-background"] +
                " pl-5 nav-button-padding row"
              }
            >
              <div
                className={
                  styles["btn-back-envirtual-container"] + " col-md-12"
                }
                onClick={() => {
                  history("/asc3/photoboothentrance2");
                }}
              >
                <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
                <label className={styles["back-btn-label"]}>Back</label>
              </div>
              <div
                className={styles["main-container"] + " col-md-12 col-sm-12"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className={styles["asc3-mainframe"]}>
                    <img src={frame} className={styles["asc3-frame"]}></img>
                    <video
                      id='video'
                      width='300'
                      height='300'
                      className={styles["asc3-video"]}
                    >
                      Stream not available..
                    </video>
                  </div>
                  <label className={styles["asc3-framename"]}>{name}</label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src='/assets/thirdAscension/camera.png'
                    id='photo-button'
                    disabled={loadBytes == true ? false : true}
                    className={styles["btn-styling"]}
                  ></img>
                  <img
                    src='/assets/thirdAscension/gallerywall.png'
                    disabled={loadBytes == true ? false : true}
                    className={styles["btn-styling"] + " mt-3"}
                    onClick={() => {
                      history("/asc3/gallerywall");
                    }}
                  ></img>
                  <img
                    src='/assets/thirdAscension/mosaicwall.png'
                    disabled={loadBytes == true ? false : true}
                    className={styles["btn-styling"] + " mt-3"}
                  ></img>
                </div>
              </div>
              <div className='col-md-12 col-xs-6'>
                <div className='video-bottom-container'>
                  <div id='photos' className='row'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='breakRoom-container-fluid'>
            <div
              className={
                styles["mentor-card-screen-background"] +
                " pl-5 nav-button-padding row"
              }
            >
              <div
                className={
                  styles["btn-back-envirtual-container"] + " col-md-12"
                }
                onClick={() => {
                  history("/asc3/photoboothentrance2");
                }}
              >
                <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
                <label className={styles["back-btn-label"]}>Back</label>
              </div>
              <div
                className={styles["main-container"] + " col-md-12 col-sm-12"}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className='video-bottom-container'>
                    <div id='photos' className='row'></div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-xs-6'>
                <div className='video-bottom-container'>
                  <div id='photos' className='row'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThirdAscensionTakePhoto;
