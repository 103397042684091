// ProtectedRoute.js
import React from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import { getIdToken } from "../Auth/authProvider";

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const token = getIdToken(); // Replace this with your authentication logic
  const location = useLocation();
  if (!token && window.location.pathname !== "/entrance") {
    return <Navigate replace='/home' />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
