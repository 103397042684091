import React, { useEffect } from "react";

function Onboarding() {
  function isMyScriptLoaded(url) {
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }
  const [load, setload] = React.useState(false);
  useEffect(() => {
    var hammertime = new window.Hammer(document, {
      cssProps: {
        touchAction: "none",
      },
    });

    hammertime.get("pan").set({
      enable: true,
      direction: window.Hammer.DIRECTION_ALL,
    });
    hammertime.on("panup pandown panleft panright", function (event) {});

    document.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
      },
      { passive: false }
    );

    let ui = document.querySelector("#UI");

    // Function to display information
    function markerInfo(marker, evt, infoui) {
      let posx = marker.object3D.position.x;
      let posy = marker.object3D.position.y;
      let posz = marker.object3D.position.z;

      let message =
        "marker at x: " +
        marker.object3D.position.x +
        ", y: " +
        marker.object3D.position.x +
        ", z: " +
        marker.object3D.position.z;

      if (evt) {
        infoui.classList.remove("lost");
        infoui.classList.add("found");
        message = "Found " + message;
      } else {
        infoui.classList.remove("found");
        infoui.classList.add("lost");
        message = "Lost " + message;
      }

      infoui.innerHTML = message;
    }

    // Detecting single marker
    let m = document.querySelector("a-nft");
    if (m) {
      m.addEventListener("markerFound", (e) => {
        window
          .$("#object")
          .attr(
            "animation__scale",
            `property: scale; to: 3 2 2; loop: false; dur: 2000; easing: easeInOutSine;`
          );
      });

      m.addEventListener("markerLost", (e) => {
        // window
        //   .$("#object")
        //   .attr(
        //     "animation__scale",
        //     `property: scale; to: 0 0 0; loop: false; dur: 2000; easing: easeInOutSine;`
        //   );
      });
    }
    window.$("#object").on("animationcomplete", function (event) {
      if (window.$("#object").attr("animation__scale")) {
        window.$("#object").removeAttr("animation__scale");
        window.$("#object").attr("animation-mixer", "clip:*");
      }
    });
  }, [load]);
  useEffect(() => {
    if (
      !isMyScriptLoaded(
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js"
      )
    ) {
      const script = document.createElement("script");
      script.src =
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js";
      script.async = true;
      document.head.appendChild(script);
    }
    if (!isMyScriptLoaded("http://localhost:3000/aframe-ar-nft.js")) {
      const script2 = document.createElement("script");
      script2.src = "/aframe-ar-nft.js";
      script2.async = true;
      document.head.appendChild(script2);
      setTimeout(() => {
        setload(true);
      }, 2000);
    }
    document.addEventListener(
      "touchend",
      function (event) {
        event.preventDefault();
      },
      { passive: false }
    );
  }, []);

  return (
    <>
      <div id='UI'></div>
      {load ? (
        <a-scene
          vr-mode-ui='enabled: false;'
          renderer='logarithmicDepthBuffer: true;'
          arjs='trackingMethod: best; sourceType: webcam; videoTexture: true;debugUIEnabled: false;'
          cursor='rayOrigin: mouse'
        >
          <a-entity camera look-controls mouse-cursor></a-entity>

          <a-nft
            type='nft'
            url='onboarding/voisLogo'
            smooth='true'
            smoothCount='10'
            smoothTolerance='.01'
            smoothThreshold='5'
          ></a-nft>
          <a-entity
            showobj
            id='object'
            gltf-model='url(/onboarding/waving.glb)'
            position='0 -5 -10 '
            rotation='0 0 0'
            scale=' 0 0 0'
          ></a-entity>
        </a-scene>
      ) : null}
    </>
  );
}

export default Onboarding;
